import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import CommonButton from '../Buttons/CommonButton';

const EmailInput = ({ id, service }) => {
    const { t } = useTranslation();
    const [emailInput, setEmailInput] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);

        const sentProposal = await service.sendEmailWithProposal(
            id,
            emailInput
        );

        if (!sentProposal) {
        }

        setLoading(false);
    };

    return (
        <div
            className="flex w-full h-auto gap-10"
            style={{ alignItems: 'end' }}>
            <div>
                <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700">
                    {t('common.campaign-proposals.action.send')}
                </label>
                <input
                    id="email"
                    type="email"
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded placeholder-none"
                    required
                />
            </div>
            <CommonButton
                useLoader={true}
                disabled={emailInput?.length == 0}
                onClick={handleSubmit}
                styleType={'primary'}
                label={t('common.buttons.submit')}
            />
        </div>
    );
};

export default EmailInput;
