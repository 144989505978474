import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CampaignReady from './CampaignReady/CampaignReady';
import ValidationIssues from './ValidationIssues/ValidationIssues';
import Basics from './Blocks/Basics';
import Screens from './Blocks/Screens';
import Location from './Blocks/Location';
import Audiences from './Blocks/Audiences';
import PropTypes from 'prop-types';
import CommonButton from '../../Buttons/CommonButton';
import { newCampaignActions, notificationsActions } from '../../../store';
import { useService } from '../../../containers/UseService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../../../containers/UseAuth';
import ValidateWithProposal from './ValidateWithProposal/ValidateWithProposal';

function WizardReview({
    isActive,
    newCampaign,
    dispatch,
    budgets,
    userMargin,
    setCalculatingForecast,
}) {
    const { t } = useTranslation();
    const isResizeMapOn = newCampaign.isResizeMapOn;
    const canLaunch = newCampaign.canLaunch;
    const service = useService();
    const [isLoading, setIsLoading] = useState(null);
    const [hasFetched, setHasFetched] = useState(false);
    const [hasForced, setHasForced] = useState(false);
    const auth = useAuth();
    const [backupValidations, setBackupValidations] = useState(false);

    const forceToBuy = async () => {
        setCalculatingForecast(true);

        let segments = newCampaign.segments;
        segments = {
            ...segments,
            accuracy: {
                value: 'low',
                sliderValue: 0,
            },
        };

        setIsLoading(true);

        dispatch(newCampaignActions.setSegments(segments));
        //hack for avoid to wait for newCampaign redux update
        let campaignWithNewSegments = Object.assign({}, newCampaign);
        campaignWithNewSegments.segments = {
            ...newCampaign.segments,
            accuracy: {
                value: 'low',
                sliderValue: 0,
            },
        };

        await service
            .calculateForecast(campaignWithNewSegments)
            .then((schedules) => {
                setHasForced(true);
                setHasFetched(true);
                setIsLoading(false);
            });

        setCalculatingForecast(false);
    };

    // This useEffect is necessary when the Audience component is calculating the forecast.
    // setCalculatingForecast does not allow the user to move between tabs in the wizard.
    useEffect(() => {
        if (isLoading === true) {
            setCalculatingForecast(true);
        }

        if (isLoading === false) {
            setCalculatingForecast(false);
        }
    }, [isLoading]);

    useEffect(async () => {
        if (newCampaign.stepper?.wizard === 'review' && newCampaign?.label_id) {
            if (auth.isSupervising()) {
                const validations = await service.checkSubCampaignBeforeLaunch(
                    newCampaign.id
                );
                setBackupValidations(validations);
            }
        }
    }, [newCampaign, auth]);

    return (
        <>
            {!isActive ? (
                <></>
            ) : (
                <div
                    className={`basics mt-2 h-full overflow-visible overflow-y-auto text-sm transition-transform duration-299 ${
                        isResizeMapOn
                            ? 'transform scale-75 -translate-y-20'
                            : ''
                    }`}>
                    <div className="module rounded m-1 mb-4">
                        <div className="header flex justify-between content-center">
                            <div className="title text-xl font-extralight">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-review.review.title'
                                )}
                            </div>
                            {
                            (
                                <div
                                    className={`brief font-light text-xs text-gray-400 mt-1.5 text-right ${
                                        !canLaunch && 'invisible'
                                    }`}>
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-review.review.info'
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="body rounded-sm p-1 divide-y divide-dotted">
                            <CampaignReady
                                dispatch={dispatch}
                                canLaunch={canLaunch}
                                userCpmMode={auth?.session?.user?.cpm_mode}
                            />
                            <ValidationIssues
                                budgets={budgets}
                                campaign={newCampaign}
                                dispatch={dispatch}
                                canLaunch={canLaunch}
                            />
                            {auth?.isSupervising() &&
                            newCampaign?.label_id &&
                            backupValidations ? (
                                <ValidateWithProposal
                                    errors={backupValidations}
                                    t={t}
                                />
                            ) : (
                                <div></div>
                            )}
                            {/* Blocks */}

                            <Basics />
                            <Location />
                            <Screens userMargin={userMargin} />
                            <Audiences
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                hasFetched={hasFetched}
                                setHasFetched={setHasFetched}
                                hasForced={hasForced}
                                setHasForced={setHasForced}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

WizardReview.proptyTypes = {
    isActive: PropTypes.bool.isRequired,
    newCampaign: PropTypes.object,
    dispatch: PropTypes.func,
    budgets: PropTypes.object,
};

export default WizardReview;
