import React, { useEffect, useMemo, useRef, useState } from 'react';
import Loading from '../../components/Loading/Loading';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../components/Search/Search';
import CampaignCard from '../../components/Cards/CampaignCard';
import CommonButton from '../../components/Buttons/CommonButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useService } from '../../containers/UseService';
import { campaignsActions, dashboardActions } from '../../store';
import statsService from '../../services/statsService';
import { useAuth } from '../../containers/UseAuth';
import LZString from 'lz-string';

// Global variable that stores the result of initializeFilteredScreens.
// This ensures that the function is only called once.
let cachedFilteredScreens = null;
const initializeFilteredScreens = async (service) => {
    if (!cachedFilteredScreens) {
        const screens = localStorage.getItem('screens')
            ? JSON.parse(LZString.decompress(localStorage.getItem('screens')))
            : JSON.parse(localStorage.getItem('screens'));

        cachedFilteredScreens = new Map(
            screens?.map((screen) => [screen.id, screen])
        );
    }
    return cachedFilteredScreens;
};

const CampaignList = (props) => {
    const { containerClassName = '', status, dashboard } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const service = useService();
    const [isLoadingBySearch, setIsLoadingBySearch] = useState(false);
    const [campaignsBySearch, setCampaignsBySearch] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const campaigns = useSelector((state) => state.campaigns);
    const statuses = campaigns.statuses ? campaigns.statuses : [];
    const current = campaigns.campaignsShowing;
    const hasMoreCampaigns =
        current.campaigns.length < statuses[current.status];
    const isLoading = campaigns.isLoading;
    const [isCardsExpanded, setIsCardsExpanded] = useState(false);
    const [initialFetch, setInitialFetch] = useState({});
    const [isFetching, setIsFetching] = useState(false);
    const [isCloned, setIsCloned] = useState(false);
    const debounceRef = useRef(setTimeout(() => {}, 1000));
    const [campaignStatus, setCampaignStatus] = useState(null);
    const auth = useAuth();
    const session = auth.session;

    const [searchValue, setSearchValue] = useState('');

    const [filteredScreens, setFilteredScreens] = useState(null);

    useEffect(async () => {
        setIsLoadingBySearch(true);
        const fetchScreens = async () => {
            if (
                LZString.decompress(localStorage.getItem('screens')) ||
                localStorage.getItem('screens')
            ) {
                const screens = await initializeFilteredScreens(service);
                setFilteredScreens(screens);
                setIsLoadingBySearch(false);
            }
        };

        await fetchScreens();
    }, [localStorage.getItem('screens')]);

    useEffect(() => {
        if (
            (current.campaigns.length > 0 && status === 'ACTIVE') ||
            status === 'INACTIVE'
        ) {
            const campaignsArr = isSearch
                ? campaignsBySearch.map((campaign) => campaign.id)
                : current.campaigns.map((campaign) => campaign.id);
            const allCampaigns = campaignsArr.join(',');
            if (allCampaigns) {
                statsService
                    .getCampaignsScreensStatus(allCampaigns)
                    .then((res) => {
                        setCampaignStatus(res.message);
                    });
            }
        } else {
            setCampaignStatus(null);
        }
    }, [current, campaignsBySearch]);

    const getDataForActiveCampaigns = async () => {
        let statsByCampaignsHistory =
            await statsService.getStatsOfCampaignsByUserIdHistory(
                session.user.id
            );

        //set acumulated data for actives campaigns
        dispatch(
            dashboardActions.setReportsForActiveCampaigns(
                statsByCampaignsHistory?.data?.stats
            )
        );
    };

    useEffect(() => {
        if (session?.user?.id) {
            getDataForActiveCampaigns();
        }
    }, [session]);

    const endMessage = (
        <p style={{ textAlign: 'center' }}>
            <b>{t('sections.campaign-list.no-more-campaigns')}</b>
        </p>
    );

    const campaignsNotFound = (
        <p style={{ textAlign: 'center' }} className="my-10">
            <b>{t('sections.campaign-list.no-found-campaigns')}</b>
        </p>
    );

    useEffect(
        () => dispatch(campaignsActions.setCurrentStatus(status)),
        [status]
    );

    const toggleExpandAllCards = () => setIsCardsExpanded(!isCardsExpanded);

    const formatCampaignStatus = (status) =>
        status !== ''
            ? t('sections.campaign-list.' + status + '-campaigns')
            : '';

    useEffect(() => {
        // if we are having less than 5 campaigns then it means our list is trucated so we refresh it
        if (
            current.campaigns.length < 5 &&
            current.campaigns.length > 0 &&
            hasMoreCampaigns
        ) {
            fetchData();
        }
        fetch();
    }, [current, statuses]);

    const intervalRefList = useRef(null);
    const intervalRefListBySearch = useRef(null);
    //creates an interval that keeps the campaign's data up to date
    useEffect(() => {
        if (
            current?.campaigns?.length &&
            !campaignsBySearch.length &&
            !isSearch
        ) {
            clearInterval(intervalRefListBySearch.current);
            clearInterval(intervalRefList.current);

            intervalRefList.current = setInterval(() => {
                fetchData(current.campaigns.length);
            }, 110000);
        } else if (campaignsBySearch.length || isSearch) {
            clearInterval(intervalRefList.current);
        }

        return () => {
            clearInterval(intervalRefList.current);
        };
    }, [current, statuses, campaignsBySearch, isSearch]);

    useEffect(() => {
        return () => {
            clearInterval(intervalRefListBySearch.current);
        };
    }, []);

    const fetchData = async (limit) => {
        const page = 1;
        await service.fetchMoreDataOfCurrentCampaignList(page, limit);
        dispatch(campaignsActions.setCurrentStatus(status));
    };

    const fetch = async () => {
        if (
            Object.keys(statuses).length > 0 &&
            current.status !== '' &&
            current.status === status
        ) {
            if (
                current.campaigns.length === 0 &&
                hasMoreCampaigns &&
                initialFetch[status] === undefined
            ) {
                const newInitialFetch = Object.assign({}, initialFetch);
                newInitialFetch[status] = true;
                setInitialFetch(newInitialFetch);
                fetchData();
            }
        }

        if (!isCloned) {
            if (
                current.campaigns.length % 5 !== 0 &&
                hasMoreCampaigns &&
                isFetching
            ) {
                fetchData();
                service.onClickNextPageOfCampaignsList(current.page);
                //hacer que al hacer click se resetee el time del intervalo que updatea
                setIsFetching(false);
            }
        }
    };

    const fetchBySearch = async (value) => {
        const page = 1;
        const limit = 5;
        setIsLoadingBySearch(true);
        const campaignsBySearch = await service.getCampaigns(
            status,
            page,
            10,
            value
        );
        setIsLoadingBySearch(false);
        return campaignsBySearch;
    };

    const onSearch = async (value) => {
        setSearchValue(value);

        if (debounceRef.current) {
            clearInterval(debounceRef.current);
        }

        debounceRef.current = setTimeout(async () => {
            if (value.trim() !== '') {
                clearInterval(intervalRefList.current);
                clearInterval(intervalRefListBySearch.current);

                if (!isSearch) setIsSearch(true);
                const campaignsBySearchFetched = await fetchBySearch(value);
                // FIXME busqueda no funciona con caracteres especiales
                if (campaignsBySearchFetched.length) {
                    setCampaignsBySearch(campaignsBySearchFetched);
                    //create interval for keeping campaignsBySearch up to date
                    intervalRefListBySearch.current = setInterval(async () => {
                        const campaignsBySearchFetched = await fetchBySearch(
                            value
                        );
                        if (campaignsBySearchFetched) {
                            setCampaignsBySearch(campaignsBySearchFetched);
                        }
                    }, 110000);
                } else {
                    setCampaignsBySearch([]);
                }
            } else {
                setIsSearch(false);
                // para que no se quede es estado anterior
                // TODO: mejorar arquitectura de la busqueda
                setCampaignsBySearch([]);
            }
        }, 400);
    };

    useEffect(() => {
        onSearch(searchValue);
    }, [status]);

    return (
        <div className={containerClassName}>
            <Loading isLoading={isLoading || isLoadingBySearch} />
            <div className="font-extralight text-sm md:text-xl pl-2 mb-2">
                {formatCampaignStatus(current.status)}{' '}
                <span className="badge white -mt-2">
                    {statuses[current.status]}
                </span>
            </div>
            <div className="flex justify-between content-center mb-2 px-2">
                <div className="flex flex-wrap flex-end content-center">
                    <div
                        onClick={toggleExpandAllCards}
                        className={`rounded antialiased bg-white border h-8 px-2 py-1 text-xs cursor-pointer uppercase transition-colors duration-75 ${
                            isCardsExpanded
                                ? 'bg-blue-400 text-white'
                                : 'hover:bg-blue-400 hover:text-white'
                        }`}>
                        <FontAwesomeIcon
                            icon={['fal', 'sort']}
                            className="mt-1"
                            fixedWidth
                        />
                    </div>
                </div>
                <SearchInput
                    onSearch={onSearch}
                    placeholder={t(
                        'sections.campaign-list.brand-name-or-agency'
                    )}
                />
            </div>
            <div className="block w-full">
                {campaignsBySearch?.length > 0 || isSearch
                    ? campaignsBySearch.map((campaign, index) => (
                          <CampaignCard
                              key={campaign.id}
                              campaign={campaign}
                              isCardExpanded={isCardsExpanded}
                              setIsFetching={setIsFetching}
                              setIsCloned={setIsCloned}
                              status={status}
                              searchRefresh={onSearch}
                              screenStatus={
                                  campaignStatus ? campaignStatus[index] : {}
                              }
                              dashboardFromHome={dashboard}
                              filteredScreens={filteredScreens}
                          />
                      ))
                    : current.campaigns.map((campaign, index) => (
                          <CampaignCard
                              key={campaign.id}
                              campaign={campaign}
                              isCardExpanded={isCardsExpanded}
                              setIsFetching={setIsFetching}
                              setIsCloned={setIsCloned}
                              status={status}
                              screenStatus={
                                  campaignStatus ? campaignStatus[index] : {}
                              }
                              dashboardFromHome={dashboard}
                              filteredScreens={filteredScreens}
                          />
                      ))}
                {campaignsBySearch.length === 0 &&
                    isSearch &&
                    campaignsNotFound}
                {!isSearch &&
                    (hasMoreCampaigns ? (
                        <CommonButton
                            styleType={'primary'}
                            className={`${
                                isLoading || isLoadingBySearch ? 'hidden' : ''
                            } mt-2`}
                            onClick={service.onClickNextPageOfCampaignsList}
                            label={t('common.words.next')}
                        />
                    ) : (
                        endMessage
                    ))}
            </div>
        </div>
    );
};

export default CampaignList;
