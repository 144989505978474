import React from 'react';

import Breadcrumbs from './Breadcrumbs';
import TopWallet from './Wallet';
import UserTop from './User';

import LeftMenu from './LeftMenu';

function Header(params) {
    const currentSection = params.currentSection;
    const sectionPath = params.sectionPath;
    const subSection = params.subSection;
    const subSectionPath = params.subSectionPath;
    const subSectionOne = params.subSectionOne;
    const campaignId = params.campaignId;
    const extraIcon = params.extraIcon;

    return (
        <>
            {/* // Menu Lateral */}
            <div className="fixed bg-white z-40 hover:shadow-xl top-0 left-0 h-screen w-16 hover:w-80  transition-all duration-500 ease-out group">
                <img
                    src={
                        require(`../../images/${process.env.REACT_APP_WL_NAME}/iso.svg`)
                            .default
                    }
                    className="w-8 mt-4 pl-1 ml-3 opacity-25 group-hover:hidden"
                    alt={process.env.REACT_APP_WL_COMPANY_NAME}
                />
                <img
                    src={
                        require(`../../images/${process.env.REACT_APP_WL_NAME}/logo-color.svg`)
                            .default
                    }
                    className="h-12 mt-3 ml-1 hidden group-hover:block"
                    alt={process.env.REACT_APP_WL_COMPANY_NAME}
                />
                <LeftMenu />
            </div>
            {/* // Menu Superior */}
            <div className="fixed z-30 top-0 left-0 bg-gray-100 bg-opacity-75 backdrop border w-full p-2 md:p-4 flex justify-between font-light">
                <Breadcrumbs
                    currentSection={currentSection}
                    sectionPath={sectionPath}
                    subSection={subSection}
                    subSectionPath={subSectionPath}
                    subSectionOne={subSectionOne}
                    campaignId={campaignId}
                    extraIcon={extraIcon}
                />
                <div className="flex flex-wrap justify-end content-center text-xs">
                    <TopWallet />
                    <UserTop />
                </div>
            </div>
        </>
    );
}

export default Header;
