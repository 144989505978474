import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LoginPage from './LoginPage/LoginPage';
import DashboardPage from './DashboardPage/DashboardPage';
import DashboardPageIndividual from './DashboardPage/DashboardPageIndividual';
import CreateCampaignPage from './CampaignPage/CreateCampaignPage';
import CampaignList from './CampaignPage/CampaignList';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import SystemNotification from '../components/Notifications/SystemNotifications';
import { useAuth } from './UseAuth';
import Management from './Management/Management';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';
import UploadTesterPage from './UploadTesterPage/UploadTesterPage';
import ScreenApproval from './ScreenApproval/ScreenApproval';
import { NotFoundPage } from '../components/NotFoundPage';
import CreativityPublicView from './CreativityPublicView/CreativityPublicView';
import HTMLDisplay from './HTMLDisplay/HTMLDisplay';
import { NewProposal } from '../components/Proposal/NewProposal';
import ProposalList from './Proposals/CampaignProposalsList';
import CampaignProposalsList from './Proposals/CampaignProposalsList';
import ProposalBrief from '../components/Proposal/ProposalBrief';

function App() {
    const auth = useAuth();
    const session = auth.session;

    return (
        <>
            <SystemNotification />
            <Switch>
                <Route
                    exact
                    path="/commercial"
                    component={() => {
                        window.location.href =
                            process.env.REACT_APP_WL_COMPANY_URL;
                        return LoginPage();
                    }}
                />
                <Route exact path="/forgot" component={ForgotPassword} />
                <Route exact path="/reset" component={ResetPassword} />
                {session && 'token' in session ? (
                    <Route exact path="/" component={DashboardPage}></Route>
                ) : (
                    <Route exact path="/" component={LoginPage}></Route>
                )}
                <Route
                    exact
                    path="/upload-tester"
                    component={UploadTesterPage}
                />
                <PrivateRoute exact path="/dashboard">
                    <DashboardPage />
                </PrivateRoute>
                {/* <PrivateRoute exact path="/campaign">
          <DashboardPage/>
        </PrivateRoute> */}
                <PrivateRoute exact path="/dashboard/:id">
                    <DashboardPageIndividual />
                </PrivateRoute>
                <PrivateRoute exact path="/campaign/new">
                    <CreateCampaignPage />
                </PrivateRoute>
                <PrivateRoute exact path="/campaign/edit/:id">
                    <CreateCampaignPage />
                </PrivateRoute>
                <PrivateRoute exact path="/campaigns/status/:status">
                    <CampaignList />
                </PrivateRoute>
                <PrivateRoute exact path="/campaign-proposals/status/:status">
                    <CampaignProposalsList />
                </PrivateRoute>
                <PrivateRoute exact path="/management">
                    <Management />
                </PrivateRoute>
                <Route
                    path="/screen/:operation/:campaignid/:token"
                    component={ScreenApproval}
                />
                <Route
                    path="/screen/creative"
                    component={CreativityPublicView}
                />
                <PrivateRoute exact path="/proposal/:id">
                    <NewProposal />
                </PrivateRoute>
                <PrivateRoute exact path="/proposal/brief/:id">
                    <ProposalBrief />
                </PrivateRoute>
                <Route path="*" component={NotFoundPage} />
            </Switch>
        </>
    );
}

export default App;
