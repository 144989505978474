import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ModalViewCreative from '../../components/Campaign/WizardCreatives/UploadedCreatives/ModalViewCreative/ModalViewCreative';
import Loading from '../../components/Loading/Loading';
import { useService } from '../UseService';

function CreativityPublicView({}) {
    const service = useService();
    const useQuery = () => new URLSearchParams(useLocation().search);
    let query = useQuery();
    const campaignId = query.get('campaignid');
    const [creative, setCreative] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        service
            .getCreatives(campaignId)
            .then((creatives) => {
                setCreative(creatives[0]);
            })
            .finally(() => setIsLoading(false));
    }, []);
    const Background = `/${process.env.REACT_APP_WL_NAME}/images/cover-home.jpg`;

    return (
        <div className="w-full h-screen flex justify-between">
            <div
                className="w-full bg-cover bg-center"
                style={{ backgroundImage: 'url(' + Background + ')' }}>
                <img
                    src={
                        require(`../../images/${process.env.REACT_APP_WL_NAME}/logo-white.svg`)
                            .default
                    }
                    alt="logo"
                    className="h-28 mx-auto mt-4"
                />
                <Loading isLoading={isLoading} />
                {creative && (
                    <ModalViewCreative
                        creative={creative}
                        isActive={true}
                        isHandle={false}
                        size={'max-h-80'}
                    />
                )}
            </div>
        </div>
    );
}

export default CreativityPublicView;
