import React, { createContext, useContext, useState } from 'react';
import axios from '../utils/axios/axios';
import configAxios from '../utils/axios/axiosConfigurer';
import * as endpoints from '../constants/endpoints';
import { useDispatch } from 'react-redux';
import {
    campaignsActions,
    dashboardActions,
    extraActions,
    newCampaignActions,
    notificationsActions,
    screensActions,
    sessionActions,
    walletActions,
} from '../store';
import { useTranslation } from 'react-i18next';

const authContext = createContext();

export default function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {
    const dispatch = useDispatch();
    const supervisorSessionKey = 'supervisorSession';
    const localStorageKey = 'session';
    const { t } = useTranslation();
    const getSessionFromLocalStorage = () => {
        const session = localStorage.getItem(localStorageKey);
        if (session) {
            return JSON.parse(session);
        } else {
            return null;
        }
    };
    const [session, setSession] = useState(getSessionFromLocalStorage());
    const getAdminSessionFromLocalStorage = () => {
        const session = localStorage.getItem(supervisorSessionKey);
        if (session) {
            return JSON.parse(session);
        } else {
            return null;
        }
    };
    const getToken = async (username, password) => {
        let url = endpoints.getTokenV2;
        let data = `username=${username}&password=${password}`;

        try {
            let session = await axios.post(url, data);

            dispatch(sessionActions.setSession(session.data.data));

            return session.data.data;
        } catch (error) {
            dispatch(
                notificationsActions.setNotification({
                    title: t('sections.login-page.title'),
                    message: t(
                        error.response.data.errorCode || error.response.data
                    ),
                    type: 'error',
                })
            );
        }
    };

    const superviseAccount = async (userId) => {
        const campaignAdmin = localStorage.getItem('newCampaignData');
        const url = `${endpoints.superviseV2}?userId=${userId}`;
        try {
            const data = await axios.get(url);
            const advertiserSession = data.data.data;
            advertiserSession.isSupervised = true;
            localStorage.setItem(supervisorSessionKey, JSON.stringify(session));
            localStorage.setItem(
                localStorageKey,
                JSON.stringify(advertiserSession)
            );
            if (campaignAdmin) {
                localStorage.setItem('campaignAdmin', campaignAdmin);
            }
            window.location = '/';
        } catch (error) {
            if (!isNaN(error.response?.data?.errorCode)) {
                dispatch(
                    notificationsActions.setNotification({
                        type: 'warning',
                        title: t('common.notifications.error.title'),
                        message: t(
                            'errorCode.' + error.response.data.errorCode
                        ),
                    })
                );
            } else {
                dispatch(
                    notificationsActions.setNotification({
                        type: 'warning',
                        title: t('common.notifications.error.title'),
                        message: t('common.notifications.error.errorData'),
                    })
                );
            }
        }
    };

    const backToOriginalSupervisorAccount = () => {
        const campaignAdmin = localStorage.getItem('campaignAdmin');
        if (campaignAdmin) {
            localStorage.setItem('newCampaignData', campaignAdmin);
            localStorage.removeItem('campaignAdmin');
        } else {
            localStorage.removeItem('newCampaignData');
        }
        const supervisorSession = localStorage.getItem(supervisorSessionKey);
        localStorage.setItem(localStorageKey, supervisorSession);
        window.location = '/';
    };

    const isSupervising = () => {
        return 'isSupervised' in session && session.isSupervised;
    };

    const isSupervisor = () => {
        return session !== null && session.user.role === 5;
    };

    const isAdmin = () => {
        return session !== null && session.user.role === 1;
    };

    const isAdminOrSupervisor = () => {
        return isAdmin() || isSupervisor();
    };

    const saveSessionInLocalStorage = (session) => {
        localStorage.setItem(localStorageKey, JSON.stringify(session));
    };

    const removeFromLocalStorage = () => {
        localStorage.clear();
    };

    const signin = async (username, password) => {
        let data = await getToken(username, password);
        if (data?.token) {
            saveSessionInLocalStorage(data);
            configAxios(axios);
            setSession(data);
        }
    };

    const signup = (email, password) => {
        return true;
    };

    const removeStatesFromRedux = () => {
        dispatch(campaignsActions.reset());
        dispatch(dashboardActions.reset());
        dispatch(extraActions.reset());
        dispatch(newCampaignActions.reset());
        dispatch(notificationsActions.reset());
        dispatch(screensActions.reset());
        dispatch(sessionActions.reset());
        dispatch(walletActions.reset());
    };

    const signout = () => {
        removeFromLocalStorage();
        removeStatesFromRedux();
        setSession(null);
    };

    return {
        session,
        signin,
        signup,
        signout,
        superviseAccount,
        backToOriginalSupervisorAccount,
        isSupervising,
        isSupervisor,
        isAdmin,
        isAdminOrSupervisor,
        getAdminSessionFromLocalStorage,
    };
}
