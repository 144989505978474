import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import SwitcherButton from '../Buttons/CommonSwitcher';
import ProgressBar from '../Progress/ProgressBar';
import CommonDropdown from '../Buttons/CommonDropdown';
import { useTranslation } from 'react-i18next';
import { useService } from '../../containers/UseService';
import { useDispatch, useSelector } from 'react-redux';
import { campaignsActions, notificationsActions } from '../../store';
import { useHistory } from 'react-router-dom';
import {
    formatDateAccordingToBrowser,
    formatNumber,
    getPeriods,
    isNowPM,
    arrayToList,
    calculateSpentPercentage,
} from '../../utils/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PredictionDetails from '../DashboardIndividual/Predictions/PredictionDetails/PredictionDetails';
import HoursList from '../HoursList/HoursList';
import { screensActions } from '../../store';
import CopyText from '../CopyText/CopyText';

function CampaignCard(params) {
    const { reportsForActiveCampaigns } = useSelector(
        (state) => state.dashboard
    );
    const { t } = useTranslation();
    const dashboard = useSelector((state) => state.dashboard);
    const service = useService();
    const dispatch = useDispatch();
    const history = useHistory();
    const currencies = useSelector((state) => state.wallet.currencies);
    const [formatedCurrency, setFormatedCurrency] = useState('');
    const {
        campaign,
        isCardExpanded,
        screenStatus,
        dashboardFromHome,
        filteredScreens,
    } = params;
    const [isActiveSwitch, setisActiveSwitch] = useState(campaign.isActive);
    const id = campaign.id;
    const setIsFetching = params.setIsFetching;
    const setIsCloned = params.setIsCloned;
    const status = params.status;
    const searchRefresh = params.searchRefresh;
    const hoursFormatted = campaign.hours_enabled
        ? getPeriods([...campaign.hours_enabled])
        : {};

    const buyingTime = isNowPM() ? 'pm' : 'am';
    const campaignPrediction = dashboard.campaignPrediction;
    const isDelayed =
        campaignPrediction.endDate > 1 || campaignPrediction.endDate < -1;

    const [showPredictions, setShowPredictions] = useState(true);

    const showLoadingWhile = async (callback) => {
        dispatch(campaignsActions.setIsLoading(true));
        await callback();
        dispatch(campaignsActions.setIsLoading(false));
    };

    const [stats, setStats] = useState({});

    useEffect(() => {
        if (dashboardFromHome?.reportsForActiveCampaigns) {
            const currencyKey = Object.keys(
                dashboardFromHome.reportsForActiveCampaigns
            )[0];

            const campaignsArray =
                dashboardFromHome.reportsForActiveCampaigns[currencyKey];

            const foundCampaign = campaignsArray?.find(
                (campaign) => campaign.campaignid === id
            );

            if (foundCampaign) {
                const updatedCampaign = {
                    ...foundCampaign,
                    spentProgress: calculateSpentPercentage(
                        foundCampaign.budget,
                        foundCampaign.spent
                    ),
                };

                setStats(updatedCampaign);
            } else {
                setStats(foundCampaign);
            }
        }
    }, [dashboardFromHome]);

    //it cleans screens filters before send the user to edit campaign
    const editCampaign = () => {
        dispatch(screensActions.resetByKey('maxCpm'));
        dispatch(screensActions.resetByKey('selectedScreensLocal'));
        dispatch(screensActions.resetByKey('isFilterByAudiences'));
        dispatch(screensActions.resetByKey('showSelectedScreens'));
        dispatch(screensActions.resetByKey('selectedScreenTypes'));
        dispatch(screensActions.resetByKey('selectedPublishers'));
        dispatch(screensActions.resetByKey('selectedTiers'));
        history.push(`/campaign/edit/${campaign.id}`);
    };

    const getScreenName = (screenId) => {
        let campaing_screens = campaign.screen
            .map((screenId) => filteredScreens?.get(screenId))
            .filter(Boolean);

        let screenName = campaing_screens.find(
            (screen) => screen.id === screenId
        );
        return screenName ? screenName.name : '';
    };

    const duplicateCampaign = async () => {
        try {
            await showLoadingWhile(() =>
                service.duplicateCampaign(campaign, status)
            );
            let currentUrl = window.location.pathname;
            // update search if it's in draft
            if (searchRefresh && currentUrl.includes('draft')) {
                const inputSearch = document.getElementById(
                    'input-search-campaigns'
                ).value;
                await searchRefresh(inputSearch);
            }
            dispatch(
                notificationsActions.setNotification({
                    type: 'success',
                    title:
                        t('sections.campaign-list.card-campaign.clone') +
                        ' ' +
                        campaign.id,
                    message: t(
                        'sections.campaign-list.card-campaign.success-clone'
                    ),
                })
            );
            setIsCloned(true);
            setIsFetching(true);
        } catch (error) {
            console.error(error);
            dispatch(campaignsActions.setIsLoading(false));
        }
    };

    const restoreCampaign = () => {
        showLoadingWhile(() => service.restoreCampaign(campaign));
        setIsFetching(true);
    };

    const deleteCampaign = async () => {
        try {
            await showLoadingWhile(() =>
                service.deleteCampaign(campaign, status)
            );
            if (searchRefresh) {
                const inputSearch = document.getElementById(
                    'input-search-campaigns'
                ).value;
                await searchRefresh(inputSearch);
            }
            dispatch(
                notificationsActions.setNotification({
                    type: 'success',
                    title:
                        t(
                            'sections.campaign-list.card-campaign.delete-campaign'
                        ) +
                        ' ' +
                        campaign.id,
                    message: t('sections.campaign-list.success-on-delete'),
                })
            );
            setIsFetching(true);
        } catch (error) {
            console.error(error);
            dispatch(campaignsActions.setIsLoading(false));
        }
    };

    const activateOrDeactivate = () => {
        showLoadingWhile(async () => {
            if (campaign.isActive) {
                await service.deactivateCampaign(campaign);
            } else {
                await service.activateCampaign(campaign);
            }
            setisActiveSwitch(true);
        });
    };

    const statsDataForActiveCampaign = (campaign) => {
        if (
            campaign &&
            campaign.currency_name &&
            reportsForActiveCampaigns &&
            reportsForActiveCampaigns[campaign.currency_name]?.length
        ) {
            let statsForCampaign = reportsForActiveCampaigns[
                campaign.currency_name
            ].find((campaignStats) => campaignStats.campaignid === campaign.id);
            return statsForCampaign;
        }
    };

    const campaignList = [
        {
            icon: 'eye',
            label: t('sections.campaign-list.card-campaign.view-in-dashboard'),
            id: id,
            action: 'link',
            function: null,
            path: '/dashboard/',
            style: null,
        },
        {
            icon: 'pencil',
            label: t('sections.campaign-list.card-campaign.edit-dashboard'),
            id: id,
            action: 'function',
            function: editCampaign,
            path: null,
            style: null,
        },
        {
            icon: 'copy',
            label: t('sections.campaign-list.card-campaign.clone-campaign'),
            id: id,
            action: 'function',
            function: duplicateCampaign,
            path: null,
            style: null,
        },
        {
            icon: 'trash',
            type: 'delete',
            label: t('sections.campaign-list.card-campaign.delete-campaign'),
            message: 'sections.campaign-list.warning',
            id: id,
            action: 'function',
            function: deleteCampaign,
            path: null,
            style: 'text-red-500',
        },
    ];

    const restoreList = [
        {
            icon: 'trash-restore',
            label: t('sections.campaign-list.card-campaign.restore'),
            id: id,
            action: 'function',
            function: restoreCampaign,
            path: null,
            style: null,
        },
    ];

    useEffect(() => {
        if (currencies.length > 0) {
            const currency = currencies.find(
                (currency) => currency.id === campaign.currency_id
            );
            if (currency) setFormatedCurrency(currency.symbol);
        }
    }, [currencies]);

    useEffect(() => {
        if (isActiveSwitch) {
            setisActiveSwitch(campaign.isActive);
        }
    }, [isActiveSwitch]);

    const hasPendingScreens =
        screenStatus?.status?.pendingScreens?.total > 0 ||
        screenStatus?.status?.disapprovedScreens?.total > 0;
    const pendingScreensIcon = () => {
        if (hasPendingScreens) {
            return (
                <FontAwesomeIcon
                    onClick={() => setShowPredictions(!showPredictions)}
                    icon={['fad', 'exclamation-triangle']}
                    fixedWidth
                    className="ml-2 text-yellow-500 cursor-pointer"
                />
            );
        }
    };

    const hasPredictions = Object.keys(campaignPrediction) > 0;

    return (
        <>
            <div className="group module m-1 mb-4 w-full p-2">
                <div className="flex-col">
                    <div className="header flex justify-between">
                        <div className={'title text-sm font-light w-full'}>
                            {hasPendingScreens && (
                                <PredictionDetails
                                    campaignScreensStatus={screenStatus}
                                    campaign={campaign}
                                    setHandleDataSchedule={setShowPredictions}
                                    handleDataSchedule={showPredictions}
                                    isDelayed={isDelayed}
                                    campaignPrediction={
                                        hasPredictions
                                            ? campaignPrediction
                                            : null
                                    }
                                />
                            )}
                            <Link to={`/dashboard/${id}`}>
                                <span className="badge font-normal font-mono text-gray-500">
                                    #{id}
                                </span>{' '}
                                <span className="font-semibold">
                                    {' '}
                                    {campaign.brand}
                                </span>{' '}
                                <span>{campaign.name}</span>
                            </Link>
                            {/* {isDelayed ? (
                                <>
                                    <ReactTooltip
                                        effect="solid"
                                        className="w-48 p-2"
                                        id="etaTooltip"
                                        aria-haspopup="true"
                                    >
                                        {campaignPrediction.endDate > 0
                                            ? t(
                                                  "sections.dashboard-individual.predictions.increase-spending"
                                              )
                                            : t(
                                                  "sections.dashboard-individual.predictions.decrease-spending"
                                              )}
                                    </ReactTooltip>
                                    <a data-tip data-for='etaTooltip' >
                                        <FontAwesomeIcon
                                        icon={["fad", "exclamation-triangle"]}
                                        fixedWidth
                                        className="mx-2 text-red-300"
                                        />
                                    </a>
                                </>
                            ) : null } */}
                            {pendingScreensIcon()}
                        </div>
                        <div className="actions flex justify-end">
                            {(campaign.status === 'ACTIVE' ||
                                campaign.status === 'INACTIVE') && (
                                <SwitcherButton
                                    onToggle={activateOrDeactivate}
                                    status={isActiveSwitch}
                                    labelOn={t(
                                        'sections.campaign-list.card-campaign.active'
                                    )}
                                    labelOff={t(
                                        'sections.campaign-list.card-campaign.inactive'
                                    )}
                                />
                            )}
                            {campaign.status !== 'DELETED' ? (
                                <CommonDropdown
                                    icon={'cog'}
                                    list={campaignList}
                                />
                            ) : (
                                <CommonDropdown
                                    icon={'cog'}
                                    list={restoreList}
                                    isDeleted
                                />
                            )}
                        </div>
                    </div>
                    <div className="w-full">
                        <ProgressBar
                            progress={
                                stats?.spentProgress
                                    ? stats.spentProgress
                                    : campaign.spentProgress
                            }
                            isActive={campaign.isActive}
                        />
                    </div>
                </div>
                <div
                    className={`body bg-gray-50 mt-2 rounded-lg flex flex-wrap items-stretch space-x-2 divide-y divide-x divide-dotted overflow-y-auto opacity-0 h-0 group-hover:h-52 group-hover:opacity-100 transition-all ease-in-out duration-150 delay-300 ${
                        isCardExpanded ? 'h-52 opacity-100' : 'h-0 opacity-0'
                    }`}>
                    <div className="p-2 flex-grow">
                        <div className="title font-light text-sm text-gray-600">
                            {t(
                                'sections.campaigns.new-campaign.wizard-basics.budget.title'
                            )}
                        </div>
                        <div
                            className={`${
                                campaign.status === 'ACTIVE' ? '' : 'hidden'
                            } text-xs text-gray-400`}>
                            {t(
                                'sections.campaign-list.card-campaign.budget-explain'
                            )}
                        </div>
                        <div className="font-semibold text-lg">
                            <span className="text-xs font-thin text-gray-500">
                                {formatedCurrency}
                            </span>{' '}
                            {campaign.status === 'ACTIVE' ||
                            campaign.status === 'JOB_SUCCESS' ? (
                                <>
                                    {formatNumber(
                                        stats?.spent
                                            ? stats.spent
                                            : campaign.totalSpent,
                                        'rounded'
                                    )}{' '}
                                    <span className="font-light text-sm">
                                        /{' '}
                                        <span className="text-xs font-thin text-gray-500">
                                            {formatedCurrency}
                                        </span>{' '}
                                        {formatNumber(
                                            campaign.budget,
                                            'currency'
                                        )}
                                    </span>
                                </>
                            ) : (
                                formatNumber(campaign.budget, 'currency')
                            )}
                        </div>
                        {/* FIXME: Uncomment code when the CPM is operational */}
                        {/* <div className="font-semibold text-lg">
                            <div className="font-light text-sm text-gray-600">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-screens.screens.table-screenCPM'
                                )}
                                :{' '}
                                {campaign.max_cpm === 0 && (
                                    <span>
                                        {t(
                                            'sections.campaign-list.card-campaign.without-cpm'
                                        )}
                                    </span>
                                )}{' '}
                                {campaign.max_cpm !== 0 && (
                                    <>
                                        <span className="text-xs font-thin text-gray-500">
                                            {' '}
                                            {formatedCurrency}
                                        </span>{' '}
                                        <span>
                                            {formatNumber(
                                                campaign.max_cpm,
                                                'currency'
                                            )}
                                        </span>
                                    </>
                                )}
                            </div>
                        </div> */}
                    </div>
                    <div
                        className={`p-2 ${
                            campaign.status === 'ACTIVE' ? '' : 'hidden'
                        }`}>
                        <div className="title font-light text-sm text-gray-600">
                            {t(
                                'sections.campaigns.new-campaign.wizard-review.blocks.basics.impacts'
                            )}
                        </div>
                        <div className="text-xs text-gray-400">
                            {t(
                                'sections.campaign-list.card-campaign.impacts-explain'
                            )}
                        </div>
                        <div className="font-semibold text-lg">
                            {formatNumber(
                                statsDataForActiveCampaign(campaign)?.impacts,
                                'rounded'
                            )}
                        </div>
                    </div>
                    <div
                        className={`p-2 ${
                            campaign.status === 'ACTIVE' ? '' : 'hidden'
                        }`}>
                        <div className="title font-light text-sm text-gray-600">
                            {t(
                                'sections.campaign-list.card-campaign.campaign-status'
                            )}
                        </div>
                        <div className="text-xs text-gray-400">
                            {t(
                                'sections.campaign-list.card-campaign.screen-status'
                            )}
                        </div>
                        <ul>
                            <li className="text-xs text-gray-600">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-screens.screens.pending'
                                )}
                                : {screenStatus?.status?.pendingScreens?.total}
                            </li>
                            <li className="text-xs text-gray-600">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-screens.screens.approve'
                                )}
                                : {screenStatus?.status?.approvedScreens?.total}
                            </li>
                            <li className="text-xs text-gray-600">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-screens.screens.disapproved'
                                )}
                                :{' '}
                                {
                                    screenStatus?.status?.disapprovedScreens
                                        ?.total
                                }
                            </li>
                        </ul>
                    </div>
                    <div className="p-2 flex-grow">
                        <div className="title font-light text-sm text-gray-600">
                            {t(
                                'sections.campaigns.new-campaign.wizard-basics.dailyCap.title'
                            ) + ':'}{' '}
                            {campaign.isDailyCap && (
                                <>
                                    {' '}
                                    <span className="font-semibold">
                                        {campaign.dailyCapType}
                                    </span>
                                </>
                            )}
                        </div>
                        <div
                            className={`${
                                campaign.status === 'ACTIVE' ? '' : 'hidden'
                            } text-xs text-gray-400`}>
                            {t(
                                'sections.campaign-list.card-campaign.daily-cap-explain'
                            )}
                        </div>
                        {campaign.isDailyCap ? (
                            <>
                                <div className="font-semibold text-lg">
                                    {campaign.auto_distribution === 0 ? (
                                        <>
                                            {campaign.limit_type === 1 ? (
                                                <>
                                                    <span className="text-xs font-thin text-gray-500">
                                                        {formatedCurrency}
                                                    </span>{' '}
                                                    {campaign.status ===
                                                    'ACTIVE'
                                                        ? formatNumber(
                                                              campaign.spent,
                                                              'currency'
                                                          )
                                                        : formatNumber(
                                                              campaign.daily_limit,
                                                              'currency'
                                                          )}
                                                    <span
                                                        className={`font-light text-sm ${
                                                            campaign.status ===
                                                            'ACTIVE'
                                                                ? ''
                                                                : 'hidden'
                                                        }`}>
                                                        {' '}
                                                        /{' '}
                                                        <span className="text-xs font-thin text-gray-500">
                                                            {formatedCurrency}
                                                        </span>{' '}
                                                        {formatNumber(
                                                            campaign.daily_limit,
                                                            'currency'
                                                        )}
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <span className="text-xs font-thin text-gray-500">
                                                        {t()}
                                                    </span>{' '}
                                                    {campaign.status ===
                                                    'ACTIVE'
                                                        ? formatNumber(
                                                              campaign.impressions,
                                                              'rounded'
                                                          )
                                                        : formatNumber(
                                                              campaign.daily_limit,
                                                              'ecpm'
                                                          )}
                                                    <span
                                                        className={`font-light text-sm ${
                                                            campaign.status ===
                                                            'ACTIVE'
                                                                ? ''
                                                                : 'hidden'
                                                        }`}>
                                                        {' '}
                                                        /{' '}
                                                        {formatNumber(
                                                            campaign.daily_limit,
                                                            'ecpm'
                                                        )}
                                                    </span>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        t(
                                            'sections.campaigns.new-campaign.wizard-briefBar.basics.automatic'
                                        )
                                    )}
                                </div>
                                {campaign.status === 'ACTIVE' &&
                                    campaign.auto_distribution === 0 && (
                                        <>
                                            <div className="currentStatus flex justify-between">
                                                <div
                                                    className="mt-2 text-gray-400"
                                                    style={{ fontSize: '50%' }}>
                                                    {formatNumber(
                                                        campaign.dailyProgress,
                                                        'rounded'
                                                    )}
                                                    %{' '}
                                                    {t(
                                                        `sections.campaign-list.card-campaign.daily-cap-porcent-${
                                                            campaign.limit_type ===
                                                            1
                                                                ? 'budget'
                                                                : 'impressions'
                                                        }`
                                                    )}
                                                    .
                                                </div>
                                            </div>
                                            <ProgressBar
                                                progress={
                                                    campaign.dailyProgress
                                                }
                                                isActive={campaign.isActive}
                                            />
                                        </>
                                    )}
                            </>
                        ) : (
                            <div className="font-semibold text-lg">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-briefBar.basics.unlimited'
                                )}
                            </div>
                        )}
                        <div className="title font-light text-sm text-gray-600">
                            {t(
                                'sections.campaigns.new-campaign.wizard-basics.timing.pacing'
                            ) + ':'}{' '}
                            {
                                <>
                                    {' '}
                                    <span>
                                        {campaign.asap
                                            ? t(
                                                  'sections.campaigns.new-campaign.wizard-basics.timing.ASAP'
                                              )
                                            : t(
                                                  'sections.campaigns.new-campaign.wizard-basics.timing.evenly'
                                              )}
                                    </span>
                                </>
                            }
                        </div>
                    </div>
                    <div className="pl-0 pt-2 w-full flex flex-wrap h-auto space-x-2 justify-between mx-3 px-4 ">
                        <span
                            className={`${
                                campaign.days.length > 0 ? '' : 'hidden'
                            } text-xs text-gray-400 font-semibold`}>
                            {t(
                                'sections.campaigns.new-campaign.wizard-basics.timing.days'
                            )}
                            :<br />{' '}
                            <span className="font-light text-black">
                                {campaign.days}
                            </span>
                        </span>
                        <br />
                        <span
                            className={`${
                                campaign.days.length > 0 ? '' : 'hidden'
                            } text-xs text-gray-400 font-semibold`}>
                            {t('sections.campaign-list.card-campaign.from')}:{' '}
                            <span className="font-light text-black">
                                <br />
                                {formatDateAccordingToBrowser(
                                    campaign.start_period
                                )}
                            </span>
                        </span>
                        <br />
                        <span
                            className={`${
                                campaign.days.length > 0 ? '' : 'hidden'
                            } text-xs text-gray-400 font-semibold`}>
                            {t('sections.campaign-list.card-campaign.to')}:{' '}
                            <span className="font-light text-black">
                                <br />
                                {formatDateAccordingToBrowser(
                                    campaign.end_period
                                )}
                            </span>
                        </span>
                        <br />
                        <HoursList
                            hoursFormatted={hoursFormatted}
                            hoursEnabled={campaign.hours_enabled}
                            isCampaignCard={true}
                            campaignDays={campaign.days_enabled}
                            campaignStart={campaign.start_period}
                            campaignEnd={campaign.end_period}
                        />
                    </div>
                    <div className="flex justify-between flex-wrap">
                        <div className="pt-2">
                            <div className="title font-light text-sm text-gray-600">
                                {t(
                                    'sections.campaign-list.card-campaign.overview'
                                )}
                            </div>
                            <div className="text-xs text-gray-400">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-screens.screens.screens-title'
                                )}
                            </div>
                            <div>
                                <ul className="list-none">
                                    {campaign.screen.map((screenId, index) => (
                                        <li
                                            className="flex flex-row space-x-2"
                                            key={index}>
                                            <div className="basis-1/6 text-xs text-gray-600 self-baseline">
                                                <CopyText
                                                    text={screenId.toUpperCase()}
                                                />
                                            </div>
                                            <div className="basis-5/6 text-ellipsis self-baseline text-sm">
                                                {getScreenName(screenId)}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CampaignCard;
