import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

const ValidateWithProposal = ({ errors, t }) => {
    return errors?.length ? (
        <div>
            <ReactTooltip
                effect="solid"
                className="w-208 "
                id="notValid"
                aria-haspopup="true">
                <ul className="list-none text-white">
                    {errors.map((error, index) => {
                        return (
                            <li key={index} className="py-1">
                                {error}
                            </li>
                        );
                    })}
                </ul>
            </ReactTooltip>
            <span data-tip data-for="notValid">
                <FontAwesomeIcon
                    icon={['fad', 'exclamation-triangle']}
                    fixedWidth
                    size="2x"
                    className="mr-1 text-yellow-300"
                />{' '}
            </span>
            <span className="font-light">
                {t('common.campaign-proposals.differences')}
            </span>
            <div className="mt-2"></div>
        </div>
    ) : (
        <div></div>
    );
};

export default ValidateWithProposal;
