import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';

// Componente InputSelect
const CreatableSelectInput = (props) => {
    const {
        id = '',
        placeholder,
        label,
        containerClassName = '',
        options,
        onChange,
        onCreateNew,
        value,
        createOptionPosition,
    } = props;

    const [isLoading, setIsLoading] = useState(false);

    const handleCreate = (inputValue) => {
        if (onCreateNew) {
            setIsLoading(true);
            setTimeout(() => {
                const newOption = {
                    label: inputValue,
                    value: inputValue.toLowerCase().replace(/\W/g, ''),
                };
                onCreateNew(newOption.label);
                setIsLoading(false);
            }, 1000);
        }
    };
    return (
        <div className={`mx-auto sm:mx-0 ${containerClassName}`}>
            <label htmlFor={id} className="text-sm font-medium text-gray-700">
                {label}
            </label>
            <div className="grid grid-cols-7">
                <div className="col-span-6">
                    <CreatableSelect
                        isClearable
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        onChange={onChange}
                        onCreateOption={handleCreate}
                        options={options}
                        value={value}
                        placeholder={placeholder}
                        formatCreateLabel={(inputValue) =>
                            `Crear "${inputValue}"`
                        }
                        createOptionPosition={createOptionPosition}
                    />
                </div>
                <div
                    className={`flex justify-center items-center col-span-1 ${
                        !value ? 'invisible' : ''
                    }`}>
                    <FontAwesomeIcon
                        icon={['fas', 'check']}
                        fixedWidth
                        className="ml-1 text-green-500"
                    />
                </div>
            </div>
        </div>
    );
};

export default CreatableSelectInput;
