import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { formatDateAccordingToBrowser } from '../../utils/functions';
import CommonDropdown from '../Buttons/CommonDropdown';
import { NavLink } from 'react-router-dom';

function CampaignProposalCard({ campaign, deleteProposal }) {
    const { t } = useTranslation();

    const proposalActions = [
        {
            icon: 'pencil',
            type: 'edit',
            label: t('common.campaign-proposals.action.edit'),
            id: campaign.id,
            action: 'link',
            function: null,
            path: `/proposal/`,
            style: null,
        },
        {
            icon: 'trash',
            type: 'delete',
            label: t('common.campaign-proposals.action.delete'),
            id: campaign.id,
            message: 'common.campaign-proposals.delete-message',
            action: 'function',
            function: () => deleteProposal(campaign.id),
            path: null,
            style: 'text-red-500',
        },
    ];

    return (
        <div className="group module m-1 mb-4 w-full p-2">
            <div className="header flex justify-between">
                <div className="title text-sm font-light w-full">
                    <Link to={`/proposal/${campaign.id}`}>
                        <span className="badge font-normal font-mono text-gray-500">
                            #{campaign.id}
                        </span>
                        <span className="font-semibold">
                            {' '}
                            {`${
                                campaign?.brand ||
                                t('common.campaign-proposals.no-brand')
                            } `}
                        </span>
                        <span>
                            {campaign?.name ||
                                t('common.campaign-proposals.no-name')}
                        </span>
                    </Link>
                    <div className="actions flex justify-end">
                        <CommonDropdown icon={'cog'} list={proposalActions} />
                    </div>
                </div>
            </div>
            <div
                className={`text-sm font-light body mt-2 rounded-lg flex flex-wrap items-stretch space-x-2 divide-y divide-x divide-dotted opacity-0 h-0 group-hover:h-28 group-hover:opacity-100 transition-all ease-in-out duration-150 delay-300 overflow-y-auto`}>
                {campaign.proposals && campaign.proposals.length ? (
                    <div className=" font-light w-full">
                        <span className=" mb-100">
                            {t('common.campaign-proposals.proposals-briefs')}
                        </span>
                        {campaign.proposals.map((proposal, index) => (
                            <div
                                className="w-full flex justify-evenly mt-5"
                                key={proposal.id}>
                                <p>
                                    {` `}
                                    <strong>{`v${
                                        campaign.proposals.length - index
                                    }`}</strong>
                                    {`  ${formatDateAccordingToBrowser(
                                        proposal.created_at
                                    )}`}
                                </p>
                                <NavLink
                                    to={`/proposal/brief/${proposal.id}`}
                                    className="text-sm text-blue-500">
                                    {t('common.campaign-proposals.see-brief')}
                                </NavLink>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div
                        className="w-full flex font-light align-center justify-center"
                        style={{ minWidth: '100%' }}>
                        {t('common.campaign-proposals.without-brief')}
                    </div>
                )}
            </div>
        </div>
    );
}

export default CampaignProposalCard;
