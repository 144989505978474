import React, { useState, useEffect, useRef } from 'react';
import supercluster from 'points-cluster';
import GoogleMapReact, { Circle } from 'google-map-react';
import ScreenMarker from './ScreenMarker';
import ScreenDetails from './ScreenDetails';
import styles from './mapStyles.json';
import ClusterMarker from './ClusterMarker/ClusterMarker';
import StreetView from './StreetView/StreetViewContent';
import { newCampaignActions } from '../../store';
import { newCampaignReducer } from '../../store/newCampaign';
import SwitcherButton from '../Buttons/CommonSwitcher';
import { useTranslation } from 'react-i18next';

function MapCanvas(params) {
    const {
        resetMap,
        setResetMap,
        selectedScreensLocal,
        newCampaign,
        dispatch,
        setFilteredScreensLocal,
        filteredScreensByLocation,
        screensWithCpm,
        screenOpened,
        setScreenOpened,
        filterScreenByPoi,
    } = params;
    const [clusters, setClusters] = useState([]);
    const currentPositionMap = newCampaign.currentPositionMap;
    const zones =
        (newCampaign?.mapLocations?.zones?.length ?? 0) !== 0
            ? newCampaign.mapLocations.zones
            : newCampaign?.location?.zones ?? [];

    const mapLocations = newCampaign.mapLocations
        ? newCampaign.mapLocations
        : null;
    const [showStreetView, setShowStreetView] = useState(false);
    const toggleStreetView = () => setShowStreetView(!showStreetView);
    const [mapReference, setMapReference] = useState(null);
    const [mapsReference, setMapsReference] = useState(null);
    const [zonesInMap, setZonesInMap] = useState([]);
    const [circleCenter, setCircleCenter] = useState({ lat: 0, lng: 0 });
    const zoneNormal = zonesInMap;
    const { t } = useTranslation();
    const { location } = newCampaign;

    const mapRef = useRef();

    const [streetViewPanoramaOptions, setStreetViewPanoramaOptions] = useState({
        position: { lat: 0, lng: 0 },
        pov: { heading: 100, pitch: 0 },
        zoom: 1,
    });

    const initialPosition = {
        lat: params.lat,
        lng: params.lng,
    };

    const MAP = {
        defaultZoom: params.defaultZoom,
        defaultCenter: initialPosition,
        options: {
            styles: styles,
            maxZoom: 19,
        },
    };

    const [mapOptions, setMapOptions] = useState({
        center: MAP.defaultCenter,
        zoom: MAP.defaultZoom,
    });

    const mapButtons = [
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-basics.location.map.traffic-toggle.label'
            ),
            onClick: () => setTrafficLayer(!trafficLayer),
            hintLabel: t(
                'sections.campaigns.new-campaign.wizard-basics.location.map.traffic-toggle.hint'
            ),
        },
    ];

    const [mapLayers, setMapLayers] = useState([]);
    const [trafficLayer, setTrafficLayer] = useState(false);

    useEffect(() => {
        if (trafficLayer) {
            setMapLayers([...mapLayers, 'TrafficLayer']);
        } else {
            setMapLayers(mapLayers.filter((item) => item !== 'TrafficLayer'));
        }
    }, [trafficLayer]);

    useEffect(() => {
        if (!resetMap) {
            setResetMap(true);
        }
    }, [resetMap]);

    const getClusters = () => {
        const clusters = supercluster(selectedScreensLocal, {
            minZoom: 0,
            maxZoom: 16,
            radius: 60,
        });

        return clusters(mapOptions);
    };

    const onShowStreetView = (screen) => {
        const newOptions = JSON.parse(
            JSON.stringify(streetViewPanoramaOptions)
        );
        newOptions.position = {
            lat: screen.geo.lat,
            lng: screen.geo.lon,
        };

        setStreetViewPanoramaOptions(newOptions);
        setShowStreetView(true);
    };

    const createClusters = () => {
        setClusters(
            mapOptions.bounds
                ? getClusters().map(({ wx, wy, numPoints, points }) => ({
                      lat: wy,
                      lng: wx,
                      numPoints,
                      id: `${numPoints}_${points[0].id}`,
                      points,
                  }))
                : []
        );
    };

    useEffect(createClusters, [selectedScreensLocal, mapOptions]);

    const handleMapChange = ({ center, zoom, bounds }) => {
        setMapOptions({ center, zoom, bounds });
    };

    const handleApiLoaded = (map, maps) => {
        setMapReference(map);
        setMapsReference(maps);
    };

    const [circleCenterChanged, setCircleCenterChanged] = useState(false);

    useEffect(() => {}, [circleCenterChanged]);

    function createCircleInMap(place, radius = undefined) {
        let circle = new mapsReference.Circle({
            key: place.ref,
            strokeColor: place.color,
            strokeOpacity: 1,
            strokeWeight: 0,
            fillColor: place.color,
            fillOpacity: 0.2,
            map: mapReference,
            center: {
                lat: parseFloat(place.lat),
                lng: parseFloat(place.lng),
            },
            radius: radius ? radius : place.radius,
        });

        let initialCircleValue = circle.getCenter();
        let centerChanged = false;
        let ignore = false;

        circle.addListener('radius_changed', () => {
            for (let i = 0; i < zones.length; i++) {
                for (let j = 0; j < zones[i].results.length; j++) {
                    if (
                        zones[i].results[j].ref === circle.key &&
                        zones.length > 0 &&
                        circle.getRadius() !== 0
                    ) {
                        dispatch(
                            newCampaignActions.setLocationZonesRadius({
                                i: i,
                                j: j,
                                value: circle.getRadius(),
                            })
                        );
                    }
                }
            }
        });

        circle.addListener('center_changed', () => {
            for (let i = 0; i < zones.length; i++) {
                for (let j = 0; j < zones[i].results.length; j++) {
                    if (
                        zones[i].results[j].ref === circle.key &&
                        zones.length > 0 &&
                        circle.getRadius() !== 0
                    ) {
                        dispatch(
                            newCampaignActions.setLocationZonesCenterToInitialValue(
                                {
                                    i: i,
                                    j: j,
                                    lat: initialCircleValue.lat(),
                                    lng: initialCircleValue.lng(),
                                }
                            )
                        );
                        if (ignore) {
                            ignore = false;
                            return;
                        }
                        circle.setEditable(false);
                        ignore = true;
                        circle.setCenter({
                            lat: initialCircleValue.lat(),
                            lng: initialCircleValue.lng(),
                        });
                        circle.setEditable(true);
                        centerChanged = true;
                        setCircleCenterChanged(true);
                    }
                }
            }
        });

        circle.addListener('mouseover', () => {
            circle.setEditable(true);
        });
        circle.addListener('mouseout', () => {
            circle.setEditable(false);
        });
        circle.addListener('drag', () => {});

        return circle;
    }

    function createCenterCircle(place) {
        return new mapsReference.Circle({
            key: place.ref,
            strokeColor: place.color,
            strokeOpacity: 0,
            strokeWeight: 0,
            fillColor: place.color,
            fillOpacity: 1,
            map: mapReference,
            center: {
                lat: parseFloat(place.lat),
                lng: parseFloat(place.lng),
            },
            radius: place.radius / 10,
        });
    }

    function removeCircleMaps(circle) {
        mapsReference.event.clearListeners(circle, 'click');
        mapsReference.event.clearListeners(circle, 'contextmenu');
        circle.setRadius(0);
        circle.setMap(null);
    }

    const updateRadiusCircleRedux = (newCircle) => {
        const newRadius = newCircle.getRadius();
        const colorCircle = newCircle.strokeColor;
        const zonesCurrent = newCampaign.location.zones;
        let newZones = [];
        let newZone = {};
        let oldRadius = 0;
        zonesCurrent.forEach((zone) => {
            zone.results.map((result, index) => {
                if (result.color === colorCircle) {
                    oldRadius = result.radius;
                    const newResultCopy = Object.assign({}, result);
                    newResultCopy.radius = newRadius;
                    newZone = { ...zone };
                    newZone.results = [newResultCopy];
                } else newZones.push({ ...zone });
            });
        });
        //add to the last place the edited zone for the map zoom
        newZones.push(newZone);
        const newLocation = Object.assign({}, newCampaign.location);
        newLocation.zones = newZones;
        dispatch(newCampaignActions.setLocation(newLocation));
        newCircle.setEditable(false);
        if (newRadius !== oldRadius) {
            //! The only way that the radios are updated within the events since the google api saves the outdated radios.
            //! this causes the events to be removed and recreated in useEffetc again with the new radius
            setCircleCenter({
                lat: newCircle.getCenter().lat(),
                lng: newCircle.getCenter().lng(),
            });
        }
    };

    // Draw zones
    useEffect(() => {
        let handlerClickMaps = undefined;
        let handlerMenuMaps = undefined;

        if (mapReference && mapsReference) {
            // const trafficLayer = new mapsReference.TrafficLayer()

            // trafficLayer.setMap(mapReference)

            // ! remove all circles on the map
            zonesInMap.forEach((zone) => {
                removeCircleMaps(zone);
            });

            const zonesAddedInMap = [];
            // ! create the circles that exist with old center but new radius if exist
            zones.forEach((zone) => {
                for (let place of zone.results) {
                    // circles are controled by google
                    const newCircleInMap = createCircleInMap(place);
                    const newCenterIncircle = createCenterCircle(place);
                    zonesAddedInMap.push(newCenterIncircle, newCircleInMap);
                }
            });

            setZonesInMap(zonesAddedInMap);

            zonesAddedInMap.forEach((lastZoneAdded) => {
                if (lastZoneAdded.key === 'circle') {
                    const centerCircle = zonesAddedInMap.find(
                        (circle) =>
                            circle.key === 'centerCircle' &&
                            circle.strokeColor === lastZoneAdded.strokeColor
                    );
                }
            });
        }

        return () => {
            if (handlerClickMaps) {
                mapsReference.event.removeListener(handlerClickMaps);
                mapsReference.event.removeListener(handlerMenuMaps);
            }
        };
    }, [
        mapReference,
        mapsReference,
        zones.map((zone) => zone.results[0].color).toString(),
        zones,
        circleCenter,
    ]);

    useEffect(() => {
        if (zonesInMap.length > 0) {
            const zoneAdded = zonesInMap[zonesInMap.length - 1];
            mapReference.fitBounds(zoneAdded.getBounds());
        }
    }, [zonesInMap]);

    useEffect(() => {
        if (
            currentPositionMap &&
            'lat' in currentPositionMap &&
            'lng' in currentPositionMap &&
            'zoom' in currentPositionMap
        ) {
            const center = {
                lat: currentPositionMap.lat,
                lng: currentPositionMap.lng,
            };
            setMapOptions({ center, zoom: currentPositionMap.zoom });
        }
    }, [currentPositionMap]);

    useEffect(() => {
        if (selectedScreensLocal.length > 0) {
            const lastScreenAdded = selectedScreensLocal[0];
            const center = {
                lat: lastScreenAdded.geo.lat,
                lng: lastScreenAdded.geo.lon,
            };
            setMapOptions({ zoom: 16, center });
        }
    }, [selectedScreensLocal]);

    const handleClickClusterMarker = (marker) => {
        const center = { lat: marker.lat, lng: marker.lng };
        const currentZoom = mapReference.getZoom();
        setMapOptions({ zoom: currentZoom + 2, center });
    };

    useEffect(() => {
        /*global google*/
        let count = 0;
        zones.forEach((zone) => {
            if (zone.active) count++;
        });
        let filteredByZone = [];
        if (count > 0 && filteredScreensByLocation.length > 0) {
            filteredScreensByLocation.forEach((screen) => {
                let isInZone = false;
                if (
                    'lat' in screen.geo &&
                    'lon' in screen.geo &&
                    mapsReference
                ) {
                    zones.forEach((zone) => {
                        if (zone.active === true || zone.active === 1) {
                            if (!isInZone) {
                                zone.results.forEach((result) => {
                                    if (!isInZone) {
                                        isInZone =
                                            mapsReference.geometry.spherical.computeDistanceBetween(
                                                new mapsReference.LatLng(
                                                    screen.geo?.lat,
                                                    screen.geo?.lon
                                                ),
                                                new mapsReference.LatLng(
                                                    result.lat,
                                                    result.lng
                                                )
                                            ) <= result.radius;
                                    }
                                });
                            }
                        }
                    });
                    if (isInZone) {
                        filteredByZone.push(screen);
                    } else {
                    }
                }
            });
            setFilteredScreensLocal(filteredByZone);
        } else {
            setFilteredScreensLocal(filteredScreensByLocation);
        }
    }, [
        zones,
        filteredScreensByLocation,
        mapsReference,
        location,
        mapLocations,
        filterScreenByPoi,
    ]);

    return (
        <>
            <ScreenDetails
                onCloseScreen={setScreenOpened}
                screenOpened={screenOpened}
                selectedCountries={newCampaign.location.selectedCountries}
                timezone={newCampaign.timing.selectedTimezone.gmt}
                screensWithCpm={screensWithCpm}
            />

            {/* {(<CustomMapButton
                buttons={mapButtons}
                childClassName={`w-15 font-sans font-bold text-gray-500 bg-white p-2 h-10 z-50 rounded-lg`}
                fatherClassName="flex row absolute m-3 gap-1"
            />)} */}
            {mapButtons.map((item, index) => {
                return (
                    <div
                        className="z-10 w-auto h-auto px-2 py-1 absolute text-lg font-bold rounded bg-white shadow left-2 top-14"
                        key={index}>
                        <SwitcherButton
                            onToggle={item.onClick} // Function to handle the toggle event
                            status={false} // Boolean value indicating the initial status of the switch (on/off)
                            labelOn={item.label} // Label text to display when the switch is on
                            className="" // Additional CSS class for styling the component
                            labelOff={item.label} // Label text to display when the switch is off
                            revertLabel={false} // Boolean value indicating whether to reverse the label position
                            duration={150} // Duration of the transition animation in milliseconds
                            hintMessage={item.hintLabel} // Hint message to display when hovering over the component
                            hintTitle={item.label} // Title of the hint message
                            dataFor="hint" // Optional data attribute for targeting the hint message
                        />
                    </div>
                );
            })}
            {resetMap && (
                <GoogleMapReact
                    ref={mapRef}
                    bootstrapURLKeys={{
                        key: 'AIzaSyBfLH4V6D19dTDTW31weSPHhKN-LxDQcR4',
                        libraries: [
                            'places',
                            'geometry',
                            'drawing',
                            'visualization',
                        ],
                    }}
                    center={mapOptions.center}
                    zoom={mapOptions.zoom}
                    options={MAP.options}
                    onChange={handleMapChange}
                    layerTypes={mapLayers}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) =>
                        handleApiLoaded(map, maps)
                    }>
                    <StreetView
                        active={showStreetView}
                        toggler={toggleStreetView}
                        streetViewPanoramaOptions={streetViewPanoramaOptions}
                        lat={streetViewPanoramaOptions.position.lat}
                        lng={streetViewPanoramaOptions.position.lng}
                    />
                    {clusters.map((cluster) => {
                        if (cluster.numPoints === 1) {
                            const screen = cluster.points[0];
                            return (
                                <ScreenMarker
                                    key={screen.id}
                                    screen={screen}
                                    lat={parseFloat(screen.lat)}
                                    lng={parseFloat(screen.lng)}
                                    setScreenOpened={setScreenOpened}
                                    screenOpened={screenOpened}
                                    onOpenStreetView={onShowStreetView}
                                    selectedScreensLocal={selectedScreensLocal}
                                />
                            );
                        }

                        return (
                            <ClusterMarker
                                key={cluster.id}
                                lat={cluster.lat}
                                lng={cluster.lng}
                                points={cluster.points}
                                onClick={handleClickClusterMarker}
                            />
                        );
                    })}
                </GoogleMapReact>
            )}
        </>
    );
}

export default MapCanvas;
