import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { useService } from '../UseService';
import CampaignProposalCard from '../../components/Cards/CampaignProposalCard';
import { CAMPAIGN_PROPOSALS_STATUSES } from '../../constants/constants';
import Loading from '../../components/Loading/Loading';

function CampaignProposalsList(props) {
    const { t } = useTranslation();
    const { fetchCampaignProposalsByStatus } = useService();
    const history = useHistory();

    const campaigns = useSelector((state) => state.campaigns);
    const isLoading = campaigns.isLoading;
    history.params;
    const params = useParams();
    const { status } = params;
    const [campaignProposals, setCampaignProposals] = useState([]);
    const deleteProposal = async (id) => {
        const deleted = await service.deleteCampaignProposal(id);
        if (deleted?.error === false) {
            const updatedCampaignProposals = campaignProposals.filter(
                (proposal) => proposal.id !== id
            );
            setCampaignProposals(updatedCampaignProposals);
        }
    };
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const campaignProposalsFetched =
                    await fetchCampaignProposalsByStatus(status);

                setCampaignProposals(campaignProposalsFetched?.campaign || []);
            } catch (error) {}
            setLoading(false);
        })();
        return () => {
            setCampaignProposals([]);
        };
    }, [status]);

    const changeStatus = (status) => {
        if (!isLoading) {
            history.push(`/campaign-proposals/status/${status}`);
        }
    };

    const service = useService();

    return (
        <>
            <Helmet>
                <title>
                    {t(`common.campaign-proposals.statuses.${status}`)}{' '}
                </title>
            </Helmet>
            <div className="fixed h-screen w-full bg-gray-100 overflow-y-auto pb-10">
                <Header
                    currentSection={t('common.menu.dashboard')}
                    sectionPath={'/dashboard'}
                    subSection={t('common.words.campaigns')}
                    subSectionOne={t(
                        `common.campaign-proposals.statuses.${status}`
                    )}
                />
                <div className="workingArea flex justify-between w-full mt-10 md:mt-16">
                    <div className="font-extralight text-2xl p-2 leading-5">
                        {t(`common.campaign-proposals.statuses.${status}`)}{' '}
                        <br />
                    </div>
                    <div className="text-right mb-2">
                        <button
                            className="mt-4 capitalize bg-blue-400 hover:bg-blue-600 text-white rounded-sm transition-colors duration-300 ease-in-out font-light text-sm p-1 py-2 px-2 lg:px-4 focus:outline-none"
                            onClick={service.createCampaignProposalName}>
                            {t('common.menu.newProposal')}
                        </button>
                    </div>
                </div>
                <div className="workingArea gap-2 flex flex-col md:flex-row md:justify-between">
                    <div className="module w-full md:w-3/12">
                        <ul className="place-content-evenly space-y-2 font-light">
                            {CAMPAIGN_PROPOSALS_STATUSES.map(
                                (status, index) => (
                                    <li
                                        key={index}
                                        onClick={() => changeStatus(status)}
                                        className={`text-blue-500 cursor-pointer flex justify-between`}>
                                        <span>
                                            {t(
                                                `common.campaign-proposals.statuses.${status}`
                                            )}
                                        </span>{' '}
                                        {/* <span className="text-right text-gray-400">
                                        {status.amount}
                                    </span> */}
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                    <div
                        className="block w-full"
                        style={{
                            justifyItems: 'center',
                        }}>
                        <div className="flex flex-wrap flex-end content-center bg-white">
                            {loading ? (
                                <Loading isLoading={loading}></Loading>
                            ) : campaignProposals?.length ? (
                                campaignProposals.map((campaign) => (
                                    <CampaignProposalCard
                                        key={campaign.id}
                                        campaign={campaign}
                                        isCardExpanded={true}
                                        deleteProposal={deleteProposal}
                                        service={service}
                                    />
                                ))
                            ) : (
                                <div className="w-full flex justify-center">
                                    <span className="font-extralight p-2 leading-5">
                                        {t(
                                            'common.campaign-proposals.no-campaign-proposals'
                                        )}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CampaignProposalsList;
