import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

function Breadcrumbs(params) {
    const currentSection = params.currentSection;
    const sectionPath = params.sectionPath;
    const subSection = params.subSection;
    const subSectionOne = params.subSectionOne; // this one should change
    const campaignId = params.campaignId;
    const extraIcon = params.extraIcon;

    return (
        <nav className="flex justify-start ml-14 md:ml-20 text-xs uppercase truncate">
            <ol className="list-none p-0 inline-flex">
                <li className="flex items-center">
                    <NavLink
                        className="link"
                        to={
                            sectionPath === '/campaign' ||
                            sectionPath === '/campaigns' ||
                            sectionPath === '/proposal'
                                ? '/dashboard'
                                : sectionPath.toLowerCase()
                        }>
                        {/* we don't have an endpoint called campaign so if section path is campaing we rename it to dashboard */}
                        {currentSection.toLowerCase() === 'dashboard' ||
                        currentSection.toLowerCase() ===
                            'painel de controle' ? (
                            <FontAwesomeIcon icon={['fad', 'home']} />
                        ) : (
                            currentSection
                        )}
                    </NavLink>
                    {subSection ? (
                        <FontAwesomeIcon
                            icon={['fas', 'angle-right']}
                            fixedWidth
                            className="mx-1 md:mx-2"
                        />
                    ) : (
                        <></>
                    )}
                </li>
                {subSection && (
                    <li className="flex items-center truncate">
                        <NavLink
                            className="link"
                            to={
                                subSection?.toLowerCase() === 'campaña' ||
                                subSection?.toLowerCase() === 'campaign'
                                    ? '/campaigns/status/job-pending'
                                    : '#'
                            }>
                            {subSection}
                        </NavLink>
                        <FontAwesomeIcon
                            icon={['fas', 'angle-right']}
                            fixedWidth
                            className="mx-1 md:mx-2"
                        />
                    </li>
                )}
                {subSectionOne ? (
                    <li className="flex items-center truncate">
                        <ReactTooltip id={`tts` + campaignId}>
                            <span className="font-bold">#{campaignId}</span>
                            <br />
                            <span className="font-light capitalize">
                                {subSectionOne}
                            </span>
                        </ReactTooltip>
                        <span
                            className={`${
                                campaignId ? 'inline' : 'hidden'
                            } font-mono pt-1 mr-2 bg-gray-200 text-black rounded px-2`}>
                            #{campaignId}
                        </span>
                        <span data-tip data-for={`tts` + campaignId}>
                            {subSectionOne}
                        </span>
                    </li>
                ) : (
                    ''
                )}
            </ol>
        </nav>
    );
}

export default Breadcrumbs;
