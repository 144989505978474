import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

function LoginLayout(props) {
    const { t } = useTranslation();
    const { children } = props;

    // en public/{WL}/images
    const Background = `/${process.env.REACT_APP_WL_NAME}/images/cover-home.jpg`;

    const whiteLebelSloganStyles = () => {
        switch (process.env.REACT_APP_WL_NAME) {
            case 'bebot':
                return 'text-yellow-600';
            default:
                return '';
        }
    };

    const whiteLebelCompanyStyles = () => {
        switch (process.env.REACT_APP_WL_NAME) {
            case 'bebot':
                return 'text-yellow-500';
            default:
                return '';
        }
    };

    return (
        <>
            <div className="w-full h-screen flex justify-between">
                <div
                    className="relative hidden md:block w-4/6 h-full text-white p-4 bg-cover bg-center bg-opacity-25"
                    style={{ backgroundImage: 'url(' + Background + ')' }}>
                    <div className="logo overflow-hidden">
                        <img
                            src={
                                require(`../../images/${process.env.REACT_APP_WL_NAME}/logo-white.svg`)
                                    .default
                            }
                            alt="logo"
                            className="h-14 object-left-top"
                        />
                    </div>
                    <div className="logo-product"></div>
                    <div
                        className={`absolute z-10 bottom-0 right-0 text-right p-4 text-gray-500 ${whiteLebelSloganStyles()}`}>
                        <span
                            className={`text-lg font-bold subpixel-antialiased ${whiteLebelCompanyStyles()}`}
                            style={{
                                textShadow: '0 0 10px rgba(255,255,255,0.3)',
                            }}>
                            {process.env.REACT_APP_WL_COMPANY_NAME}
                        </span>
                        <br />
                        {process.env.REACT_APP_WL_COMPANY_SLOGAN}.{' '}
                        {/* Should be translated?*/}
                    </div>
                </div>
                <div className="relative w-full md:w-2/6 h-full text-black p-4 flex flex-col">
                    <div className="logo block md:hidden">
                        <img
                            src={
                                require(`../../images/${process.env.REACT_APP_WL_NAME}/iso.svg`)
                                    .default
                            }
                            alt="logo"
                        />
                    </div>
                    <div className="mt-5 p-0 md:p-8 h-full font-light">
                        {children}
                    </div>
                    <div className="absolute bottom-0 text-center text-xs pb-4 text-gray-600">
                        {t('sections.login-page.want-to-register', {
                            company: process.env.REACT_APP_WL_COMPANY_NAME,
                        })}{' '}
                        <a
                            href={`mailto:${process.env.REACT_APP_WL_COMPANY_EMAIL}?subject=Wanna be Advertiser`}
                            className="link">
                            {t('sections.login-page.contact-us')}
                        </a>
                        .
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoginLayout;
