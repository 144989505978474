import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Block from './Block';
import Item from './Item';
import { useService } from '../../../../containers/UseService';
import { useAuth } from '../../../../containers/UseAuth';
import CommonButton from '../../../Buttons/CommonButton';
import { newCampaignActions } from '../../../../store';

const Audiences = ({
    isLoading,
    setIsLoading,
    setHasFetched,
    setHasForced,
}) => {
    const dispatch = useDispatch();
    const auth = useAuth();
    const { t } = useTranslation();
    const newCampaign = useSelector((state) => state.newCampaign);
    const metrics = newCampaign.forecast;
    const service = useService();

    useEffect(() => {
        return function () {
            setHasFetched(false);
            setHasForced(false);
        };
    }, []);

    //FIXME usar una funcion/estado global para validar usuario
    useEffect(() => {
        if (newCampaign.canLaunch) {
            setIsLoading(true);
            service.calculateForecast(newCampaign).then((schedules) => {
                setHasFetched(true);
                setIsLoading(false);
            });
        }
    }, [newCampaign.canLaunch]);

    const [seeImpressions, setSeeImpressions] = useState(false);
    useEffect(() => {
        let adminOrSupervising = auth.isAdmin() || auth.isSupervising();
        setSeeImpressions(adminOrSupervising);
    }, []);

    return (
        <div>
            <Block
                icon={
                    <FontAwesomeIcon
                        icon={['fal', 'users-class']}
                        fixedWidth
                        size="lg"
                        className="m-1 mr-2 text-blue-300"
                    />
                }
                title={t(
                    'sections.campaigns.new-campaign.wizard-tab.audiences'
                )}>
                <Item
                    isLoading={isLoading === true}
                    title={t(
                        'sections.campaigns.new-campaign.wizard-audiences.forecast.targeted-universe'
                    )}>
                    {metrics.targetUniverse}
                </Item>

                <Item
                    isLoading={isLoading === true}
                    title={t(
                        'sections.campaigns.new-campaign.wizard-audiences.forecast.targeted-impacts'
                    )}>
                    {metrics.totalImpacts}
                </Item>

                <Item
                    isLoading={isLoading === true}
                    title={t(
                        'sections.campaigns.new-campaign.wizard-audiences.forecast.coverage'
                    )}>
                    <span>
                        {metrics.coverage}
                        <span className="text-xs text-gray-500 block">
                            {metrics.coverageAbs}
                        </span>
                    </span>
                </Item>

                <Item
                    isLoading={isLoading === true}
                    title={t(
                        'sections.campaigns.new-campaign.wizard-audiences.forecast.GRP'
                    )}>
                    {metrics.grp}
                </Item>

                <Item
                    isLoading={isLoading === true}
                    title={t(
                        'sections.campaigns.new-campaign.wizard-audiences.forecast.frequency'
                    )}>
                    {metrics.frequency}
                </Item>
                <Item isLoading={isLoading === true} title={'CPM'}>
                    {metrics.cpm}
                </Item>

                {seeImpressions && (
                    <Item
                        isLoading={isLoading === true}
                        title={t(
                            'sections.campaigns.new-campaign.wizard-audiences.forecast.ad-impressions'
                        )}>
                        {metrics.adImpressions}
                    </Item>
                )}
                {seeImpressions && (
                    <Item
                        isLoading={isLoading === true}
                        title={t(
                            'sections.campaigns.new-campaign.wizard-audiences.forecast.cost-per-impact'
                        )}>
                        {metrics.cpi}
                    </Item>
                )}
            </Block>
        </div>
    );
};

export default Audiences;
