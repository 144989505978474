import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'rc-slider/assets/index.css';
import Header from '../../components/Header/Header';
import SystemNotification from '../../components/Notifications/SystemNotifications';
import { useService } from '../UseService';
import { useSelector, useDispatch } from 'react-redux';
import ReportsTable from '../../components/Dashboard/ReportsTable/ReportsTable';
import TimeLine from '../../components/Dashboard/TimeLine/TimeLine';
import DashboardStats from '../../components/Dashboard/DashboardStats/DashboardStats';
import Loading from '../../components/Loading/Loading';
import CampaingsList from '../../components/CampaignList/CampaignList';
import DropdownPeriods from '../../components/Select/DropdownPeriods';
import { useLocalStorage } from '../../hooks/storage/useLocalStorage';
import { dateToInt } from '../../utils/functions';
import { dashboardActions } from '../../store';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../UseAuth';

function DashboardPage(params) {
    const { t } = useTranslation();
    const service = useService();
    const currencies = useSelector((state) => state.wallet.currencies);
    const [isLoading, setIsLoading] = useState(false);
    const [isSilentLoading, setIsSilentLoading] = useState(false);
    const [currentPeriod, setCurrentPeriod] = useLocalStorage('currentPeriod', [
        null,
        null,
    ]);
    const dashboard = useSelector((state) => state.dashboard);
    const campaigns = useSelector((state) => state.campaigns?.statuses?.ACTIVE);
    const reportsTableGeneralData =
        dashboard.reportsTableGeneralData.reportsForTable;
    //TODO: currency selected in currency selector change stats to show
    const currentCurrencySelected =
        dashboard.reportsTableGeneralData.currentCurrency;
    const selectedCurrency = dashboard.selectedCurrency;
    const fadeOutAnimation = `transition-opacity delay-300 ${
        isLoading ? 'opacity-0' : 'opacity-1'
    }`;
    const [value, setValue] = useLocalStorage('dateName', 'today');
    const [rowTotals, setRowTotals] = useState([]);
    const dispatch = useDispatch();
    const auth = useAuth();

    const [rawTotals, setRawTotals] = useState({});

    const getStats = async () => {
        let rawData = {};
        let totals = await service.getStatsByUser(
            currentPeriod[0],
            currentPeriod[1],
            rawData
        );
        setRowTotals(totals);

        setRawTotals(rawData?.data?.totals || {});

        setIsLoading(false);
    };
    const getCampaigns = async () => {
        await service.getCampaignsPerStatus();
        setIsLoading(false);
        setIsSilentLoading(false);
    };

    const hasImpacts =
        rawTotals[currentCurrencySelected.short_name]?.impacts > 0;

    useEffect(() => {
        if (currentPeriod[1] && currencies.length > 0 && !isLoading) {
            setIsLoading(true);
            getStats();
        }
    }, [currentPeriod, currencies]);

    //FIXME validar usuario de forma global
    const [seeImpressions, setSeeImpressions] = useState(false);
    useEffect(() => {
        let adminOrSupervising = auth.isAdmin() || auth.isSupervising();
        setSeeImpressions(adminOrSupervising);
    }, []);

    // Update campaign on background every 2 minutes
    useEffect(() => {
        const cron = setInterval(async () => {
            // Only update if now is between current period
            if (
                dateToInt(currentPeriod[0]) <= dateToInt(Date.now()) &&
                dateToInt(currentPeriod[1]) >= dateToInt(Date.now())
            ) {
                if (!isSilentLoading) {
                    dispatch(dashboardActions.setIsSilentLoading(true));
                    await getStats();
                    await getCampaigns();
                    dispatch(dashboardActions.setIsSilentLoading(false));
                }
            }
        }, 120_000);
        return () => {
            clearInterval(cron);
        };
    }, [currentPeriod]);

    return (
        <>
            <Helmet>
                <title>{`${t('sections.dashboard.page-title')} - ${
                    process.env.REACT_APP_WL_COMPANY_NAME
                }`}</title>
            </Helmet>
            <SystemNotification
                notificationType={'success'}
                title={t('sections.dashboard.welcome')}
                text={t('sections.dashboard.welcome-message')}
            />
            <div className="fixed h-screen w-full bg-gray-100 overflow-y-auto pb-10">
                <div className="flex justify-between items-center">
                    <Header
                        currentSection={t('common.menu.dashboard')}
                        sectionPath={'/dashboard'}
                    />
                    <DropdownPeriods
                        isDisabled={isLoading}
                        currentPeriod={currentPeriod}
                        setCurrentPeriod={setCurrentPeriod}
                        value={value}
                        setValue={setValue}
                    />
                </div>

                <Loading isLoading={isLoading} />
                <div className={`workingArea ${fadeOutAnimation} mt-4 md:mt-0`}>
                    <div className={`grid grid-cols-2 `}>
                        <DashboardStats
                            totals={
                                rawTotals[currentCurrencySelected.short_name]
                            }
                            seeImpressions={seeImpressions}
                        />
                    </div>
                </div>

                <div
                    className={`${
                        dashboard.isSilentLoading || dashboard.isLoading
                            ? 'opacity-50 workingArea flex flex-col md:flex-row mt-2'
                            : `workingArea flex flex-col md:flex-row mt-2 ${fadeOutAnimation}`
                    }`}>
                    <TimeLine currentPeriod={currentPeriod} />
                </div>

                <div
                    className={`workingArea mt-2 p-2 pr-0 ${fadeOutAnimation}`}>
                    <ReportsTable
                        withGraph
                        data={reportsTableGeneralData}
                        currency={currentCurrencySelected}
                        rowTotals={rowTotals}
                        seeImpressions={seeImpressions}
                    />
                </div>

                {isLoading || !campaigns || (
                    <CampaingsList
                        status={'ACTIVE'}
                        containerClassName={
                            'workingArea mt-2 overflow-x-hidden'
                        }
                        dashboard={dashboard}
                    />
                )}
            </div>
        </>
    );
}

export default DashboardPage;
