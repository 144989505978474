import { useEffect, useState, useTransition } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useService } from '../../containers/UseService.js';
import { formatNumber } from '../../utils/functions.js';
import { Helmet } from 'react-helmet-async';
import Header from '../Header/Header.js';
import Loading from '../Loading/Loading.js';
import DownloadScreensToCSV from '../Campaign/WizardReview/Blocks/DownloadScreensToCSV.js';
import EmailInput from './EmailInput.js';

function LabelValue({ label, value }) {
    return (
        <div>
            <p className="text-gray-500">{label}</p>
            <p className="text-xl font-semibold">{value}</p>
        </div>
    );
}

function ProposalBrief() {
    const { t } = useTranslation();
    const service = useService();
    const [brief, setBrief] = useState(null);
    const [loading, setLoading] = useState(false);

    const params = useParams();
    const { id } = params;
    useEffect(() => {
        (async () => {
            setLoading(true);
            const brief = await service.getProposalBrief(id);
            if (!brief.error) {
                setBrief(brief.data);
            }
            setLoading(false);
        })();
        return () => setBrief(null);
    }, []);

    const csvColumnsHeaders = [
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-screens.screens.table-screenName'
            ),
            key: 'name',
        },
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-screens.screens.table-screenEcpm'
            ),
            key: 'median',
        },
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-screens.screens.table-screenProvider'
            ),
            key: 'provider',
        },
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-screens.screens.table-screenType'
            ),
            key: 'screenType',
        },
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-screens.screens.weekly_impacts'
            ),
            key: 'weeklyImpacts',
        },
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-screens.screens.table-screenSize'
            ),
            key: 'size',
        },
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-screens.screens.table-type'
            ),
            key: 'type',
        },
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-screens.screens.table-screenGoogleMapLink'
            ),
            key: 'googleMapLink',
        },
    ];

    return (
        <div>
            <Helmet>
                <title>Proposals | brief of proposal</title>
            </Helmet>
            <div className="relative pl-16 pb-10 md:pt-0 mt-12 h-auto flex justify-center bg-white border-r w-full border-gray-300 shadow-lg">
                <Header
                    currentSection={t('common.menu.dashboard')}
                    sectionPath={'/proposal'}
                    subSection={'Proposal'}
                    subSectionOne={'brief'}
                />
                {loading ? (
                    <Loading isLoading={loading}></Loading>
                ) : (
                    brief && (
                        <div className="bg-gray-100 min-h-screen flex justify-center items-center">
                            <div className="bg-white shadow-lg rounded-lg p-8 w-full">
                                <div className="mb-6">
                                    <h2 className="text-xl font-semibold mt-4 text-gray-600">
                                        {t(
                                            'common.campaign-proposals.proposal'
                                        )}
                                    </h2>
                                    <p className="text-gray-500">
                                        {`${brief.campaign_brand} ${brief.campaign_name}`}
                                    </p>
                                </div>

                                {/* Proposal Summary */}
                                <div className="grid grid-cols-2 gap-6 bg-gray-50 p-6 rounded-lg mb-6">
                                    <LabelValue
                                        label={t(
                                            'common.campaign-proposals.slide.brief-budget'
                                        )}
                                        value={
                                            formatNumber(
                                                brief.total_budget,
                                                'currency'
                                            ) +
                                            ' ' +
                                            brief.currency_short_name
                                        }
                                    />
                                    <LabelValue
                                        label={t(
                                            'common.campaign-proposals.slide.brief-duration'
                                        )}
                                        value={`${brief.duration_in_days} ${t(
                                            'common.words.days'
                                        )}`}
                                    />
                                    <LabelValue
                                        label={t(
                                            'common.campaign-proposals.slide.brief-start'
                                        )}
                                        value={brief.start_period}
                                    />
                                    <LabelValue
                                        label={t(
                                            'common.campaign-proposals.slide.brief-end'
                                        )}
                                        value={brief.end_period}
                                    />
                                    <LabelValue
                                        label={t(
                                            'common.campaign-proposals.slide.brief-ecpm'
                                        )}
                                        value={
                                            formatNumber(brief.ecpm, 'ecpm') +
                                            ' ' +
                                            brief.currency_short_name
                                        }
                                    />
                                    <LabelValue
                                        label={t(
                                            'common.campaign-proposals.slide.brief-universe'
                                        )}
                                        value={formatNumber(
                                            brief.target_universe,
                                            'currency'
                                        )}
                                    />
                                    <LabelValue
                                        label={t(
                                            'common.campaign-proposals.slide.brief-impacts'
                                        )}
                                        value={formatNumber(
                                            brief.total_impacts,
                                            'impacts'
                                        )}
                                    />
                                    <LabelValue
                                        label={t(
                                            'common.campaign-proposals.slide.brief-frequency'
                                        )}
                                        value={brief.frequency.toFixed(2)}
                                    />
                                </div>

                                {/* Planning Section */}
                                <div className="mb-6">
                                    <h3 className="text-lg font-semibold text-gray-600 mb-4">
                                        {t(
                                            'common.campaign-proposals.planning.title'
                                        )}
                                    </h3>
                                    <table className="w-full text-left bg-gray-50 rounded-lg">
                                        <thead className="bg-gray-100">
                                            <tr>
                                                <th className="p-3 text-gray-600">
                                                    {t(
                                                        'common.campaign-proposals.planning.sub-campaign'
                                                    )}
                                                </th>
                                                <th className="p-3 text-gray-600">
                                                    {t(
                                                        'common.campaign-proposals.planning.budget-percentage'
                                                    )}
                                                </th>
                                                <th className="p-3 text-gray-600">
                                                    {t(
                                                        'common.campaign-proposals.planning.impacts'
                                                    )}
                                                </th>
                                                <th className="p-3 text-gray-600">
                                                    {t(
                                                        'common.campaign-proposals.planning.ecpm'
                                                    )}
                                                </th>
                                                <th className="p-3 text-gray-600">
                                                    {t(
                                                        'common.campaign-proposals.planning.total-investment'
                                                    )}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(brief.labels).map(
                                                (label) => (
                                                    <tr key={label}>
                                                        <td className="p-3">
                                                            {label}
                                                        </td>
                                                        <td className="p-3">
                                                            {brief.labels[label]
                                                                .budget_porcentage +
                                                                '%'}
                                                        </td>
                                                        <td className="p-3">
                                                            {
                                                                brief.labels[
                                                                    label
                                                                ].total_impacts
                                                            }
                                                        </td>
                                                        <td className="p-3">{`${formatNumber(
                                                            brief.labels[label]
                                                                .ecpm,
                                                            'currency'
                                                        )} ${
                                                            brief.currency_short_name
                                                        }`}</td>
                                                        <td className="p-3">
                                                            {`${formatNumber(
                                                                brief.labels[
                                                                    label
                                                                ].budget,
                                                                'currency'
                                                            )} ${
                                                                brief.currency_short_name
                                                            }`}
                                                        </td>
                                                    </tr>
                                                )
                                            )}

                                            <tr className="font-semibold">
                                                <td className="p-3">
                                                    {'Totals'}
                                                </td>
                                                <td className="p-3">
                                                    {100 + '%'}
                                                </td>
                                                <td className="p-3">
                                                    {brief.total_impacts}
                                                </td>
                                                <td className="p-3">{`${formatNumber(
                                                    brief.ecpm,
                                                    'currency'
                                                )} ${
                                                    brief.currency_short_name
                                                }`}</td>
                                                <td className="p-3">
                                                    {`${formatNumber(
                                                        brief.total_budget,
                                                        'currency'
                                                    )} ${
                                                        brief.currency_short_name
                                                    }`}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div
                                    style={{
                                        width: '100%',
                                        height: '500px',
                                        overflow: 'hidden',
                                    }}>
                                    {brief?.proposal?.slide_link ? (
                                        <iframe
                                            src={brief.proposal.slide_link}
                                            frameBorder="0"
                                            width="100%"
                                            height="100%"
                                            allowFullScreen
                                            title="Google Slide"></iframe>
                                    ) : (
                                        <div className="w-full flex justify-center h-full items-center">
                                            {' '}
                                            <p>
                                                {t(
                                                    'common.campaign-proposals.slide.not-available'
                                                )}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="my-10 w-full flex flex-col gap-10">
                                    <DownloadScreensToCSV
                                        customScreens={
                                            brief?.screens?.length
                                                ? brief.screens.map(
                                                      (screen) => {
                                                          return {
                                                              ...screen,
                                                              median: screen.median,
                                                              googleMapLink: `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${screen.geo.lat},${screen.geo.lon}`,
                                                          };
                                                      }
                                                  )
                                                : null
                                        }
                                        customHeaders={csvColumnsHeaders}
                                    />
                                    <EmailInput id={id} service={service} />
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
}

export default ProposalBrief;
