import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsActions } from '../../store';

const notificationVariants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '200%' },
};

function SystemNotification() {
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notifications.list);
    const [isNotificationOpen, setIsNotificationOpen] = useState(true);

    // BORRAR: simulo aparicion de notificacion a los 8 segundos, el comportamiento debería borrarse al rato.
    useEffect(() => {
        setIsNotificationOpen(true);
        if (notifications.length > 0) {
            setTimeout(() => {
                notifications.map((notification) => {
                    dispatch(
                        notificationsActions.removeNotification(notification.id)
                    );
                });
            }, 8000);
        }
        return () => {
            setIsNotificationOpen(false);
        };
    });

    const removeNotification = (id) => {
        dispatch(notificationsActions.removeNotification(id));
    };

    const getStyle = (type) => {
        switch (type) {
            case 'success':
                return {
                    borderLeft: 'border-green-300',
                    icon: 'badge-check',
                    titleColor: 'text-green-500',
                };
            case 'warning':
                return {
                    borderLeft: 'border-yellow-300',
                    icon: 'exclamation-triangle',
                    titleColor: 'text-yellow-500',
                };
            case 'error':
                return {
                    borderLeft: 'border-red-300',
                    icon: 'exclamation-triangle',
                    titleColor: 'text-red-500',
                };
            default:
                return {
                    borderLeft: 'border-gray-300 bg-gray-200',
                    icon: 'bells',
                    titleColor: 'text-gray-700',
                };
        }
    };

    return (
        <div className="fixed z-50 max-w-md min-w-max top-0 right-0 mr-2 mt-2 px-4">
            {notifications.map((notification, index) => (
                <motion.div
                    className={`${
                        getStyle(notification.type).borderLeft
                    } relative my-4 border-l-4 bg-white bg-opacity-75 backdrop shadow-lg rounded-lg overflow-hidden`}
                    animate={isNotificationOpen ? 'open' : 'closed'}
                    variants={notificationVariants}
                    key={index}
                    data-test="notification">
                    <div
                        className="absolute top-0 right-0 mr-2"
                        onClick={() => removeNotification(notification.id)}>
                        <FontAwesomeIcon
                            icon={['fal', `times`]}
                            className="text-gray-400"
                        />
                    </div>
                    <div className="flex items-center px-1 py-3 max-w-xs max-h-48 overflow-hidden">
                        <FontAwesomeIcon
                            icon={[
                                'fad',
                                `${getStyle(notification.type).icon}`,
                            ]}
                            className={`${
                                getStyle(notification.type).titleColor
                            } opacity-50 text-2xl`}
                        />
                        <div className="mx-3">
                            <h2
                                className={`font-semibold ${
                                    getStyle(notification.type).titleColor
                                }`}>
                                {notification.title}
                            </h2>
                            <p className="text-xs text-gray-600">
                                {notification.message}
                            </p>
                        </div>
                    </div>
                </motion.div>
            ))}
        </div>
    );
}

export default SystemNotification;
