import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from '../utils/axios/axios';
import axiosOriginal from 'axios';
import * as endpoints from '../constants/endpoints';
import statsService from '../services/statsService';
import { useDispatch, useSelector } from 'react-redux';
import {
    campaignsActions,
    commercialsPersonsActions,
    dashboardActions,
    extraActions,
    newCampaignActions,
    notificationsActions,
    screensActions,
    walletActions,
} from '../store';
import {
    getResolutionByString,
    formatNumber,
    numberWithCommas,
    numberWithoutCommas,
    lastTwoDecimal,
    formatDaysFromNumbers,
    arrayToList,
    fillWithDays,
    fillWithHours,
    isEmpty,
    capitalizeFirstLetter,
    setOtherZone,
    guessTimezoneOfBrowser,
    stringToTypeUser,
    stringToRoleUser,
    campaignStatus,
    formatDate,
    translateDaysEnabled,
} from '../utils/functions';
import { useTranslation } from 'react-i18next';
import scopesiMetadata from '../constants/Scopesi.json';
import retargetlyMetadata from '../constants/Retargetly.json';
import telefonicaMetadata from '../constants/Telefonica.json';
import cinnectaMetadata from '../constants/Cinnecta.json';
import onemataMetadata from '../constants/Onemata.json';
import { useAuth } from '../containers/UseAuth';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import JSZip from 'jszip';
import LZString from 'lz-string';

import { injectCpmOnScreen, thereIsNewVersion } from './UseServiceFunctions';

import { db } from '../db/db';
import useDispatchNotification from '../hooks/notifications/useDispatchNotification';
import { newCampaignInitialState } from '../store/newCampaign';

const serviceContext = createContext();
// .

export default function ProvideService({ children }) {
    const service = useProvideService();
    return (
        <serviceContext.Provider value={service}>
            {children}
        </serviceContext.Provider>
    );
}

export const useService = () => {
    return useContext(serviceContext);
};

let debounceCampaignPreview;

function useProvideService() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dashboard1 = useSelector((state) => state.dashboard);
    const newCampaign = useSelector((state) => state.newCampaign);
    const screens = useSelector((state) => state.screens.screens);
    const wallet = useSelector((state) => state.wallet);
    const campaignList = useSelector((state) => state.campaigns);
    const extra = useSelector((state) => state.extra);
    const currencies = wallet.currencies;
    const budgets = wallet.budgets;
    const history = useHistory();
    const auth = useAuth();
    const session = auth.session;
    const [margins, setMargins] = useState(-1);
    const creatives = newCampaign.creatives;

    const { successNotification, warningNotification } =
        useDispatchNotification();

    useEffect(() => {
        if (session) {
            getCurrencies();
            getCampaignsPerStatus();
            getBudgets();
            getTimezones();
            getUpdates();
            getScreens();
            getCreativeCategories();
        }
    }, [session]);

    // State for campaign currently being edited / created
    const [activeCampaignData, setActiveCampaignData] = useState('');

    useEffect(() => {
        saveCampaignInLocalStorage(newCampaign.id);
    }, [creatives]);

    useEffect(() => {
        if (newCampaign.id !== null && !newCampaign.wasLaunched)
            saveOrUpdateCampaignPreview();
    }, [newCampaign]);

    const getData = (response) => {
        return 'data' in response && 'data' in response.data
            ? response.data.data
            : [];
    };

    const getUpdates = async () => {
        let url = endpoints.getUpdates;
        let version = getData(await axios.get(url));
        if (thereIsNewVersion(version.ver, localStorage.getItem('version'))) {
        }
        localStorage.setItem('version', version.ver);
    };

    const getBudgets = async () => {
        let url = endpoints.getBudgetsV2;

        try {
            if (budgets === null) {
                let budgets = getData(await axios.get(url));
                dispatch(walletActions.setBudgets(budgets.budget));
                return budgets;
            } else {
                return budgets;
            }
        } catch (error) {
            ErrorMessage(error);
            return { '-': 0 };
        }
    };

    const getBudgetsDetails = async () => {
        let url = endpoints.getBudgetsDetailsV2;

        try {
            const budgetsDetails = getData(await axios.get(url));
            const currencies = await getCurrencies();
            budgetsDetails.campaigns = budgetsDetails.campaigns.map(
                (campaign) => {
                    campaign.currency = currencies.find(
                        (currency) => currency.id === campaign.currency_id
                    );
                    return campaign;
                }
            );
            return budgetsDetails;
        } catch (error) {
            ErrorMessage(error);
        }
    };

    const getUserMovements = async () => {
        let url = endpoints.getUserMovements;
        try {
            const userMovements = getData(await axios.get(url));
            return userMovements;
        } catch (error) {
            ErrorMessage(error);
        }
    };

    const getCampaignPreview = async (id) => {
        let url = endpoints.getCampaignPreviewV2 + `/${id}`;

        try {
            dispatch(dashboardActions.setIsLoading(true));
            let response = await axios.get(url);

            let campaign = getData(response);
            campaign.currency = getCurrencyById(campaign.currency_id);
            dispatch(dashboardActions.setCampaign(campaign));
            dispatch(dashboardActions.setIsLoading(false));
            return campaign;
        } catch (error) {
            ErrorMessage(error);
            history.push('/dashboard');
            dispatch(dashboardActions.setIsLoading(false));
        }
    };

    async function setDraftCampaignAutodelete(user, days) {
        let url = endpoints.userSettingsV2;

        try {
            let response = await axios.post(url + user, {
                campaign_autodelete: days,
            });
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    async function getUserSettings(user) {
        let url = endpoints.userSettingsV2;
        try {
            let response = await axios.get(url + user);
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    const getTimezones = async () => {
        let url = endpoints.getTimezonesV2;

        try {
            if (newCampaign.timing.timezones.length > 0) {
                //console.log('timezones already loaded')
                return newCampaign.timing.timezones;
            }

            if (!localStorage.getItem('timezones')) {
                let timezones = getData(await axios.get(url));
                timezones = timezones.map((timezone) => ({
                    ...timezone,
                    value: timezone.id,
                    label: timezone.description,
                }));

                localStorage.setItem('timezones', JSON.stringify(timezones));

                db.timezones.clear();
                db.timezones.bulkPut(timezones);

                dispatch(extraActions.setTimezones(timezones));
                return timezones;
            } else {
                let timezones = JSON.parse(localStorage.getItem('timezones'));
                dispatch(extraActions.setTimezones(timezones));
                return timezones;
            }
        } catch (error) {
            ErrorMessage(error);
        }
    };
    const getStatsByScreens = async (campaignId, from, to) => {
        try {
            from = new Date(from);
            from = formatDate(from);
            to = new Date(to);
            to = formatDate(to);
            let url = `${endpoints.getStats}/screens/campaign/${campaignId}?from=${from}&to=${to}`;
            const response = await axios.get(url);

            return response;
        } catch (e) {
            console.error(e);
        }
    };

    const getCampaignStats = async (campaign, currentPeriod) => {
        let promiseStatsByScreens = statsService.getStatsByScreens(
            campaign.id,
            currentPeriod[0],
            currentPeriod[1]
        );
        let promiseStatsByCampaign = statsService.getStatsByCampaign(
            campaign.id,
            currentPeriod[0],
            currentPeriod[1]
        );
        let promiseStatsByHours = statsService.getStatsByCampaignByHours(
            campaign.id,
            currentPeriod[0],
            currentPeriod[1]
        );

        let [statsByScreens, statsByCampaign, statsByHours] =
            await Promise.allSettled([
                promiseStatsByScreens,
                promiseStatsByCampaign,
                promiseStatsByHours,
            ]);

        let key = Object.keys(statsByHours?.value?.data?.stats)[0];
        if (!key) {
            key = [];
        }
        const symbol = campaign?.currency.short_name;

        const totals = [];

        let formattedScreens = formatTotals(
            statsByScreens.value?.data.totals[key],
            symbol
        );
        let formattedCampaign = formatTotals(
            statsByCampaign.value?.data.totals[key],
            symbol
        );
        let formattedHours = formatTotals(
            statsByHours.value?.data.totals[key],
            symbol
        );

        totals.push(formattedScreens, formattedCampaign, formattedHours);

        let total = statsByCampaign.value.data?.totals[key];

        dispatch(dashboardActions.setIsLoading(false));

        let byDays = key ? statsByCampaign.value.data.stats[key] : [];

        let byHours = key ? statsByHours.value.data.stats[key] : [];

        let byScreen = key ? statsByScreens.value.data.stats[key] : [];

        let bySpent = total ? total.spent : 0;

        let byImpressions = total ? total.impressions : 0;

        let byImpacts = total ? total.impacts : 0;

        let bySpot = total ? total.spot : 0;

        let byEcpm = total ? total.ecpm : 0;

        dispatch(
            dashboardActions.setIndividualCampaignStats({
                days: byDays,
                hours: byHours,
                screen: byScreen,
                spent: bySpent,
                impressions: byImpressions,
                impacts: byImpacts,
                spot: bySpot,
                ecpmAverage: byEcpm,
            })
        );
        const newStats = {
            day: statsByCampaign.value?.data.stats,
            hour: statsByHours.value?.data.stats,
            screen: statsByScreens.value?.data.stats,
        };

        return { newStats, totals };
    };

    const forgotPassword = async (email) => {
        try {
            let url = endpoints.forgotPasswordV2;
            await axios.post(url, {
                email: email,
                redirectUrl: process.env.REACT_APP_FRONT_END_URL + '/reset',
            });
        } catch (error) {
            ErrorMessage(error);
        }
    };

    const resetPassword = async (token, reEnterPassword, username) => {
        try {
            let url = endpoints.resetPasswordV2;
            await axios.post(url, {
                token: token,
                password: reEnterPassword,
                username: username,
            });

            dispatch(
                notificationsActions.setNotification({
                    type: 'success',
                    title: t(
                        'common.notifications.success.passChangeSuccesfull-title'
                    ),
                    message: t(
                        'common.notifications.success.passChangeSuccesfull-description'
                    ),
                })
            );
        } catch (error) {
            ErrorMessage(error);
        }
    };

    const getCampaignPrediction = async (campaign) => {
        const from = moment(campaign.start_period).format('YYYY-MM-DD HH:mm');
        const to = moment(campaign.end_period).format('YYYY-MM-DD HH:mm');
        let url = `${endpoints.getCampaignStatusPrediction}${campaign.id}`;

        try {
            let prediction = (await axios.get(url)).data;
            if (JSON.stringify(prediction) === '[]')
                dispatch(dashboardActions.setCampaignPrediction({}));
            else dispatch(dashboardActions.setCampaignPrediction(prediction));
            return prediction;
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    const getCampaigns = async (
        status,
        page,
        limit = process.env.REACT_APP_CAMPAIGNS_PER_PAGE,
        search
    ) => {
        try {
            if (status !== '') {
                let url = `${endpoints.getCampaigns}?limit=${limit}&userId=${session.user.id}&status=${status}&page=${page}`;
                if (search) url = `${url}&search=${search}`;

                const response = await axios.get(url);
                const data = response.data;
                const campaigns = data.data.campaigns.data;
                formatCampaigns(campaigns);
                return campaigns;
            } else {
                return [];
            }
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    const onClickNextPageOfCampaignsList = (pageNumber) => {
        const nextPage =
            pageNumber > 0
                ? pageNumber
                : campaignList.campaignsShowing.page + 1;
        fetchMoreDataOfCurrentCampaignList(nextPage);
    };

    const fetchMoreDataOfCurrentCampaignList = (page, limit) => {
        const fetchData = async () => {
            dispatch(campaignsActions.setIsLoading(true));
            const newCampaigns = await getCampaigns(
                campaignList.campaignsShowing.status,
                page,
                limit
            );
            addCampaigns(newCampaigns, page);
            dispatch(campaignsActions.setIsLoading(false));
        };
        fetchData();
    };

    const addCampaigns = (campaigns, page) => {
        const newCurrent = Object.assign({}, campaignList.campaignsShowing);
        const currentCampaigns = campaignList.campaignsShowing.campaigns;
        //FIXME: this is a hack to fix the issue of the first page not showing the first campaign
        // but currentCampaigns is wrong
        const campaignsWithoutRepeat = currentCampaigns.filter((campaign) => {
            return !campaigns.find(
                (newCampaign) => newCampaign.id === campaign.id
            );
        });
        newCurrent.campaigns = [...campaignsWithoutRepeat, ...campaigns];
        newCurrent.page = page;
        dispatch(campaignsActions.changeCampaigns(newCurrent));
    };

    const getCampaignsPerStatus = async () => {
        let url = endpoints.getCampaignsPerStatusV2;
        let statuses = {
            DRAFT: '-',
            JOB_SUCCESS: '-',
            DELETED: '-',
            INACTIVE: '-',
        };

        try {
            const statusesInBack = getData(await axios.get(url));
            statuses = statusesInBack;
        } catch (error) {
            ErrorMessage(error);
        }
        dispatch(campaignsActions.setStatuses(statuses));
        localStorage.setItem('statuses', JSON.stringify(statuses));
        return statuses;
    };

    const getCurrencies = async () => {
        try {
            // NOTE: This needs to be refactored to avoid to mix localStorage
            // and state.
            let newCurrencies = localStorage.getItem('currencies');
            if (!newCurrencies) {
                newCurrencies = getData(
                    await axios.get(endpoints.getCurrenciesV2)
                );
                localStorage.setItem(
                    'currencies',
                    LZString.compress(JSON.stringify(newCurrencies))
                );
                // Clean un currency records
                db.currencies.clear();
                db.currencies.bulkPut(newCurrencies, 'id');
            } else {
                newCurrencies = JSON.parse(LZString.decompress(newCurrencies));
            }

            dispatch(walletActions.setCurrencies(newCurrencies));
            return newCurrencies;
        } catch (error) {
            ErrorMessage(error);
        }
    };

    const getNodes = async (isForFilter = false) => {
        try {
            // tiene que tener paises en extra.location para poder buscar
            if (extra?.location?.countries && isForFilter)
                return extra.location;
            // tiene que tener paises y no por filter
            if (newCampaign.location.nodes.length > 0 && !isForFilter) {
                return newCampaign.location.nodes;
            }
            const nodes = getData(await axios.get(endpoints.getGeoNodesV2));
            const countries = getData(
                await axios.get(endpoints.getGeoCountriesV2)
            );
            const screensForFilter = await getScreens();
            const nodesCountries = nodes.reduce(
                (acc, curr) => [...acc, curr.name],
                []
            );
            countries.forEach((country) => {
                const indexOfCountry = nodesCountries.indexOf(country.code2);
                if (indexOfCountry !== -1) {
                    nodes[indexOfCountry] = {
                        ...nodes[indexOfCountry],
                        label: country.name,
                    };
                }
            });

            const optionCountries = [];
            const optionNodes = [];
            nodes.forEach((node) => {
                let isCountry = false;
                screensForFilter.forEach((screen) => {
                    if (screen?.geo?.country == node?.name) {
                        isCountry = true;
                    }
                });
                if (isCountry) {
                    optionCountries.push({
                        value: node.name,
                        label: node.label,
                    });
                    optionNodes.push(node);
                }
            });
            const newLocation = Object.assign({}, newCampaign.location);
            newLocation.nodes = optionNodes;
            newLocation.countries = optionCountries;
            dispatch(extraActions.setLocation(newLocation));
            dispatch(newCampaignActions.setLocation(newLocation));
            if (isForFilter) return newLocation;
            else return newLocation.nodes;
        } catch (error) {
            ErrorMessage(error);
        }
    };

    const setSelectedCountries = (newCountries) => {
        const moreCountries =
            newCountries.length > newCampaign.location.countries.length;

        const countriesNames = newCountries.map((c) => c.value);
        const includesOnCountriesByCurrency =
            newCampaign.location.selectedCountries.every((sc) => {
                return countriesNames.includes(sc.value);
            });

        const countriesForSelect =
            newCampaign.location.selectedCountries.length &&
            includesOnCountriesByCurrency &&
            moreCountries === false
                ? newCampaign.location.selectedCountries
                : newCountries;
        dispatch(newCampaignActions.setSelectedCountries(countriesForSelect));
    };

    // Depending on the chosen currency, it is filtered which
    // country can be selected using the screens
    useEffect(() => {
        if (!newCampaign?.selectedCurrency) return;
        let optionsByCurrency = { nodes: [], countries: [] };

        const getDataNodes = async () => {
            let datanodes = await getNodes(true);
            return datanodes;
        };

        const checkPrice = async (shortCurrency) => {
            let data = await getDataNodes();
            const screens = await getScreens();
            let countriesByProviders = {
                scopesi: [],
                onemata: [],
                cinnecta: [],
            };
            let forCpmMode = auth?.session?.user?.cpm_mode === 1;
            if (forCpmMode) {
                let providers = await axios.get(
                    endpoints.getCountriesByProviders
                );
                countriesByProviders = {
                    scopesi: providers?.data?.scopesi?.countries,
                    onemata: providers?.data?.onemata?.countries,
                    cinnecta: providers?.data?.cinnecta?.countries,
                };
            }
            let countriesShort = {};
            screens?.forEach((screen) => {
                try {
                    if ('prices' in screen.deals) {
                        if (
                            shortCurrency === screen.deals?.prices[0]?.currency
                        ) {
                            if (forCpmMode) {
                                if (
                                    countriesByProviders.onemata.some(
                                        (country) =>
                                            country == screen.geo.country
                                    ) ||
                                    countriesByProviders.scopesi.some(
                                        (country) =>
                                            country == screen.geo.country
                                    ) ||
                                    countriesByProviders.cinnecta.some(
                                        (country) =>
                                            country == screen.geo.country
                                    )
                                ) {
                                    countriesShort[screen.geo.country] = true;
                                }
                            } else {
                                countriesShort[screen.geo.country] = true;
                            }
                        }
                    }
                } catch (err) {
                    console.log(err.message);
                }
            });
            // NOTE: BeBot compatibility
            if (shortCurrency === 'BRL') {
                countriesShort = { BR: true };
            }

            if (Object.keys(countriesShort).length > 0) {
                Object.keys(countriesShort).forEach((country) => {
                    let infoCoutry = data?.countries.find(
                        (element) => element.value == country
                    );
                    let infoNode = data?.nodes.find(
                        (element) => element.name == country
                    );
                    if (infoCoutry) {
                        optionsByCurrency.nodes.push(infoNode);
                        optionsByCurrency.countries.push(infoCoutry);
                    }
                });
                let isInfoCountries =
                    Object.values(optionsByCurrency).length > 0;
                if (isInfoCountries) {
                    setSelectedCountries(optionsByCurrency.countries);
                    dispatch(
                        newCampaignActions.setNodeAndCountries(
                            optionsByCurrency
                        )
                    );
                }
            } else {
                dispatch(
                    notificationsActions.setNotification({
                        type: 'warning',
                        title: t(
                            'sections.management.section.billing.budget.tab'
                        ),
                        message: t(
                            'sections.management.section.billing.budget.notifications.currencyWihoutScreen'
                        ),
                    })
                );
                setSelectedCountries(optionsByCurrency.countries);
                dispatch(
                    newCampaignActions.setNodeAndCountries(optionsByCurrency)
                );
            }
        };

        if (newCampaign.selectedCurrency.short_name)
            checkPrice(newCampaign.selectedCurrency.short_name);
    }, [newCampaign.selectedCurrency]);

    // FIXME: This function is horrible and should be refactored
    const getScreens = async () => {
        try {
            const screensStore = LZString.decompress(
                localStorage.getItem('screens')
            );
            const isSavedOnLocalStorage = JSON.parse(screensStore.length > 0);
            const user = JSON.parse(localStorage.getItem('session'));
            let screenCacheTimestamp =
                localStorage.getItem('screenCacheTimeout') || 0;
            let hour = 1000 * 60 * 60;

            let localCampaignCurrency;
            if (newCampaign?.selectedCurrency) {
                localCampaignCurrency = newCampaign.selectedCurrency;
            } else {
                let localCampaign = localStorage.getItem('newCampaignData');
                if (localCampaign) {
                    localCampaign = JSON.parse(
                        LZString.decompress(localCampaign)
                    );
                    localCampaignCurrency = localCampaign.selectedCurrency;
                }
            }

            if (
                screensStore &&
                Date.now() < Number(screenCacheTimestamp) + hour
            ) {
                const screens = JSON.parse(screensStore);
                // If there are screens in localStorage and the CPM is
                // calculated, return the screens
                if (
                    screens.length > 0 &&
                    screens[0].cpm >= 0 &&
                    screens[0].cpm !== null
                ) {
                    return screens;
                }
            }

            // Clean up database for new data
            db.screens.clear();

            if (!isSavedOnLocalStorage) {
                let screensResponse = await axiosOriginal.get(
                    endpoints.screensWithSpotCost + `?userId=${user.user.id}`
                );
                let screens = screensResponse.data;

                // Support for old localStorage
                screens = screens.map((screen) => ({
                    ...screen,
                    size: `${screen.width}x${screen.height}`,
                    lat: screen.geo.lat,
                    lng: screen.geo.lon,
                    isSelected: false,
                    label: screen.name,
                }));

                localStorage.setItem(
                    'screens',
                    LZString.compress(JSON.stringify(screens))
                );
                localStorage.setItem('screenCacheTimeout', Date.now());
                return screens;
            } else {
                const screens = JSON.parse(screensStore);
                return screens;
            }
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    const getScreensReports = async (
        provider = 'onemata',
        userId,
        timezone,
        currency,
        screenId
    ) => {
        const screens = await axiosOriginal.get(
            `${endpoints.screensReportsData}?provider=${provider}&timezone=${timezone}&userid=${userId}&currency=${currency}&screenId=${screenId}`
        );
        return screens;
    };

    const getScreensReportsWithCpm = async (
        provider = 'onemata',
        userId,
        localCurrency
    ) => {
        const medianCpms = await axiosOriginal.get(
            `${endpoints.screensReportsWithCpm}?provider=${provider}&userId=${userId}&localCurrency=${localCurrency}`
        );
        return medianCpms;
    };

    /**
     *
     * @deprecated

     */
    const updateCPMOfScreens = async (screensParams) => {
        if (screensParams === undefined) {
            screensParams = screens;
        }

        const margins = await getMargins();

        const cpmNotAvaible = 0;
        const getCpmOfScreen = (screen) => {
            try {
                if ('prices' in screen.deals) {
                    if ('price' in screen.deals?.prices[0]) {
                        const cpm =
                            margins !== 0
                                ? screen.deals.prices[0].price / (1 - margins)
                                : screen.deals.prices[0].price;
                        return {
                            cpm: cpm,
                            cpmValue: cpm,
                        };
                    } else {
                        return cpmNotAvaible;
                    }
                } else {
                    return cpmNotAvaible;
                }
            } catch (err) {
                return cpmNotAvaible;
            }
        };

        const screensWithCpm = screensParams.map((screen) => ({
            ...screen,
            // ...getCpmOfScreen(screen),
        }));

        dispatch(screensActions.setScreens(screensWithCpm));
        return screensWithCpm;
    };

    //Example input = [{"id" : 1}, {"id" : 2}, {"id" : 3}], "id" output = 1,2,3
    const concatItemsByComa = (items, attribute) => {
        let concatenated = '';
        items.forEach((item) => {
            concatenated = concatenated.concat(',', item[attribute]);
        });

        return concatenated.substring(1);
    };

    const getScreenProviders = async () => {
        try {
            const screenProviders = getData(
                await axios.get(endpoints.getProviders)
            );
            return screenProviders.data;
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    const getScreenProvidersFiltered = async (countries, regions, cities) => {
        try {
            let url = endpoints.getProviders;
            if (countries.length > 0) {
                url = `${url}?countries=${concatItemsByComa(countries, 'id')}`;
            }

            if (regions.length > 0) {
                url = `${url}&regions=${concatItemsByComa(regions, 'id')}`;
            }

            if (cities.length > 0) {
                url = `${url}&cities=${concatItemsByComa(cities, 'id')}`;
            }
            const screenProviders = getData(await axios.get(url));
            return screenProviders.data;
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    const saveCampaignInLocalStorage = (id) => {
        const campaignId = id ? id : newCampaign.id;
        if (campaignId) {
            const campaign = JSON.parse(JSON.stringify(newCampaign)); // do this instead of object.assign
            campaign.id = campaignId;
            campaign.forecast.schedules = [];
            localStorage.setItem(
                'newCampaignData',
                LZString.compress(JSON.stringify(campaign))
            );
            localStorage.setItem(
                'currencies',
                LZString.compress(JSON.stringify(currencies))
            );

            // HACK: fix to replicate the behavior of the old localStorage
            db.newCampaignData.clear().then(() => {
                db.newCampaignData.put(campaign, 'id');
            });
        }
    };

    const clearNewCampaignData = () => {
        localStorage.removeItem('newCampaignData');
        // Only clear the localStorage if passed more than 1 hour
        if (Date.now() - newCampaign.timestamp > 3600000) {
            localStorage.removeItem('screens');
        }
        dispatch(newCampaignActions.reset());
        dispatch(screensActions.resetByKey('selectedScreensLocal'));
        dispatch(screensActions.resetByKey('isFilterByAudiences'));
        dispatch(screensActions.resetByKey('showSelectedScreens'));
        dispatch(screensActions.resetByKey('selectedScreenTypes'));
        dispatch(screensActions.resetByKey('selectedPublishers'));
        dispatch(screensActions.resetByKey('selectedTiers'));
        dispatch(screensActions.resetByKey('maxCpm'));
    };

    const getCampaignInLocalStorage = () => {
        return JSON.parse(
            LZString.decompress(localStorage.getItem('newCampaignData'))
        );
    };

    const hasDraftCampaignInLocalStorage = () => {
        return localStorage.getItem('newCampaignData') !== null;
    };

    const formatValuesSavePreview = (campaign) => {
        const currencyDefault = JSON.parse(
            localStorage.getItem('currencyDefault')
        );
        // si la url tiene la palabra new se setea la currencyDefault
        // if (window.location.href.includes("new")) {
        //     campaign.selectedCurrency = currencyDefault;
        // }

        const request = {};
        const user = session.user;
        request['id'] = campaign.id;
        request['agency'] =
            user && user.company ? user.company : 'Undefined Company';
        request['user_id'] = user.id;
        /* W1 Basics */
        request['name'] = campaign.name;
        request['brand'] = campaign.brand;
        request['budget'] = campaign.budget.budget;
        request['currency_id'] = campaign.selectedCurrency
            ? campaign.selectedCurrency.id
            : currencyDefault.id;
        request['cpm_optimize'] = campaign.budget.cpmOptimizer ? 1 : 0;
        request['timezone_id'] = campaign.timing.selectedTimezone.id;
        request['max_cpm'] = campaign.budget.cpm;
        // TODO:  finish_when_budget is the same to limit_type (delete from front and back)
        request['finish_when_budget'] = campaign.dailyCap.isBudget ? 1 : 0;
        request['limit_type'] = campaign.dailyCap.isBudget ? 1 : 0;
        request['days_enabled'] = campaign.timing.daysEnabled.filter(
            (item) => item > 0 || item == ''
        );
        //TODO: when backend is updated, this will be deleted
        request['hours_enabled'] = campaign.timing.hoursEnabled.filter(
            (item) => item < 24 || item == ''
        ); //TODO: when backend is updated, this will be deleted
        if (
            request['days_enabled'].length === 0 &&
            campaign.timing.isDayParting === false
        ) {
            request['days_enabled'] = fillWithDays();
        }
        if (
            request['hours_enabled'].length === 0 &&
            campaign.timing.isDayParting === false
        )
            request['hours_enabled'] = fillWithHours();
        request['asap'] = campaign.timing.isAsap ? 1 : 0;
        if (campaign.timing.periodValue) {
            request['start_period'] = campaign.timing.periodValue[0];
            request['end_period'] =
                request['start_period'] === campaign.timing.periodValue[1]
                    ? campaign.timing.periodValue[1] + 1
                    : campaign.timing.periodValue[1];
        } else {
            request['start_period'] = '';
            request['end_period'] = '';
        }
        //TODO: We have a matrix, needs update backend to send matrix of hours and days
        if (campaign.dailyCap.isActive) {
            if (campaign.dailyCap.isDistribution) {
                request['auto_distribution'] = 1;
                request['daily_limit'] = 0;
            } else {
                request['auto_distribution'] = 0;
                request['daily_limit'] = campaign.dailyCap.isBudget
                    ? campaign.dailyCap.budget
                    : campaign.dailyCap.impressions;
            }
        } else {
            request['auto_distribution'] = 0;
            request['daily_limit'] = 0;
        }

        /* W2 Location */
        request['countries_selected'] = campaign.location.selectedCountries.map(
            (country) => country.value
        );
        request['regions_selected'] = campaign.location.selectedRegions.map(
            (region) => region.value
        );
        request['cities_selected'] = campaign.location.selectedCities.map(
            (city) => city.value
        );

        if (campaign.location.selectedNeighborhoods.length > 0) {
            request['neighborhoods_selected'] =
                campaign.location.selectedNeighborhoods.map(
                    (neighborhood) => neighborhood.value
                );
        } else {
            request['neighborhoods_selected'] = [];
        }

        request['zones'] = campaign.location.zones;

        /* W3 Screens */
        if (campaign.selectedScreens.length > 0)
            request['screens'] = campaign.selectedScreens.map(
                (screen) => screen.id
            );

        /* W4 Audiences */
        if (campaign.segments.age.value.length === 0) {
            request['scopesi_ages'] = [];
        } else {
            if (campaign.segments.age.value.length) {
                request['scopesi_ages'] = campaign.segments.age.value;
            } else {
                request['scopesi_ages'] = [];
            }
        }
        request['scopesi_gender'] = campaign.segments.gender;
        request['scopesi_nse'] = campaign.segments.incomeLevel;
        request['scopesi_coverage'] = campaign.segments.accuracy.sliderValue;

        /* W5 Creatives */
        request['ads_basename'] = campaign.baseName;
        request['pixel_tracker_script'] = campaign.tracking.isActive
            ? campaign.tracking.externalTracking
            : '';
        request['creative_categories'] =
            campaign.selectedCreativeCategories.map((scc) => scc.label);
        request['provider_audience'] = campaign.demographics.id;
        request['is_test'] = campaign.isTest;

        request['target_impacts_launch'] = isNaN(
            campaign.forecast.targetImpacts
        )
            ? 0
            : campaign.forecast.targetImpacts;
        return request;
    };

    const saveOrUpdateCampaignPreview = async () => {
        const data = formatValuesSavePreview(newCampaign);

        if (JSON.stringify(data) === activeCampaignData) {
            return;
        }
        setActiveCampaignData(JSON.stringify(data));

        clearTimeout(debounceCampaignPreview);
        debounceCampaignPreview = setTimeout(async () => {
            try {
                const response = getData(
                    await axios.post(endpoints.saveCampaignPreviewV2, data)
                );
                if (response.campaign) {
                    const id = response.campaign.id;
                    const statusCampaign = campaignStatus(
                        response.campaign.status
                    );
                    if (id != newCampaign.id)
                        dispatch(newCampaignActions.setId(id));
                    dispatch(newCampaignActions.setStatus(statusCampaign));
                    saveCampaignInLocalStorage(id);
                    updateCampaignInListOfCampaigns(response.campaign);
                    if (statusCampaign != 'DRAFT') updateBudgets();
                }
            } catch (error) {
                ErrorMessage(error);
            }
        }, 300);
    };

    const updateCampaignInListOfCampaigns = (campaign) => {
        campaign.screen = campaign.screens ? campaign.screens : [];
        campaign.countries = campaign.countries_selected
            ? campaign.countries_selected
            : [];
        campaign.regions = campaign.regions_selected
            ? campaign.regions_selected
            : [];
        campaign.cities = campaign.cities_selected
            ? campaign.cities_selected
            : [];
        campaign.neighborhood = campaign.neighborhoods_selected
            ? campaign.neighborhoods_selected
            : [];
        campaign.pixel_tracker_script = campaign.pixel_tracker_script
            ? campaign.pixel_tracker_script
            : '';
        formatCampaign(campaign);
        let updated = false;
        const newCampaignList = JSON.parse(
            JSON.stringify(campaignList.campaigns)
        );
        newCampaignList.forEach((data) => {
            if (!updated) {
                data.campaigns.forEach((c) => {
                    if (!updated) {
                        if (c.id === campaign.id) {
                            Object.keys(c).forEach((key) => {
                                c[key] = campaign[key];
                            });
                            c.timezone_id = campaign.timezone_id
                                ? campaign.timezone_id
                                : null;
                            updated = true;
                        }
                    }
                });
            }
        });

        if (!updated) {
            newCampaignList.forEach((data) => {
                if (data.status === 'DRAFT') {
                    data.campaigns = [campaign, ...data.campaigns];
                }
            });

            if (!updated) {
                newCampaignList.push({
                    status: 'DRAFT',
                    page: 1,
                    campaigns: [campaign],
                });
            }

            const newStatuses = Object.assign({}, campaignList.statuses);
            newStatuses['DRAFT'] = newStatuses['DRAFT']
                ? newStatuses['DRAFT'] + 1
                : 1;
            dispatch(campaignsActions.setStatuses(newStatuses));
        }

        dispatch(campaignsActions.setCampaigns(newCampaignList));
    };

    // Continue the status of last campaign incomplete
    const continueNewCampaign = async () => {
        const campaign = localStorage.getItem('newCampaignData')
            ? JSON.parse(
                  LZString.decompress(localStorage.getItem('newCampaignData'))
              )
            : JSON.parse(localStorage.getItem('newCampaignData'));
        const screens = localStorage.getItem('screens')
            ? JSON.parse(LZString.decompress(localStorage.getItem('screens')))
            : JSON.parse(localStorage.getItem('screens'));
        const currenciesStorage = localStorage.getItem('currencies')
            ? JSON.parse(
                  LZString.decompress(localStorage.getItem('currencies'))
              )
            : JSON.parse(localStorage.getItem('currencies'));
        if (campaign) {
            // !!the campaign is configured from editCampaign and saved to local storage for use here
            dispatch(newCampaignActions.setMany(campaign));
            if (campaign.selectedScreens.length) {
                dispatch(
                    screensActions.setSelectedScreensLocal(
                        campaign.selectedScreens
                    )
                );
            }
            if (currenciesStorage)
                dispatch(walletActions.setCurrencies(currenciesStorage));
            else if (currencies.length > 0)
                dispatch(walletActions.setCurrencies(currencies));
        }
        if (screens) {
            dispatch(screensActions.setScreens(screens));
        }
    };

    const launchCampaign = async () => {
        try {
            let data = await axios.put(
                endpoints.finishCampaignV2 + newCampaign.id
            );
            if (!data?.data?.error) {
                fetchMoreDataOfCurrentCampaignList(1);
                await updateBudgets();
                dispatch(
                    notificationsActions.setNotification({
                        type: 'success',
                        title: t(
                            'sections.campaigns.new-campaign.wizard-launch.launch.campaign-launched-title'
                        ),
                        message: `${t(
                            'sections.campaigns.new-campaign.wizard-launch.launch.campaign-launched-message'
                        )}`,
                    })
                );
                dispatch(newCampaignActions.setWasLaunched(true));
                history.push('/campaigns/status/active');
                clearNewCampaignData();
                return true;
            } else {
                dispatch(
                    notificationsActions.setNotification({
                        type: 'warning',
                        title: t(
                            'sections.campaigns.new-campaign.wizard-launch.launch.campaign-not-launched-message'
                        ),
                        message: `${t(
                            'sections.campaigns.new-campaign.wizard-launch.launch.campaign-not-launched-message'
                        )}
                            , ${data.data.message}`,
                    })
                );
                return true;
            }
        } catch (e) {
            ErrorMessage(e);
            return false;
        }
    };

    const updateBudgets = async () => {
        try {
            const budgetss = getData(await axios.get(endpoints.getBudgetsV2));
            const budgetStore = wallet.budgets;
            Object.entries(budgetStore).forEach((currency) => {
                if (budgetStore[currency[0]] != currency[1])
                    dispatch(walletActions.setBudgets(budgetss.budget));
            });
        } catch (error) {
            ErrorMessage(error);
        }
    };

    const getMargins = async () => {
        try {
            if (margins === -1) {
                const userId = { user_id: session.user.id };
                const { margins } = getData(
                    await axios.post(endpoints.getMargins, userId)
                );
                const marginsInFloat = parseFloat(margins);
                setMargins(marginsInFloat);
                return marginsInFloat;
            } else {
                return margins;
            }
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    const getCreativeType = (extension) => {
        if (extension === '')
            return { isImage: false, isVideo: false, isScript: true };
        const imageExtensions = ['gif', 'jpg', 'png', 'jpeg'];
        const videoExtensions = ['mp4', 'webm'];
        const isImage = imageExtensions.some(
            (imageExtension) => imageExtension === extension
        );
        const isVideo = videoExtensions.some(
            (videoExtension) => videoExtension === extension
        );

        return { isImage, isVideo, isScript: !isImage && !isVideo };
    };

    const getResolution = (file, extension) => {
        const url = URL.createObjectURL(file);
        const { isImage, isVideo } = getCreativeType(extension);

        if (isImage) {
            return new Promise((resolve) => {
                const image = new Image();
                image.onload = function () {
                    resolve(this.width + 'x' + this.height);
                };
                image.src = url;
            });
        } else if (isVideo) {
            return new Promise((resolve) => {
                const videoElem = document.createElement('video');
                videoElem.src = url;
                videoElem.addEventListener('loadedmetadata', function () {
                    resolve(this.videoWidth + 'x' + this.videoHeight);
                });
            });
        } else {
            return 'no resolution';
        }
    };

    /**
     * Validate one zip file, one zip file is valid if contains one index.html inside. The zip can contains another files like
     * html, htm, css, webm, mp4, js, gif, jpg, png, svg, scss, less, php.
     * @param {File} zip
     * @return {Object} with 2 keys
     * - isValid: true if is valid
     * - error: in case that isValid=false, message contains the error message
     */
    const validateZip = async (zipFile) => {
        const content = await JSZip.loadAsync(zipFile);

        if (content.files['index.html']) {
            return { isValid: true, error: '' };
        } else {
            return {
                isValid: false,
                error: t(
                    'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.errors.invalid-zip'
                ),
            };
        }
    };

    const validateFiles = async (files) => {
        const validExtensions = [
            'gif',
            'jpeg',
            'jpg',
            'png',
            'mp4',
            'webm',
            'zip',
        ];
        const isVideo = (extension) => ['mp4', 'webm'].includes(extension);
        const hasResolutionInFileName = (fileName) => {
            const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
            const resolution = getResolutionByString(fileNameWithoutExtension);
            if (resolution[0] !== 0 && resolution[1] !== 0) {
                const width = parseInt(resolution[0]);
                const height = parseInt(resolution[1]);
                return !isNaN(width) && !isNaN(height)
                    ? [width, height]
                    : false;
            } else {
                return false;
            }
        };

        let validFiles = [];
        const invalidFiles = [];

        await Promise.all(
            files.map(async (file) => {
                const extension = file.name.split('.').pop();
                const validExtension = validExtensions.some(
                    (e) => e === extension
                );
                if (validExtension) {
                    let resolution = await getResolution(file, extension);
                    if (
                        isVideo(extension) &&
                        hasResolutionInFileName(file.name)
                    ) {
                        resolution = hasResolutionInFileName(file.name);
                        resolution = `${resolution[0]}x${resolution[1]}`;
                    }

                    if (resolution !== 'no resolution') {
                        let isValidResolution = isVideo(extension)
                            ? true
                            : newCampaign.selectedScreens.some(
                                  (screen) => screen.size === resolution
                              );

                        if (isValidResolution) {
                            validFiles.push(file);
                        } else {
                            const error = t(
                                'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.errors.invalid-resolution'
                            );
                            invalidFiles.push({ file, error });
                        }
                    } else {
                        // zip file
                        const { isValid, error } = await validateZip(file);
                        if (isValid) validFiles.push(file);
                        else invalidFiles.push({ file, error });
                    }
                } else {
                    const error = t(
                        'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.errors.invalid-extension'
                    );
                    invalidFiles.push({ file, error });
                }
            })
        );

        return { validFiles, invalidFiles };
    };

    const uploadTester = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(endpoints.uploadTester, formData);
            const data = response.data;
            return data;
        } catch (e) {
            return { error: true, message: e };
        }
    };

    const uploadFiles = async (files) => {
        const { validFiles, invalidFiles } = await validateFiles(files);
        const formData = new FormData();
        const showError = () => {
            dispatch(
                notificationsActions.setNotification({
                    type: 'error',
                    title: t(
                        'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.errors.error-uploading-files'
                    ),
                    message: t(
                        'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.errors.error-uploading-files-description'
                    ),
                })
            );
        };

        if (validFiles.length === 0) {
            showError();
            return invalidFiles;
        }

        validFiles.forEach((file, index) => {
            formData.append('files[' + index + ']', file);
        });

        formData.append('campaign_preview_id', newCampaign.id);
        formData.append('basename', newCampaign.baseName);
        try {
            const response = getData(
                await axios.post(endpoints.uploadFiles, formData)
            );
            if (response?.length === 0 || response.fail?.length > 0) {
                dispatch(
                    notificationsActions.setNotification({
                        type: 'warning',
                        title: t('common.notifications.error.title'),
                        message: t(
                            'common.notifications.error.errorExtensionaData'
                        ),
                    })
                );
                return invalidFiles;
            }
            const uploadedCreatives = response.success.map((creative) => ({
                ...creative.ad,
                extension: creative.extension,
            }));
            dispatch(
                newCampaignActions.setCreatives([
                    ...creatives,
                    ...uploadedCreatives,
                ])
            );
            if (invalidFiles.length > 0) {
                showError();
            }
            return invalidFiles;
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
            return invalidFiles;
        }
    };

    const changeResolution = async (creative, resolution) => {
        const [width, height] = getResolutionByString(resolution);
        try {
            const request = { width, height };
            const response = getData(
                await axios.put(endpoints.assignSize + creative.id, request)
            );
            const creativeEdited = response.ad;
            if ('extension' in creative) {
                creativeEdited.extension = creative.extension;
            }
            const newCreatives = [];
            creatives.forEach((creative) => {
                if (creative.id !== creativeEdited.id)
                    newCreatives.push(creative);
                else newCreatives.push(creativeEdited);
            });
            dispatch(newCampaignActions.setCreatives(newCreatives));
            return response.ad;
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    const uploadScript = async (width, height, script) => {
        try {
            const request = {
                script,
                width,
                height,
                basename: newCampaign.baseName,
                campaign_preview_id: newCampaign.id,
            };

            let response = await axios.post(endpoints.uploadFiles, request);

            if (!response.data.error) {
                response = getData(response);
                dispatch(
                    newCampaignActions.setCreatives([...creatives, response.ad])
                );
                return response.ad;
            } else {
                dispatch(
                    notificationsActions.setNotification({
                        type: 'warning',
                        title: t(
                            'sections.campaigns.new-campaign.wizard-creatives.creatives.title'
                        ),
                        message: t(
                            'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.errors.error-uploading-files-description'
                        ),
                    })
                );
            }
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    const vastInDisplay = async (width, height, script) => {
        try {
            const request = {
                script,
                vastInDisplay: script,
                width,
                height,
                basename: newCampaign.baseName,
                campaign_preview_id: newCampaign.id,
            };
            let response = await axios.post(endpoints.uploadFiles, request);

            if (!response.data.error) {
                response = getData(response);
                const responseCopy = Object.assign({}, response.ad);
                const newResponse = {
                    campaign_preview_id: responseCopy.campaign_preview_id,
                    created_at: responseCopy.created_at,
                    height: responseCopy.height,
                    id: responseCopy.id,
                    image: responseCopy.image,
                    is_secure: responseCopy.is_secure,
                    name: responseCopy.name,
                    original_filename: responseCopy.original_filename,
                    thumbnail_url: responseCopy.thumbnail_url,
                    title: responseCopy.title,
                    type: responseCopy.type,
                    updated_at: responseCopy.updated_at,
                    url: responseCopy.url,
                    user_id: responseCopy.user_id,
                    width: responseCopy.width,
                    extension: 'vast',
                };
                dispatch(
                    newCampaignActions.setCreatives([...creatives, newResponse])
                );
                return newResponse;
            } else {
                dispatch(
                    notificationsActions.setNotification({
                        type: 'warning',
                        title: t(
                            'sections.campaigns.new-campaign.wizard-creatives.creatives.title'
                        ),
                        message: t(
                            'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.errors.error-uploading-files-description'
                        ),
                    })
                );
            }
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    const duplicateCreative = async (creative) => {
        try {
            const response = getData(
                await axios.post(endpoints.duplicateAd, { id: creative.id })
            );
            const duplicated = response.ad;
            if ('extension' in creative)
                duplicated.extension = creative.extension;
            dispatch(
                newCampaignActions.setCreatives([...creatives, duplicated])
            );
            return duplicated;
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    const removeCreative = async (creative) => {
        try {
            await axios.delete(endpoints.removeAd + creative.id);
            const newCreatives = creatives.filter((c) => c.id !== creative.id);
            dispatch(newCampaignActions.setCreatives(newCreatives));
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    /**
     * Lets you delete multiple creatives in on query
     * @param Array creativesarr is an Array of creatives
     */
    const removeMultipleCreatives = async (creativesarr) => {
        try {
            let newCreatives = creatives;
            let promisesToDelete = [];
            for (let i = 0; i < creativesarr.length; i++) {
                promisesToDelete.push(
                    axios.delete(endpoints.removeAd + creativesarr[i].id)
                );
            }
            const responses = await Promise.allSettled(promisesToDelete);
            let withoutError = [];
            let withError = [];
            responses.forEach((response) => {
                const idDeleted = response;
                const idResponse =
                    idDeleted.value.request.responseURL.split('/ad/')[1];
                if (!response.value.data.error) {
                    withoutError.push({
                        ...response.value.data,
                        id: idResponse,
                    });
                    newCreatives = newCreatives.filter(
                        (c) => c.id != idResponse
                    );
                } else {
                    withError.push({ ...response.value.data, id: idResponse });
                    console.error({ ...response.value.data, id: idResponse });
                }
            });
            dispatch(newCampaignActions.setCreatives(newCreatives));
            return { withError, withoutError };
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    //add translations to retargetly interests
    const getRetargetlyMetadata = () => {
        const interests = [];
        retargetlyMetadata.data.segments.forEach((segment) => {
            if (segment.id === 'rtg_interests') {
                segment.keys.forEach((key) => {
                    const interest = {
                        text: t(
                            'sections.campaigns.new-campaign.wizard-audiences.providers.retargetly.' +
                                key.id
                        ),
                        id: key.id,
                    };
                    interests.push(interest);
                });
            }
        });
        const newRetargetlyData = JSON.parse(
            JSON.stringify(retargetlyMetadata)
        );
        newRetargetlyData.data.segments.push({
            keys: interests,
            name: 'interests',
            id: 'interests',
        });

        return newRetargetlyData;
    };

    const getProviderMetadata = (provider) => {
        let metadata;
        switch (provider) {
            case 'scopesi':
                metadata = scopesiMetadata;
                break;
            case 'retargetly':
                metadata = getRetargetlyMetadata();
                break;
            case 'telefonica':
                metadata = telefonicaMetadata;
                break;
            case 'cinnecta':
                metadata = cinnectaMetadata;
                break;
            case 'onemata':
                metadata = onemataMetadata;
                break;
            default:
                metadata = scopesiMetadata;
                break;
        }
        const selectedProvider = metadata.data;
        dispatch(newCampaignActions.setDemographics(selectedProvider));
    };

    const getWeekDays = () => {
        let weekDays = newCampaign.timing.daysEnabled;
        if (newCampaign.timing.daysEnabled.length === 0)
            weekDays = fillWithDays();
        weekDays = formatDaysFromNumbers(weekDays);
        if (newCampaign.demographics.id === 'scopesi') {
            return weekDays;
        } else {
            const workingDays = ['Mon', 'Tue', 'Wed', 'Thi', 'Fri'];
            const weekendDays = ['Sun', 'Sat'];
            const weekDaysWeekend = [];
            if (
                weekDays.some((day) =>
                    workingDays.some((woorkingDay) => woorkingDay === day)
                )
            )
                weekDaysWeekend.push('working');
            if (
                weekDays.some((day) =>
                    weekendDays.some((weekendDay) => weekendDay === day)
                )
            )
                weekDaysWeekend.push('weekend');
            return weekDaysWeekend;
        }
    };

    const formatCampaignLikeJson = (campaign, margin) => {
        const valuesAccuracy = {
            0: 'low',
            1: 'mid',
            2: 'high',
        };
        const request = {
            campaign: 0,
            budget: 0,
            start_period: 0,
            end_period: 0,
            name: '',
            user: session.user.id,
            advertiser: '',
            brand: '',
            provider: [],
            hasAudienceFilter: false,
            audience: {
                incomeLevel: [],
                gender: [],
                age: [],
                accuracy: '',
            },
            tcpm: 0,
            cpm: 0,
            asap: 1,
            margin: 0,
            cpm_optimize: 0,
            creative_categories: [],
            tmzn: 0,
            currency: '',
            deals_ids: [],
            ads: [],
            time_hours: [],
            time_days: [],
            frequency_cap: {},
            screens_sizes: [],
            screens: [],
        };
        let deals = [];
        let screenSizes = [];
        let ads = [];
        campaign.selectedScreens.map((screen) => {
            deals.push(screen.deals.id);
            screenSizes.push(`${screen.width}x${screen.height}`);
            if (
                ads.findIndex(
                    (ad) =>
                        ad.width === screen.width && ad.height === screen.height
                ) === -1
            ) {
                ads.push({
                    id: 1,
                    type: 1,
                    url: '',
                    click_url: '',
                    media_url: '',
                    last_update: '',
                    width: screen.width,
                    height: screen.height,
                    url_secure: '',
                });
            }
        });
        request.margin = margin;
        request.campaign = campaign.id;
        request.budget = campaign.budget.budget;
        request.start_period = campaign.timing.periodValue[0];
        request.end_period = campaign.timing.periodValue[1];
        request.name = campaign.name;
        request.brand = campaign.brand;
        request.provider = [campaign.demographics.id];
        request.hasAudienceFilter =
            campaign.segments.incomeLevel.length > 0 ||
            campaign.segments.gender.length > 0 ||
            campaign.segments.age.value.length > 0;
        request.audience = {
            incomeLevel: campaign.segments.incomeLevel,
            gender: campaign.segments.gender,
            age: campaign.segments.age.value,
            accuracy: valuesAccuracy[campaign.segments.accuracy.sliderValue],
        };
        // the cpm max to use the bidder
        request.tcpm = campaign.budget.cpm;
        // the max cpm without margin
        request.cpm = campaign.budget.cpm * 1000;
        // TODO: the asap and pacing are the same
        request.asap = campaign.timing.isAsap === true ? 1 : 0;
        request.frequency_cap = {
            pacing: campaign.timing.isAsap === true ? 1 : 0,
        };
        request.creative_categories = campaign.selectedCreativeCategories;
        request.tmzn = campaign.timing.selectedTimezone.gmt;
        request.currency = campaign.selectedCurrency.short_name;
        request.deals_ids = deals;
        request.ads = ads;
        request.time_hours =
            campaign.timing.hoursEnabled.length === 0
                ? fillWithHours()
                : campaign.timing.hoursEnabled;
        request.time_days =
            campaign.timing.daysEnabled.length === 0
                ? fillWithDays()
                : campaign.timing.daysEnabled;
        request.screens = campaign.selectedScreens;
        request.screens_sizes = [...new Set(screenSizes)];
        if (campaign.dailyCap.isActive) {
            if (campaign.dailyCap.isDistribution) {
                request.daily_limit = 0;
                request.daily_limit_unit = 0;
            } else {
                request.daily_limit = campaign.dailyCap.isBudget
                    ? campaign.dailyCap.budget
                    : campaign.dailyCap.impressions;
                // 1 limit by budget, 0 by impressions
                request.daily_limit_unit = campaign.dailyCap.isBudget ? 1 : 0;
            }
        }
        return request;
    };

    const calculateForecast = async (
        campaign,
        isAdmin = auth.isAdmin() || auth.isSupervising()
    ) => {
        try {
            let margin = await getMargins();
            const campaignFormated = formatCampaignLikeJson(campaign, margin);
            const response = await axios.post(
                `${endpoints.getForecaster}?isAdmin=${isAdmin}`,
                { campaignJson: campaignFormated }
            );
            if (response.error) {
                throw response.error;
            } else {
                const formatedMetrics = formatMetrics(response.data);
                dispatch(newCampaignActions.setForecast(formatedMetrics));
                return response.data?.schedules;
            }
        } catch (e) {
            console.error(e.message);
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t(
                        'common.notifications.error.errorCalculateForecast-title'
                    ),
                    message: t(
                        'common.notifications.error.errorCalculateForecast-description'
                    ),
                })
            );
        }
    };

    const formatMetrics = (response) => {
        const metrics = {};
        const schedules = response.schedules;
        metrics.schedules = schedules;
        const summary = response.summary;
        metrics.costs = `${newCampaign.selectedCurrency.symbol}${formatNumber(
            summary.costs,
            'currency'
        )}`;
        metrics.impressions = summary.impressions;
        metrics.frequency = isNaN(summary.totalFrequency)
            ? 0
            : lastTwoDecimal(summary.totalFrequency);
        metrics.cpm = lastTwoDecimal(summary.cpm);
        metrics.cpm = `${newCampaign.selectedCurrency.symbol}${formatNumber(
            summary.cpm,
            'currency'
        )}`;
        const coverage = summary.coverage;
        metrics.coverage = isNaN(coverage) ? 0 : coverage;
        metrics.grp = isNaN(summary.totalGrp)
            ? 0
            : lastTwoDecimal(summary.totalGrp);
        metrics.cpi = `${newCampaign.selectedCurrency.symbol}${formatNumber(
            summary.cpi,
            'currency',
            null,
            { decimals: summary.cpi < 0.01 ? 4 : 2 }
        )}`; // add an aditional decimal number if cpi is lower than 0.01
        metrics.targetUniverse = summary.targetUniverse
            ? formatNumber(summary.targetUniverse, 'currency')
            : ' 0';
        metrics.totalImpacts = formatNumber(summary.totalImpacts, 'currency');
        metrics.coverage = formatNumber(metrics.coverage, 'cpi') + '%';
        metrics.coverageAbs = formatNumber(summary.totalCoverageAbs, 'short');
        const adImpressions = numberWithCommas(Math.round(summary.impressions));
        metrics.adImpressions =
            adImpressions !== '' ? formatNumber(adImpressions, 'rounded') : 0;
        metrics.notMatchingScreens = response.notMatchingScreens;
        metrics.screensNotUsed = response.screensNotUsed;
        metrics.screensWithoutProvider = response.screensWithoutProvider;
        metrics.validScreens = response.validScreens;
        metrics.screensUsed = response.screensUsed ? response.screensUsed : [];
        metrics.duration = summary.campaignDuration
            ? summary.campaignDuration
            : 0;
        metrics.targetImpacts = summary.totalImpacts;
        metrics.isUnderspend = summary.isUnderspend;
        return metrics;
    };

    const activateCampaign = async (campaign) => {
        try {
            await axios.put(endpoints.activateCampaignV2 + campaign.id);

            moveFrom('INACTIVE', 'ACTIVE', campaign);

            dispatch(
                notificationsActions.setNotification({
                    type: 'success',
                    title: t(
                        'common.notifications.success.campaignSuccessfullActivated-title'
                    ),
                    message: t(
                        'common.notifications.success.campaignSuccessfullActivated-description'
                    ),
                })
            );
        } catch (error) {
            ErrorMessage(error);
        }
    };

    const deactivateCampaign = async (campaign) => {
        try {
            const { data } = await axios.put(
                endpoints.deactivateCampaignV2 + campaign.id
            );

            moveFrom('ACTIVE', 'INACTIVE', campaign);

            dispatch(
                notificationsActions.setNotification({
                    type: 'success',
                    title: t(
                        'common.notifications.success.campaignSuccessfullDeactivated-title'
                    ),
                    message: t(
                        'common.notifications.success.campaignSuccessfullDeactivated-description'
                    ),
                })
            );
        } catch (error) {
            ErrorMessage(error);
        }
    };

    const restoreCampaign = async (campaign) => {
        try {
            const { data } = await axios.put(
                endpoints.restoreCampaignV2 + campaign.id
            );
            if (!data.error) {
                moveFrom('DELETED', 'INACTIVE', campaign);
            }

            dispatch(
                notificationsActions.setNotification({
                    type: 'success',
                    title: t(
                        'common.notifications.success.campaignSuccesfullRestore-title'
                    ),
                    message: t(
                        'common.notifications.success.campaignSuccesfullRestore-description'
                    ),
                })
            );
        } catch (error) {
            ErrorMessage(error);
        }
    };

    const deleteCampaign = async (campaign, status) => {
        try {
            const { data } = await axios.delete(
                endpoints.removeCampaignV2 + campaign.id
            );

            await updateBudgets();
            // validation so that the deleted campaign is removed to the current section if it
            // does not have that current status (for example active)
            // JOB_PENDING for when one of this list is deleted,
            // since the status is not inactive, then it can be deleted
            const isRemoveoCurrentSection = status == 'JOB_PENDING';
            const newStatuses = JSON.parse(
                JSON.stringify(campaignList.statuses)
            );
            newStatuses[campaign.status] = newStatuses[campaign.status] - 1;
            if (newStatuses[campaign.status] === 0)
                delete newStatuses[campaign.status];
            let newCampaignsData = Array.from(campaignList.campaigns);
            newCampaignsData = newCampaignsData.map((data) => {
                if (
                    data.status === campaign.status ||
                    isRemoveoCurrentSection
                ) {
                    const newData = Object.assign({}, data);
                    newData.campaigns = newData.campaigns.filter(
                        (c) => c.id !== campaign.id
                    );
                    return newData;
                } else {
                    return data;
                }
            });

            getCampaignsPerStatus();
            dispatch(campaignsActions.setCampaigns(newCampaignsData));
        } catch (error) {
            ErrorMessage(error);
        }
    };

    const formatCampaigns = (campaigns) => {
        campaigns.forEach((campaign) => formatCampaign(campaign));
    };

    const formatCampaign = (campaign) => {
        const formatNumber = (number) =>
            number !== undefined && number !== null && number !== 0
                ? numberWithCommas(number.toFixed(2))
                : '0';
        campaign.auto_distribution =
            campaign.auto_distribution === null
                ? 0
                : campaign.auto_distribution;
        campaign.daily_limit = parseInt(campaign.daily_limit);
        campaign.budget =
            typeof campaign.budget === 'string'
                ? parseInt(numberWithoutCommas(campaign.budget))
                : campaign.budget;
        campaign.name =
            campaign.name || t('sections.campaign-list.without-name');
        campaign.brand =
            campaign.brand || t('sections.campaign-list.without-brand');
        campaign.dailyCapType =
            campaign.limit_type === 1
                ? t(
                      'sections.campaigns.new-campaign.wizard-basics.dailyCap.budget-label'
                  )
                : t(
                      'sections.campaigns.new-campaign.wizard-basics.dailyCap.impressions-label'
                  );
        campaign.spent = formatNumber(campaign.spent);
        campaign.totalSpent = formatNumber(campaign.totalSpent);
        campaign.impressions = formatNumber(campaign.impressions);
        campaign.totalImpressions = formatNumber(campaign.totalImpressions);
        campaign.isDailyCap = !(
            campaign.auto_distribution === 0 && campaign.daily_limit === 0
        );
        campaign.daily_limit = formatNumber(parseInt(campaign.daily_limit));
        campaign.dailyProgress = -1;
        if (campaign.isDailyCap && parseInt(campaign.daily_limit) !== 0) {
            let divisor = 0;
            const impressions = campaign.impressions
                ? parseInt(numberWithoutCommas(campaign.impressions))
                : 0;
            const spent = campaign.spent
                ? parseInt(numberWithoutCommas(campaign.spent))
                : 0;
            const dailyLimit = parseInt(
                numberWithoutCommas(campaign.daily_limit)
            );
            divisor = campaign.limit_type === 1 ? spent : impressions;
            campaign.dailyProgress = (divisor / dailyLimit) * 100;
            campaign.dailyProgress = campaign.dailyProgress.toFixed(2);
        }
        campaign.spentProgress = -1;
        if (campaign.totalSpent && campaign.budget > 0) {
            campaign.spentProgress =
                (numberWithoutCommas(campaign.totalSpent) /
                    numberWithoutCommas(campaign.budget)) *
                100;
            campaign.spentProgress = campaign.spentProgress.toFixed(2);
        }
        campaign.budget = formatNumber(campaign.budget);
        campaign.isActive = campaign.status === 'ACTIVE';
        let days = formatDaysFromNumbers(campaign.days_enabled);
        days = days.map((day) =>
            t('common.days.abbreviated-days.' + day.toLowerCase())
        );
        campaign.days = arrayToList(days.map((day) => ({ label: day })));
        let hoursAM = campaign.hours_enabled
            .filter((hour) => hour < 12)
            .map((hour) => (hour !== 0 ? hour : 12));
        let hoursPM = campaign.hours_enabled
            .filter((hour) => hour >= 12)
            .map((hour) => hour - 12)
            .map((hour) => (hour !== 0 ? hour : 12));
        hoursPM = hoursPM.sort((a, b) => a - b);
        hoursAM = hoursAM.sort((a, b) => a - b);
        campaign.hoursPM = arrayToList(
            hoursPM.map((hour) => ({ label: hour }))
        );
        campaign.hoursAM = arrayToList(
            hoursAM.map((hour) => ({ label: hour }))
        );
        campaign.isTest = campaign.is_test;
    };

    const duplicateCampaign = async (campaign, status) => {
        try {
            const response = getData(
                await axios.post(endpoints.duplicateCampaignV2 + campaign.id)
            );
            const duplicatedCampaign = response.campaign;
            duplicatedCampaign.screen = campaign.screen;
            formatCampaign(duplicatedCampaign);
            let newCampaignsData = campaignList.campaigns.map((data) => {
                // add campaign cloned to postiion 0 in list
                // if it's already saved in previus querys
                if (data.status === duplicatedCampaign.status) {
                    let copyArray = [...data.campaigns];
                    copyArray.unshift(duplicatedCampaign);
                    let copyData = { ...data, campaigns: copyArray };
                    return copyData;
                } else {
                    return data;
                }
            });

            const newCurrent = newCampaignsData.find(
                (data) => data.status === campaignList.campaignsShowing.status
            );

            await getCampaignsPerStatus();
            dispatch(campaignsActions.setCampaigns(newCampaignsData));
            dispatch(campaignsActions.changeCampaigns(newCurrent));
        } catch (error) {
            ErrorMessage(error);
        }
    };

    /**
     * Move one campaign from one list to another list
     * @param {String} status1 status of the list where is the campaign that have to move to another list
     * @param {String} status2 status of the target list to move the campaign
     * @param {*} campaign with id of the campaign to move
     * @returns List with states of each status
     */
    const moveFrom = (status1, status2, campaign) => {
        const newStatuses = JSON.parse(JSON.stringify(campaignList.statuses));
        newStatuses[status1] = newStatuses[status1] - 1;
        if (newStatuses[status1] === 0) delete newStatuses[status1];
        newStatuses[status2] = newStatuses[status2]
            ? newStatuses[status2] + 1
            : 1;
        campaign = JSON.parse(JSON.stringify(campaign));
        campaign.status = status2;
        campaign.isActive = status2 === 'ACTIVE';

        let newCampaignsData = Array.from(campaignList.campaigns);
        newCampaignsData = newCampaignsData.map((data) => {
            if (data.status === status2) {
                const newData = Object.assign({}, data);
                newData.campaigns = [campaign, ...newData.campaigns];
                return newData;
            } else if (data.status === status1) {
                const newData = Object.assign({}, data);
                newData.campaigns = newData.campaigns.filter(
                    (c) => c.id !== campaign.id
                );
                return newData;
            } else {
                return data;
            }
        });

        dispatch(campaignsActions.setStatuses(newStatuses));
        dispatch(campaignsActions.setCampaigns(newCampaignsData));
    };

    const getLocationDataFromCampaign = async (campaign) => {
        const nodes = await getNodes();
        const selectedCountries = [];
        const selectedRegions = [];
        let selectedCities = [];
        const selectedNeighborhoods = [];
        const countries = [];
        const regions = [];
        const cities = [];
        const areaRegex = /Area/;
        const localityRegex = /Locality/;
        nodes.forEach((node) => {
            const country = { value: node.name, label: node.label };
            const isSelectedCountry = campaign.countries_selected.includes(
                country.value
            );
            if (isSelectedCountry) selectedCountries.push(country);
            countries.push(country);

            if (isSelectedCountry) {
                node.nodes.forEach((area) => {
                    if (areaRegex.test(area.type)) {
                        let region = { value: area.name, label: area.name };
                        let isSelectedRegion =
                            campaign.regions_selected.includes(region.value);
                        if (isSelectedRegion) selectedRegions.push(region);
                        regions.push(region);

                        area.nodes?.forEach((areaOrLocation) => {
                            if (
                                localityRegex.test(areaOrLocation.type) ||
                                (areaRegex.test(areaOrLocation.type) &&
                                    areaOrLocation.nodes)
                            ) {
                                region = {
                                    value: areaOrLocation.name,
                                    label: areaOrLocation.name,
                                };
                                let isSelectedRegion2 =
                                    campaign.regions_selected.includes(
                                        region.value
                                    );

                                if (isSelectedRegion || isSelectedRegion2) {
                                    if (areaOrLocation.nodes) {
                                        areaOrLocation.nodes.forEach(
                                            (location) => {
                                                let city;
                                                if (
                                                    campaign
                                                        .countries_selected[0] !==
                                                        'BR' &&
                                                    campaign
                                                        .countries_selected[0] !==
                                                        'MX'
                                                ) {
                                                    city = {
                                                        value: `${areaOrLocation.name} - ${location.name}`,
                                                        label: `${areaOrLocation.name} - ${location.name}`,
                                                    };
                                                } else {
                                                    city = {
                                                        value: areaOrLocation.name,
                                                        label: areaOrLocation.name,
                                                    };
                                                }
                                                const isSelectedCity =
                                                    campaign.cities_selected.includes(
                                                        city.value
                                                    );

                                                if (isSelectedCity) {
                                                    selectedCities.push(city);
                                                }
                                                cities.push(city);

                                                let neighborhood = {
                                                    value: location.name,
                                                    label: location.name,
                                                };
                                                const isSelectedNeighborhood =
                                                    campaign.neighborhoods_selected.includes(
                                                        neighborhood.value
                                                    );

                                                if (isSelectedNeighborhood) {
                                                    selectedNeighborhoods.push(
                                                        neighborhood
                                                    );
                                                }
                                            }
                                        );
                                    }
                                }
                            } else if (
                                localityRegex.test(areaOrLocation.type)
                            ) {
                                const city = {
                                    value: areaOrLocation.name,
                                    label: areaOrLocation.name,
                                };
                                const isSelectedCity =
                                    campaign.cities_selected.includes(
                                        city.value
                                    );
                                if (isSelectedCity) {
                                    selectedCities.push(city);
                                }

                                cities.push(city);

                                let neighborhood = {
                                    value: areaOrLocation.name,
                                    label: areaOrLocation.name,
                                };

                                selectedNeighborhoods.push(neighborhood);
                            }
                        });
                    }
                });
                let hash = {};
                selectedCities = selectedCities.filter((city) =>
                    hash[city.value] ? false : (hash[city.value] = true)
                );
            }
        });

        const zones = campaign.zones ? campaign.zones : [];

        return {
            nodes,
            countries,
            selectedCountries,
            regions,
            selectedRegions,
            cities,
            selectedCities,
            selectedNeighborhoods,
            zones,
        };
    };

    const getSegmentsFromCampaign = (campaign) => {
        const coverage = parseInt(campaign.scopesi_coverage);
        const valuesAccuracy = ['low', 'mid', 'high'];
        let gender = [];
        let incomeLevel = [];
        const oldsGenders = {
            F: 'female',
            M: 'male',
        };
        const oldsNse = {
            B: 'low',
            M: 'mid',
            A: 'high',
        };
        // compatibility with old harcoded parameters gender
        campaign.scopesi_gender.forEach((genderValue) => {
            if (oldsGenders[genderValue]) {
                gender.push(oldsGenders[genderValue]);
            } else {
                gender.push(genderValue);
            }
        });
        // compatibility with old harcoded parameters Nse
        campaign.scopesi_nse.forEach((nseValue) => {
            if (oldsNse[nseValue]) {
                incomeLevel.push(oldsNse[nseValue]);
            } else {
                incomeLevel.push(nseValue);
            }
        });

        let age = { value: campaign.scopesi_ages, sliderValue: [18, 18] };
        if (campaign.scopesi_ages.length > 0) {
            age.sliderValue = [
                parseInt(campaign.scopesi_ages[0]),
                parseInt(
                    campaign.scopesi_ages[
                        campaign.scopesi_ages.length - 1
                    ].split('-')[1]
                ),
            ];
        }
        const segments = {
            activeSegment: 'age',
            age,
            gender,
            incomeLevel,
            interests: [],
            accuracy: {
                value: valuesAccuracy[coverage],
                sliderValue: coverage,
            },
        };

        return segments;
    };

    const getTimingFromCampaign = async (campaign) => {
        const timezones = await getTimezones();
        let selectedTimezone = timezones.find(
            (timezone) => timezone.id === campaign.timezone_id
        );
        //if there's not selecteds start date or end date period value is setted as null
        if (selectedTimezone === null || selectedTimezone === undefined) {
            if (timezones.length > 0) {
                selectedTimezone = guessTimezoneOfBrowser(timezones);
            } else {
                selectedTimezone = '';
            }
        }
        let periodValue = [
            setOtherZone(new Date(), selectedTimezone.name),
            new Date().getTime(),
        ];
        if (campaign.start_period) {
            periodValue[0] = campaign.start_period;
        } else {
            periodValue = null;
        }

        if (campaign.end_period) periodValue[1] = campaign.end_period;
        else {
            periodValue = null;
        }

        const timing = {
            //map for old draft campaigns wich are still using 0 as sunday
            daysEnabled:
                campaign.days_enabled.length &&
                campaign.days_enabled.map((numberDay) =>
                    numberDay == 0 ? 7 : numberDay
                ),
            hoursEnabled: campaign.hours_enabled,
            isAsap: campaign.asap === 1,
            isCellSelected: {
                cells: [
                    [
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                    ],
                    [
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                    ],
                    [
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                    ],
                    [
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                    ],
                    [
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                    ],
                    [
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                    ],
                    [
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                    ],
                ],
            },
            isDayParting:
                campaign.days_enabled.length === 7 &&
                campaign.hours_enabled.length === 24
                    ? false
                    : true,
            periodValue: periodValue,
            selectedTimezone: selectedTimezone ? selectedTimezone : '',
            timezones: timezones,
        };

        return timing;
    };

    const addMissingFieldsToCampaign = (campaign) => {
        const completeWith = (value, keys) =>
            keys.forEach(
                (key) => (campaign[key] = campaign[key] ? campaign[key] : value)
            );
        completeWith(
            [],
            [
                'selectedCountries',
                'selectedRegions',
                'selectedCities',
                'screens',
                'selectedCreativeCategories',
            ]
        );
        completeWith('', [
            'name',
            'ads_basename',
            'brand',
            'pixel_tracker_script',
        ]);
        completeWith(0, [
            'budget',
            'max_cpm',
            'cpm_optimize',
            'daily_limit',
            'limit_type',
            'auto_distribution',
        ]);
        completeWith(1, ['currency_id']);
    };

    const editCampaign = async (campaign) => {
        campaign = JSON.parse(JSON.stringify(campaign));
        addMissingFieldsToCampaign(campaign);
        const fetchData = async () => {
            let creatives = getCreatives(campaign.id);
            let location = getLocationDataFromCampaign(campaign);
            let segments = getSegmentsFromCampaign(campaign);
            let timing = getTimingFromCampaign(campaign);
            let selectedCreativeCategories = getCreativeCategories(
                'creative_categories' in campaign
                    ? campaign.creative_categories.filter(
                          (category) => category
                      )
                    : []
            );
            let screens = getScreens();
            creatives = await creatives;
            location = await location;
            timing = await timing;
            screens = await screens;
            selectedCreativeCategories = await selectedCreativeCategories;
            return {
                creatives,
                location,
                segments,
                timing,
                screens,
                selectedCreativeCategories,
            };
        };

        const {
            creatives,
            location,
            segments,
            timing,
            screens,
            selectedCreativeCategories,
        } = await fetchData();
        let selectedScreens = campaign.screens.map((screenId) =>
            screens.find((s) => s.id === screenId)
        );
        selectedScreens = selectedScreens.filter((value) => value);
        const emptyIfIsZero = (value) =>
            value === 0 || value === '0' ? '' : value;

        // NOTE: Audiences provider must be load here with the same key/data
        // from backend-v1 to work
        const providerOptions = {
            1: 'scopesi',
            2: 'cinnecta',
            3: 'onemata',
        };
        const campaignData = {
            id: campaign.id,
            name:
                campaign.name !== t('sections.campaign-list.without-name')
                    ? campaign.name
                    : '',
            baseName: campaign.ads_basename || '',
            brand:
                campaign.brand !== t('sections.campaign-list.without-brand')
                    ? campaign.brand
                    : '',
            budget: {
                budget: numberWithoutCommas(campaign.budget),
                cpm: emptyIfIsZero(parseInt(campaign.max_cpm)),
                cpmOptimizer: campaign.cpm_optimize === 1,
            },
            buyingRules: {
                isActive: false,
                buyIf: '',
                rules: [],
            },
            canLaunch: false,
            channels: {
                isDOOH: false,
                isMobile: false,
                isTV: false,
            },
            creatives,
            selectedCreativeCategories,
            currentPositionMap: null,
            currentWizard: 'basics',
            dailyCap: {
                budget: emptyIfIsZero(
                    numberWithoutCommas(campaign.daily_limit)
                ),
                impressions: emptyIfIsZero(
                    numberWithoutCommas(campaign.daily_limit)
                ),
                isActive: !(
                    parseInt(campaign.daily_limit) === 0 &&
                    0 === campaign.auto_distribution
                ),
                isBudget: campaign.limit_type === 1,
                isImpressions: campaign.limit_type === 0,
                isDistribution: campaign.auto_distribution === 1,
            },
            demographics: {
                id:
                    campaign.provider_audience &&
                    providerOptions[campaign.provider_audience],
            },

            errors: [],
            forecast: campaign.forecast
                ? campaign.forecast
                : {
                      targetUniverse: '-',
                      totalImpacts: '-',
                      coverage: '-',
                      coverageAbs: '-',
                      adImpressions: '-',
                      frequency: '-',
                      cpm: '-',
                      grp: '-',
                      screens: '-',
                      duration: '-',
                      validScreens: [],
                      screensNotUsed: [],
                      notMatchingScreens: [],
                      screensWithoutProvider: [],
                      screensUsed: [],
                      costs: '-',
                      isUnderspend: false,
                  },
            totalSpent: campaign.totalSpent,
            forecastTable: [],
            isResizeMapOn: false,
            location,
            segments,
            selectedCurrency: currencies.find(
                (currency) => currency.id === campaign.currency_id
            ),
            selectedScreens,
            sizesNeeded: [],
            stepper: { index: 0, wizard: 'basics' },
            timing,
            tracking: {
                isActive: !isEmpty(campaign.pixel_tracker_script),
                externalTracking: isEmpty(campaign.pixel_tracker_script)
                    ? ''
                    : campaign.pixel_tracker_script,
            },
            wasLaunched: false,
            status: campaignStatus(campaign.status),
            isTest: campaign.is_test,
            label_id: campaign.label_id,
        };
        localStorage.setItem(
            'newCampaignData',
            LZString.compress(JSON.stringify(campaignData))
        ); // here
    };

    const getCreatives = async (campaignId) => {
        try {
            const url = `${endpoints.base}/campaign/${campaignId}/ads`;
            const { data } = await axios.get(url);
            const creatives = data.data;
            creatives.forEach((creative) => {
                const splited = creative.original_filename.split('.');
                let extension = splited[splited.length - 1];

                let isVast = creative.type === 8;
                let isScript = creative.type === 3;

                if (isVast) {
                    extension = 'vast';
                }
                if (isScript) {
                    extension = 'script';
                }

                creative.extension =
                    extension.type !== '' ? extension : 'script';
            });

            return creatives;
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    const removeZerosInDaysHours = (stats) => {
        const removeZerosIn = (key) => {
            const daysHours = {};
            // FIXME: Check if stats[key] is not null or undefined
            // OJO
            if (stats[key]) {
                Object.keys(stats[key]).forEach((currencyName) => {
                    daysHours[currencyName] = {};
                    Object.keys(stats[key][currencyName]).forEach((dayHour) => {
                        daysHours[currencyName][parseInt(dayHour)] =
                            stats[key][currencyName][dayHour];
                    });
                });
            }
            return daysHours;
        };
        stats.day = removeZerosIn('day');
        stats.hour = removeZerosIn('hour');

        return stats;
    };

    const fetchCreativeCategoriesJson = async () => {
        let data = await fetch(
            'https://data.42matters.com/api/iab_categories_v2.json'
        );
        let categories = await data.json();

        return categories;
    };

    const saveCreativeCategoriesInDbAndLocalStorage = (categories) => {
        // //Save categories data timestamp in localStorage
        localStorage.setItem('categoriesCacheTimeout', Date.now());

        // Save categories in db
        db.categories
            .bulkPut(categories, 'Name')
            .catch((err) => console.error(err.message));

        // Save categories in localStorage
        localStorage.setItem(
            'categories',
            LZString.compress(JSON.stringify(categories))
        );
    };

    const getCreativeCategories = async (optionalCategories = null) => {
        try {
            const categoriesStore = await LZString.decompress(
                localStorage.getItem('categories')
            );
            let hour = 1000 * 60 * 60;
            let categoriesCacheTimestap =
                localStorage.getItem('categoriesCacheTimeout') || 0;
            // if the current time is low and there are categories loads
            if (
                categoriesStore &&
                Date.now() < Number(categoriesCacheTimestap) + hour
            ) {
                //return the loaded in db local categories
                const categories = JSON.parse(categoriesStore);
                if (optionalCategories) {
                    // adapter reponse from api to react-select
                    let categoriesSelected = optionalCategories.map(
                        (categorySaved) => {
                            let categoryObject = categories.find(
                                (categoryApi) =>
                                    categoryApi.Name == categorySaved
                            );
                            categoryObject.id = categoryObject.UniqueID;
                            categoryObject.label = categoryObject.Name;
                            return categoryObject;
                        }
                    );
                    return categoriesSelected;
                } else {
                    return categories;
                }
            }
            //Clean up database for new data
            db.categories.clear();
            let categories = await fetchCreativeCategoriesJson();

            saveCreativeCategoriesInDbAndLocalStorage(categories);

            if (optionalCategories) {
                // adapter reponse from api to react-select
                let categoriesSelected = optionalCategories.map(
                    (categorySaved) => {
                        let categoryObject = categories.find(
                            (categoryApi) => categoryApi.Name == categorySaved
                        );
                        categoryObject.id = categoryObject.UniqueID;
                        categoryObject.label = categoryObject.Name;
                        return categoryObject;
                    }
                );
                return categoriesSelected;
            } else {
                return categories;
            }
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    const formatTotals = (objectTotals, currencySymbol) => {
        if (!objectTotals) {
            return;
        }

        let newObject;
        if (!currencySymbol) {
            newObject = {
                spent: formatNumber(objectTotals.spent, 'currency'),
                ecpm: formatNumber(objectTotals.ecpm, 'currency'),
                impacts: formatNumber(objectTotals.impacts, 'rounded'),
                impressions: formatNumber(objectTotals.impressions, 'rounded'),
                spot: formatNumber(objectTotals.spot, 'currency'),
            };
        } else {
            newObject = {
                spent: formatNumber(
                    objectTotals.spent,
                    'properCurrency',
                    null,
                    { currency: currencySymbol }
                ),
                ecpm: formatNumber(objectTotals.ecpm, 'properCurrency', null, {
                    currency: currencySymbol,
                }),
                impacts: formatNumber(objectTotals.impacts, 'rounded'),
                impressions: formatNumber(objectTotals.impressions, 'rounded'),
                spot: formatNumber(objectTotals.spot, 'properCurrency', null, {
                    currency: currencySymbol,
                }),
            };
        }

        return newObject;
    };

    const getStatsByUser = async (from, to, rawData) => {
        try {
            const userId = session.user.id;

            let promiseStatsByCampaignsHistory =
                statsService.getStatsOfCampaignsByUserIdHistory(userId);

            let promiseStatsByCampaigns =
                statsService.getStatsOfCampaignsByUserId(
                    from,
                    to,
                    rawData,
                    userId
                );

            let promiseStatsBydays = statsService.getStatsByDay(
                from,
                to,
                userId
            );
            let promiseStatsByBrands = statsService.getStatsBrandsByUserId(
                from,
                to,
                userId
            );
            let promiseStatsByHours = statsService.getStatsByHours(
                from,
                to,
                userId
            );
            let [
                statsByCampaigns,
                statsByDays,
                statsByHours,
                statsByBrands,
                statsByCampaignsHistory,
            ] = await Promise.allSettled([
                promiseStatsByCampaigns,
                promiseStatsBydays,
                promiseStatsByHours,
                promiseStatsByBrands,
                promiseStatsByCampaignsHistory,
            ]);
            statsByCampaignsHistory = statsByCampaignsHistory.value.data.stats;

            //set acumulated data for actives campaigns
            dispatch(
                dashboardActions.setReportsForActiveCampaigns(
                    statsByCampaignsHistory
                )
            );

            statsByCampaigns = statsByCampaigns.value.data;
            statsByDays = statsByDays.value.data;
            statsByBrands = statsByBrands.value.data;
            statsByHours = statsByHours.value.data;
            let response = {};

            const currenciesOfStats = []; //save all different currencies from stats and set the stats for each currency
            // totals for dashboard top
            Object.entries(statsByCampaigns.totals).forEach(
                ([currencyShort, totalByCurrency]) => {
                    const currencyData = getCurrencyByName(currencyShort);
                    const totals = formatTotals({ ...totalByCurrency }, '');
                    totals.overBudget =
                        (totalByCurrency.spentAllCampaigns /
                            totalByCurrency.budgetAllCampaigns) *
                        100;
                    totals.budget = formatNumber(
                        totalByCurrency.budgetAllCampaigns,
                        'currency'
                    );
                    totals.amountActiveCampaigns =
                        totalByCurrency.amountActiveCampaigns;
                    currenciesOfStats.push({
                        currency: currencyData,
                        stats: totals,
                    });
                }
            );
            response.currenciesOfStats = currenciesOfStats;
            statsByCampaigns.currencies.forEach((shortName) => {
                const dataCurrency = getCurrencyByName(shortName);
                // format totals for down table
                statsByCampaigns.totals[shortName] = formatTotals(
                    statsByCampaigns.totals[shortName],
                    dataCurrency.short_name
                );
                statsByDays.totals[shortName] = formatTotals(
                    statsByDays.totals[shortName],
                    dataCurrency.short_name
                );
                statsByHours.totals[shortName] = formatTotals(
                    statsByHours.totals[shortName],
                    dataCurrency.short_name
                );
                statsByBrands.totals[shortName] = formatTotals(
                    statsByBrands.totals[shortName],
                    dataCurrency.short_name
                );

                // add dates and properties
                statsByDays.stats[shortName].forEach((stat) => {
                    moment.locale(window.navigator.language);
                    let dateWithMonth = moment(stat.date, 'YYMMDDHH').format(
                        'L'
                    );
                    dateWithMonth = dateWithMonth.substring(0, 5);
                    stat.dateWithMonth = dateWithMonth;
                    stat.day = dateWithMonth;
                    stat.name = dateWithMonth;
                    stat.spot = stat.spot;
                    stat.days = stat.name;
                });

                // add links for table TO STATS BY CAMPAIGNS
                statsByCampaigns.stats[shortName].forEach((statByCampaign) => {
                    statByCampaign.link = `/dashboard/${statByCampaign.campaignid}`;
                    statByCampaign.id = statByCampaign.campaignid;
                    statByCampaign.name = statByCampaign.campaignname;
                });
            });

            const dashboardGeneralReports = [
                {
                    tabName: t('common.words.campaigns'),
                    columnName: t('common.words.campaign'),
                    data: statsByCampaigns.stats,
                },
                {
                    tabName: capitalizeFirstLetter(t('common.words.days')),
                    columnName: capitalizeFirstLetter(t('common.words.day')),
                    data: statsByDays.stats,
                },
                {
                    tabName: t('common.words.hours'),
                    columnName: t('common.words.hour'),
                    data: statsByHours.stats,
                },
                {
                    tabName: t('common.words.brands'),
                    columnName: t('common.words.brand'),
                    data: statsByBrands.stats,
                },
                {
                    currencies: statsByCampaigns.currencies,
                },
            ];
            dispatch(
                dashboardActions.setDashboardGeneralReports(
                    dashboardGeneralReports
                )
            );
            dispatch(dashboardActions.setCampaignStats(response));
            return [
                statsByCampaigns.totals,
                statsByDays.totals,
                statsByHours.totals,
                statsByBrands.totals,
            ];
        } catch (e) {
            console.error({ e });
        }
    };

    const getStatsByDay = async (from, to) => {
        try {
            from = new Date(from);
            from = formatDate(from);
            to = new Date(to);
            to = formatDate(to);
            let url = `${endpoints.getStats}/days/user/${session.user.id}?from=${from}&to=${to}`;
            const response = await axios.get(url);

            return response;
        } catch (e) {
            console.error(e);
        }
    };

    const getStatsByCampaign = async (campaignId, from, to) => {
        try {
            from = new Date(from);
            from = formatDate(from);
            to = new Date(to);
            to = formatDate(to);
            let url = `${endpoints.getStats}/days/campaign/${campaignId}?from=${from}&to=${to}`;
            const response = await axios.get(url);

            return response;
        } catch (e) {
            console.error(e);
        }
    };

    const getStatsByCampaignByHours = async (campaignId, from, to) => {
        try {
            from = new Date(from);
            from = formatDate(from);
            to = new Date(to);
            to = formatDate(to);
            let url = `${endpoints.getStats}/hours/campaign/${campaignId}?from=${from}&to=${to}`;
            const response = await axios.get(url);

            return response;
        } catch (e) {
            console.error(e);
        }
    };

    const getCurrencyById = (id) => {
        return currencies.find((currency) => currency.id === parseInt(id));
    };

    const getCurrencyByName = (name) => {
        return currencies.find((currency) => currency.short_name === name);
    };

    const parseRolToString = (rol) => {
        const translations =
            'sections.management.section.users.users.createUserModal.';
        switch (rol) {
            case 1:
                rol = t(translations + 'roles.admin');
                break;
            case 2:
                rol = t(translations + 'roles.manager');
                break;
            case 3:
                rol = t(translations + 'roles.advertiser');
                break;
            case 4:
                rol = t(translations + 'roles.slave');
                break;
            case 5:
                rol = t(translations + 'roles.supervisor');
                break;
            default:
                break;
        }
        return rol;
    };

    const parseMargin = (margin) => {
        const MARGIN_LENGTH = 10;
        return margin.toFixed(MARGIN_LENGTH);
    };

    const getUsers = async (
        username = '',
        // FIXME: limit is not used, it should use env REACT_APP_MAX_USERS_PER_DROPDOWN
        limit = null,
        page = 1
    ) => {
        const parseRoles = (data) => {
            data.forEach((user) => {
                user.roleNumber = user.role;
                user.role = parseRolToString(user.role);
            });

            return data;
        };

        try {
            const params = {};

            if (limit) {
                params.limit = limit;
                params.page = page;
            }

            if (username !== '') {
                params.username = username;
            }
            let response = getData(
                await axios.get(endpoints.getUsers, { params })
            );
            response = JSON.parse(JSON.stringify(response));
            if (limit) parseRoles(response.data);
            else parseRoles(response);

            return response;
        } catch (e) {
            console.error({
                type: 'error',
                title: 'Error',
                message: `Statuses Error: ${e}`,
            });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    const getUserById = async (id) => {
        const user = getData(await axios.get(endpoints.getUserById + id));
        return user;
    };

    const addBilling = async (user, currency, billing, transactionType) => {
        try {
            const body = {
                userId: user.id,
                currencyId: currency.id,
                transactionType: transactionType,
                billing,
            };
            await axios.post(endpoints.addBillingV2, body);

            dispatch(
                notificationsActions.setNotification({
                    type: 'success',
                    title: t(
                        'sections.management.section.billing.funding.title'
                    ),
                    message: t(
                        'sections.management.section.billing.funding.successfulMessage'
                    ),
                })
            );
        } catch (error) {
            ErrorMessage(error);
        }
    };

    const isValidUsername = async (username) => {
        if (username.trim() === '') return false;
        try {
            let url = `${endpoints.getUsers}?limit=1&username=${username}&page=1`;
            const response = getData(await axios.get(url));
            if (response.data.length === 1) {
                const [user] = response.data;
                return user.username.toLowerCase() !== username.toLowerCase();
            } else {
                return true;
            }
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
            return false;
        }
    };

    const getCompanies = async () => {
        const companies = getData(await axios.get(endpoints.getCompanies));
        return companies;
    };

    const isValidEditUsername = async (username) => {
        if (username === '') return false;
        try {
            let url = `${endpoints.getUsers}?limit=1&username=${username}&page=1`;
            const response = getData(await axios.get(url));
            if (response.data.length === 1) {
                const [user] = response.data;
                return user.username.toLowerCase() !== username.toLowerCase();
            } else {
                return true;
            }
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
            return false;
        }
    };

    const formDataToBodyParams = (formData) => {
        let parentAccountId;
        let role;

        if (formData.isParentAccount) {
            role = 4;
            parentAccountId = formData.userSelected.id;
        } else {
            parentAccountId = null;
            role = stringToRoleUser(formData.roleSelected.value);
        }

        const type = stringToTypeUser(formData.typeUser.value);
        const data = {
            username: formData.username,
            password: formData.wantChangePassword ? formData.password : '',
            name: formData.name,
            type: type,
            email: formData.email,
            role: role,
            parent_id: parentAccountId,
            company: formData.companyName,
            margin: parseFloat(formData.margin),
            company_id: formData.hasCompany ? formData.company.id : 1,
            business_name: formData.businessName,
            country_id: formData.countrySelected.id,
            tax_id: formData.taxId,
            show_ooh_option: formData.showOohOption ? 1 : 0,
            cpm_mode: formData.cpm_mode ? 1 : 0,
            address: formData.address,
            phone_number: formData.phoneNumber,
        };

        return data;
    };

    const createUser = async (formData) => {
        try {
            const bodyParams = formDataToBodyParams(formData);
            const response = await axios.post(endpoints.createUser, bodyParams);
            if (!response.data.error) {
                const userCreated = getData(response);
                formData.ssps.forEach((ssp) => {
                    assignSspToUser(userCreated.id, ssp);
                });
                dispatch(
                    notificationsActions.setNotification({
                        type: 'success',
                        title: t(
                            'sections.management.section.users.users.createUserModal.userCreatedTitle'
                        ),
                        message: t(
                            'sections.management.section.users.users.createUserModal.userCreatedMessage'
                        ),
                    })
                );
                return {
                    ...userCreated,
                    role: parseRolToString(userCreated.role),
                    rtb_margin: parseMargin(userCreated.rtb_margin),
                };
            } else {
                console.error(response.data.errors);
                dispatch(
                    notificationsActions.setNotification({
                        type: 'warning',
                        title: t('common.notifications.error.title'),
                        message: t('common.notifications.error.errorData'),
                    })
                );
            }
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
            return false;
        }
    };

    const editUser = async (formData, userId) => {
        try {
            const bodyParams = formDataToBodyParams(formData);
            const response = await axios.put(
                endpoints.editUser + userId,
                bodyParams
            );
            asignSspToUser(userId, formData.ssps);
            let newUser = getData(response);
            if (!response.data.error) {
                dispatch(
                    notificationsActions.setNotification({
                        type: 'success',
                        title: t(
                            'sections.management.section.users.users.createUserModal.userEditedTitle'
                        ),
                        message: t(
                            'sections.management.section.users.users.createUserModal.userEditedMessage'
                        ),
                    })
                );
                newUser.roleNumber = newUser.role;
                newUser.role = parseRolToString(newUser.role);
                newUser.role = parseRolToString(newUser.role);
                newUser.rtb_margin = parseMargin(newUser.rtb_margin);
            } else {
                dispatch(
                    notificationsActions.setNotification({
                        type: 'error',
                        message: t('common.notifications.error.errorData'),
                    })
                );
                return false;
            }
            return newUser;
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'error',
                    message: t('common.notifications.error.errorData'),
                })
            );
            return false;
        }
    };

    const getSsps = async () => {
        try {
            const ssp = getData(await axios.get(endpoints.getSsps));
            return ssp;
        } catch (error) {
            console.error(`Error in ssp ${error}`);
        }
    };

    const getSspsByUser = async (userId) => {
        try {
            let url = `${endpoints.userSsp}${userId}/ssp`;
            let response = getData(await axios.get(url));
            return response;
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    const assignSspToUser = async (userId, ssp) => {
        const url = endpoints.assignSspToUser + userId + '/ssp';
        const response = await axios.post(url, { name: ssp.name });
        return getData(response);
    };

    const asignSspToUser = async (userId, userSsps) => {
        try {
            let url = `${endpoints.asignSsps}${userId}/ssp`;
            const body = { ssps: userSsps };
            let response = getData(await axios.put(url, body));
            return response;
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    const getCountries = async () => {
        try {
            const countries = getData(
                await axios.get(endpoints.getCountriesV2)
            );
            return countries;
        } catch (error) {
            ErrorMessage(error);
        }
    };

    const getCommercialPersonsAudit = async (userId) => {
        try {
            let url = `${endpoints.getCommercialContacts}${userId}/company-person`;
            let response = getData(await axios.get(url));
            return response;
        } catch (e) {
            console.error({ e });
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    const addCommercialPersonsAudit = async (userId, commercialPerson) => {
        let createdCommercialPerson = getData(
            await axios.post(
                `${endpoints.getCommercialContacts}${userId}/company-person/assign`,
                commercialPerson
            )
        );

        return createdCommercialPerson;
    };

    const removeCommercialPersonAudit = async (userId, commercialPersonId) => {
        const url = `${endpoints.getCommercialContacts}${userId}/company-person/${commercialPersonId}`;
        await axios.delete(url);
        dispatch(
            commercialsPersonsActions.removeCommercialPerson(commercialPersonId)
        );
        return true;
    };

    const editCommercialPersonAudit = async (userId, commercialPerson) => {
        const url = `${endpoints.getCommercialContacts}${userId}/company-person/edit`;
        let commercialPersonEdited = getData(
            await axios.put(url, commercialPerson)
        );
        // commercialPersonEdited.phoneNumber = commercialPersonEdited.phone_number
        // delete commercialPersonEdited.phone_number
        // dispatch(commercialsPersonsActions.editCommercialPerson(commercialPersonEdited))
        return commercialPersonEdited;
    };

    const assignBudgetToCampaign = async (campaignId, newBudget) => {
        try {
            const url = `${endpoints.assignBudgetToCampaignV2}${campaignId}/budget/${newBudget}`;
            const campaign = getData(await axios.put(url));
            const currencies = await getCurrencies();
            const campaignCurrency = currencies.find(
                (currency) => currency.id === campaign.currency_id
            );
            campaign.currency = campaignCurrency;
            updateBudgets();
            return campaign;
        } catch (error) {
            ErrorMessage(error);
        }
    };

    /**
     * Add one commercial person to one user
     * @param {CommercialPerson} commercialPerson with name, email, phoneNumber, role
     * @returns {CommercialPerson} created
     */
    const addCommercialPersonToUser = async (commercialPerson) => {
        let createdCommercialPerson = getData(
            await axios.post(endpoints.createCommercialPerson, commercialPerson)
        );
        createdCommercialPerson.phoneNumber =
            createdCommercialPerson.phone_number;
        delete createdCommercialPerson.phone_number;
        dispatch(
            commercialsPersonsActions.addCommercialPerson(
                createdCommercialPerson
            )
        );
        return createdCommercialPerson;
    };

    /**
     * Remove one commercial person from one user
     * @param {Number} commercialPersonId id of the commercial person to remove
     * @returns {Boolean}
     */
    const removeCommercialPersonToUser = async (commercialPersonId) => {
        const url = endpoints.removeCommercialPerson + commercialPersonId;
        await axios.delete(url);
        dispatch(
            commercialsPersonsActions.removeCommercialPerson(commercialPersonId)
        );
        return true;
    };

    /**
     * Get all commercial persons of one user
     * @returns {Array} of commercial persons
     */
    const getCommercialsPersons = async () => {
        let commercialPersons = getData(
            await axios.get(endpoints.getCommercialsPersons)
        );
        commercialPersons = commercialPersons.map((cp) => {
            cp.phoneNumber = cp.phone_number;
            delete cp.phone_number;
            return cp;
        });
        dispatch(
            commercialsPersonsActions.setCommercialsPersons(commercialPersons)
        );
        return commercialPersons;
    };

    /**
     * Edit one commercial person of one user
     * @param {CommercialPerson} commercialPerson to edit with id and data
     * @returns {CommercialPerson} edited
     */
    const editCommercialPerson = async (commercialPerson) => {
        const url = endpoints.editCommercialPerson + commercialPerson.id;
        let commercialPersonEdited = getData(
            await axios.put(url, commercialPerson)
        );
        commercialPersonEdited.phoneNumber =
            commercialPersonEdited.phone_number;
        delete commercialPersonEdited.phone_number;
        dispatch(
            commercialsPersonsActions.editCommercialPerson(
                commercialPersonEdited
            )
        );
        return commercialPersonEdited;
    };

    const changeDailyCapLimit = async (campaignId, newDailyCapLimit) => {
        try {
            const url = `${endpoints.assignDailyCapLimitToCampaignV2}${campaignId}/daily-cap/${newDailyCapLimit}`;
            const campaign = getData(await axios.put(url));
            const currencies = await getCurrencies();
            const campaignCurrency = currencies.find(
                (currency) => currency.id === campaign.currency_id
            );
            campaign.currency = campaignCurrency;
            return campaign;
        } catch (error) {
            ErrorMessage(error);
        }
    };

    const changeCpm = async (campaignId, newCpm) => {
        try {
            const url = `${endpoints.assignCpmToCampaignV2}${campaignId}/cpm/${newCpm}`;
            const campaign = getData(await axios.put(url));
            const currencies = await getCurrencies();
            const campaignCurrency = currencies.find(
                (currency) => currency.id === campaign.currency_id
            );
            campaign.currency = campaignCurrency;
            dispatch(
                notificationsActions.setNotification({
                    type: 'success',
                    title: t(
                        'common.notifications.success.cpmChangeSuccessfull-title'
                    ),
                    message: t(
                        'common.notifications.success.cpmChangeSuccessfull-description'
                    ),
                })
            );
            return campaign;
        } catch (error) {
            ErrorMessage(error);
        }
    };

    const getInvoicesForUser = async (from = '', to = '') => {
        const isAdmin = auth.isAdmin();
        const url = isAdmin
            ? endpoints.getAdminInvoices + `?from=${from}&to=${to}`
            : endpoints.getUserInvoices + `?from=${from}&to=${to}`;

        const invoices = getData(await axios.get(url));
        return invoices;
    };

    const ErrorMessage = (error) => {
        if (
            !isNaN(error.response?.data?.errorCode) &&
            error.response?.data?.errorCode > 1000
        ) {
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('errorCode.' + error.response.data.errorCode),
                })
            );
        } else {
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
        return;
    };

    const createCampaignProposalName = async () => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );
        const session = JSON.parse(localStorage.getItem('session'));
        if (!supervisorSession || !session) {
            throw new Error('Session data is missing');
        }
        const name = '';
        const brand = '';
        const url = endpoints.campaignProposalCrud;
        const token = supervisorSession.token;
        const admin_id = supervisorSession.user.id;
        const user_id = session.user.id;
        const payload = {
            name,
            brand,
            admin_id,
            user_id,
        };
        try {
            const response = await axios.post(url, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            history.push(`/proposal/${response.data.data.id}`);
            return response.data.data.id;
        } catch (error) {
            throw error;
        }
    };

    const fetchCampaignProposalsByStatus = async (status) => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );
        const session = JSON.parse(localStorage.getItem('session'));
        if (!supervisorSession || !session) {
            throw new Error('Session data is missing');
        }

        const token = supervisorSession.token;
        const admin_id = supervisorSession.user.id;
        const user_id = session.user.id;

        try {
            const response = await axios.get(
                `${endpoints.getCampaignProposalsByStatus}/${user_id}/${status}`,

                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response.data.data;
        } catch (error) {
            throw error;
        }
    };

    const getCampaignProposalById = async (id) => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );
        const token = supervisorSession.token;
        const url = endpoints.getCampaignProposalById(id);
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const updateCampaignProposal = async (payload) => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );
        const url = endpoints.campaignProposalCrud;
        const token = supervisorSession.token;
        try {
            const response = await axios.put(url, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const createLabel = async (payload) => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );
        const url = endpoints.createLabel;
        const token = supervisorSession.token;
        try {
            const response = await axios.post(url, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    const getLabelsByCampaignId = async (id) => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );
        const url = endpoints.getLabelsByCampaignId(id);
        const token = supervisorSession.token;
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const getCampaignByUserIdAndStatus = async (status) => {
        try {
            const url = endpoints.getCampaignByUserIdAndStatus + status;
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const deleteCampaignProposal = async (id) => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );
        const url = endpoints.campaignProposalCrud;
        const token = supervisorSession.token;
        try {
            const response = await axios.delete(`${url}/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const createCampaign = async (campaignName = '', campaignBrand = '') => {
        dispatch(newCampaignActions.reset());
        dispatch(screensActions.resetByKey('maxCpm'));
        dispatch(screensActions.resetByKey('selectedScreensLocal'));
        dispatch(screensActions.resetByKey('isFilterByAudiences'));
        dispatch(screensActions.resetByKey('showSelectedScreens'));
        dispatch(screensActions.resetByKey('selectedScreenTypes'));
        dispatch(screensActions.resetByKey('selectedPublishers'));
        dispatch(screensActions.resetByKey('selectedTiers'));
        const data = formatValuesSavePreview(newCampaignInitialState);
        data.name = campaignName;
        data.brand = campaignBrand;
        try {
            const response = getData(
                await axios.post(endpoints.saveCampaignPreviewV2, data)
            );
            if (response.campaign) {
                const id = response.campaign.id;
                const statusCampaign = campaignStatus(response.campaign.status);
                if (id != newCampaign.id)
                    dispatch(newCampaignActions.setId(id));
                dispatch(newCampaignActions.setStatus(statusCampaign));
                saveCampaignInLocalStorage(id);
                updateCampaignInListOfCampaigns(response.campaign);
                if (statusCampaign != 'DRAFT') {
                    await updateBudgets();
                }
            }
            return response;
        } catch (error) {
            ErrorMessage(error);
        }
    };

    const linkLabelAndSubcampaign = async (payload) => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );
        const url = endpoints.linkLabelAndSubcampaign;
        const token = supervisorSession.token;
        try {
            const response = await axios.put(url, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const generateProposals = async (id) => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );
        const url = endpoints.generateProposals(id);
        const token = supervisorSession.token;
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            successNotification(
                t('common.notifications.success.proposalGenerated')
            );
            return response;
        } catch (err) {
            warningNotification(
                t('common.notifications.warning.proposalGeneratedError'),
                t('common.notifications.warning.proposalForecast')
            );
        }
    };
    const getLinkedCampaigns = async (id) => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );
        const url = endpoints.getLinkedCampaigns(id);
        const token = supervisorSession.token;
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            return response;
        } catch {}
    };
    const getProposalBrief = async (id) => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );
        const token = supervisorSession.token;
        const url = endpoints.getProposalBrief + id;
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    const getProposalsById = async (id) => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );
        const token = supervisorSession.token;
        const url = endpoints.getProposals(id);
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const sendEmailWithProposal = async (id, email) => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );

        const url = `${endpoints.sendProposalEmail}${id}?email=${email}`;
        const token = supervisorSession.token;

        try {
            const response = await axios.post(
                url,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            successNotification(t('common.notifications.success.proposalSent'));

            return response.data;
        } catch (error) {
            warningNotification(
                t('common.notifications.warning.proposalSentError')
            );
        }
    };

    const usoProposal = async (id) => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );
        const token = supervisorSession.token;
        const url = endpoints.getProposals(id);
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const checkSubCampaignBeforeLaunch = async (id) => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );
        const token = supervisorSession.token;
        const url = endpoints.checkSubCampaignBackUp + id;
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            const validations = response?.data?.data?.validations;

            if (validations.length) {
                let validationWithMessage = validations.map((validation) =>
                    t(
                        `common.campaign-proposals.check-differences.${validation.key}`
                    )
                );
                return validationWithMessage;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    const unassignLabelByCampaignId = async (subcampaignId) => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );
        const token = supervisorSession.token;
        const url = endpoints.unassignLabel + subcampaignId;
        try {
            const response = await axios.put(
                url,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const getCampaignProposalCount = async () => {
        const supervisorSession = JSON.parse(
            localStorage.getItem('supervisorSession')
        );
        const session = JSON.parse(localStorage.getItem('session'));
        const token = supervisorSession.token;
        const id = session.user.id;
        const url = endpoints.getCampaignProposalCount + id;
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch {}
    };

    return {
        getUserMovements,
        uploadTester,
        getCreativeType,
        getCreativeCategories,
        getBudgets,
        assignBudgetToCampaign,
        changeDailyCapLimit,
        changeCpm,
        getBudgetsDetails,
        getUsers,
        addBilling,
        isValidUsername,
        createUser,
        getCreatives,
        getCampaignPreview,
        getTimezones,
        getCampaigns,
        activateCampaign,
        deactivateCampaign,
        restoreCampaign,
        deleteCampaign,
        duplicateCampaign,
        editCampaign,
        getCampaignStats,
        getCampaignPrediction,
        getStatsByUser,
        getCurrencyById,
        getCampaignInLocalStorage,
        hasDraftCampaignInLocalStorage,
        clearNewCampaignData,
        onClickNextPageOfCampaignsList,
        fetchMoreDataOfCurrentCampaignList,
        getCampaignsPerStatus,
        getCurrencies,
        getScreens,
        getScreenProviders,
        getScreenProvidersFiltered,
        saveOrUpdateCampaignPreview,
        continueNewCampaign,
        launchCampaign,
        getMargins,
        uploadFiles,
        uploadScript,
        vastInDisplay,
        duplicateCreative,
        removeCreative,
        removeMultipleCreatives,
        updateCPMOfScreens,
        changeResolution,
        getProviderMetadata,
        calculateForecast,
        getNodes,
        forgotPassword,
        resetPassword,
        editUser,
        getSspsByUser,
        getSsps,
        asignSspToUser,
        isValidEditUsername,
        parseRolToString,
        getCountries,
        getCompanies,
        getUserById,
        getCommercialsPersons,
        getCommercialPersonsAudit,
        addCommercialPersonsAudit,
        removeCommercialPersonAudit,
        removeCommercialPersonToUser,
        editCommercialPersonAudit,
        addCommercialPersonToUser,
        editCommercialPerson,
        getInvoicesForUser,
        getScreensReports,
        ErrorMessage,
        setDraftCampaignAutodelete,
        getUserSettings,
        getScreensReportsWithCpm,
        createCampaignProposalName,
        fetchCampaignProposalsByStatus,
        getCampaignProposalById,
        updateCampaignProposal,
        createLabel,
        getLabelsByCampaignId,
        deleteCampaignProposal,
        linkLabelAndSubcampaign,
        createCampaign,
        generateProposals,
        getLinkedCampaigns,
        getProposalBrief,
        getProposalsById,
        usoProposal,
        checkSubCampaignBeforeLaunch,
        sendEmailWithProposal,
        unassignLabelByCampaignId,
        getCampaignByUserIdAndStatus,
        getCampaignProposalCount,
    };
}
