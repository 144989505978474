import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { newCampaignActions } from '../../../../store';
import SwitcherButton from '../../../Buttons/CommonSwitcher';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

const Tracking = ({ isResizeMapOn, tracking, dispatch }) => {
    const { t } = useTranslation();
    const i18nErrors =
        'sections.campaigns.new-campaign.wizard-review.validation-issues.errors.';
    const between = (min, max, string) => {
        return string?.trim().length >= min && string?.trim().length <= max;
    };
    const [trackingInput, setTrackingInput] = useState(
        tracking.externalTracking
    );
    const [showAsterik, setShowAsterik] = useState(
        tracking.externalTracking != '' ? false : true
    );
    const [showCrossOnBlur, setShowCrossOnBlur] = useState(
        tracking.externalTracking === ''
            ? false
            : !between(3, 20248, tracking.externalTracking)
    );

    const change = (attribute, value) => {
        if (attribute === 'isActive') {
            setShowAsterik(true);
            const newTracking = Object.assign({}, tracking);
            newTracking[attribute] = value;
            dispatch(newCampaignActions.setTracking(newTracking));
        } else if (
            attribute === 'externalTracking' &&
            between(3, 2048, value)
        ) {
            setTrackingInput(value);
            setShowAsterik(false);
            const newTracking = Object.assign({}, tracking);
            newTracking[attribute] = value;
            dispatch(newCampaignActions.setTracking(newTracking));
        } else {
            setTrackingInput(value);
            setShowAsterik(true);
            const newTracking = Object.assign({}, tracking);
            newTracking[attribute] = value;
            dispatch(newCampaignActions.setTracking(newTracking));
        }
    };

    const toggle = (attribute) => {
        change(attribute, !tracking[attribute]);
    };

    const tooltip = (
        <>
            <ReactTooltip
                effect="solid"
                className="w-48 p-2"
                id="explain-script-tracker"
                aria-haspopup="true">
                {t(
                    'sections.campaigns.new-campaign.wizard-creatives.creatives.externalTracking-tooltip'
                )}
            </ReactTooltip>
            <a data-tip data-for="explain-script-tracker">
                <FontAwesomeIcon
                    icon={['fad', 'info-circle']}
                    className="text-gray-700 text-sm"
                />
            </a>
        </>
    );

    return (
        <div className="module rounded m-1 mb-4">
            <div className="header flex justify-between content-center">
                <div className="title text-xl font-extralight">
                    {t(
                        'sections.campaigns.new-campaign.wizard-creatives.creatives.externalTracking-title'
                    )}
                </div>
                <div className="brief">
                    <SwitcherButton
                        onToggle={() => toggle('isActive')}
                        status={tracking.isActive}
                        labelOn={t(
                            'sections.campaigns.new-campaign.wizard-basics.dailyCap.dailyCap-labelOn'
                        )}
                        labelOff={t(
                            'sections.campaigns.new-campaign.wizard-basics.dailyCap.dailyCap-labelOff'
                        )}
                    />
                </div>
            </div>
            {tracking.isActive && (
                <>
                    <div className="body rounded-sm p-1">
                        <div
                            id="trackerWrapper"
                            className={`${
                                isResizeMapOn ? 'w-full' : 'md:w-3/6'
                            }`}>
                            <span className="block text-sm font-medium text-gray-700 mb-1">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-creatives.creatives.externalTracking-label'
                                )}{' '}
                                {showAsterik && (
                                    <FontAwesomeIcon
                                        icon={faAsterisk}
                                        className="mr-1 mb-0.5 text-black"
                                        style={{ fontSize: '8px' }}
                                    />
                                )}
                                {tooltip}
                            </span>
                            <div className="flex items-center">
                                <textarea
                                    onChange={(e) =>
                                        change(
                                            'externalTracking',
                                            e.target.value
                                        )
                                    }
                                    value={trackingInput}
                                    title={t(i18nErrors + 'tracking')}
                                    className="focus:ring-0 focus:outline-none flex-grow sm:text-sm border-gray-300 rounded-md"
                                    onFocus={() => setShowCrossOnBlur(false)}
                                    onBlur={() => {
                                        if (
                                            !trackingInput ||
                                            trackingInput === '' ||
                                            trackingInput.length < 3 ||
                                            trackingInput.length > 2048
                                        ) {
                                            setShowCrossOnBlur(true);
                                        }
                                    }}
                                />
                                <div>
                                    {showCrossOnBlur ? (
                                        <FontAwesomeIcon
                                            icon={['fas', 'times']}
                                            fixedWidth
                                            className="text-red-500 ml-2"
                                        />
                                    ) : trackingInput.length >= 3 &&
                                      trackingInput.length <= 2048 ? (
                                        <FontAwesomeIcon
                                            icon={['fas', 'check']}
                                            fixedWidth
                                            className="text-blue-400 ml-2"
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                            {showCrossOnBlur ? (
                                <span className="text-red-500 text-xs italic">
                                    {t(i18nErrors + 'tracking')}
                                </span>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

Tracking.proptyTypes = {
    isResizeMapOn: PropTypes.bool,
    tracking: PropTypes.object,
    dispatch: PropTypes.func,
};

export default Tracking;
